import { useLazyQuery } from "@apollo/react-hooks"
import { IconButton, makeStyles, Popover, Theme, useTheme } from "@material-ui/core"
import { Info } from "@material-ui/icons"
import lodash from "lodash"
import moment from "moment"
import React, { FunctionComponent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { CONNECTION_HISTORY } from "../../../../api/graphql/query/connection_history"
import { ConnectionHistory, ConnectionHistoryVariables } from "../../../../api/graphql/query/types/ConnectionHistory"
import { CommonHelper } from "../../../../utils/commonHelpers"

interface IPopoverContainerHistoryProps {
  qrCode: string
}

const useStyles = makeStyles((theme: Theme) => ({
  popupText: {
    padding: theme.spacing(2),
  },
  popupInfoText: {
    whiteSpace: "nowrap",
    color: theme.palette.primary.main,
  },
  infoIcon: {
    color: theme.palette.info.main,
  },
}))

export const PopoverContainerHistory: FunctionComponent<IPopoverContainerHistoryProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [loadConnectionHistory, { data, loading, error }] = useLazyQuery<ConnectionHistory, ConnectionHistoryVariables>(
    CONNECTION_HISTORY,
    {
      variables: { qrCode: props.qrCode },
      errorPolicy: "all",
    }
  )

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (open) {
      loadConnectionHistory()
    }
  }, [loadConnectionHistory, open])

  interface IConnectionLine {
    key: string
    value: string
  }

  function listConnectionHistory(
    data: ConnectionHistory | undefined,
    error: Error | undefined,
    loading: boolean
  ): IConnectionLine[] {
    const historyEntries = data?.connectionHistory

    if (error) {
      return [{ key: "Error: ", value: error?.message }]
    }
    if (loading) {
      return [{ key: t("general.loading"), value: "" }]
    }

    if (lodash.isNil(historyEntries) || lodash.isEmpty(historyEntries)) {
      return [{ key: t("generic.no_info"), value: " " }]
    }
    const lastIndex = historyEntries.length - 1
    const linesToRender: IConnectionLine[] = []
    let currentIndex = 0

    for (const historyEntry of historyEntries) {
      const connectionHistoryEntry = {
        since: moment(historyEntry.since).format("lll"),
        status: historyEntry.status,
        statusInfo: historyEntry.statusInfo,
        address: historyEntry.address,
        partnerName: historyEntry.partnerName,
        driverCredentials: CommonHelper.buildDriverCredentials(
          historyEntry.driverPersonalNumber,
          historyEntry.driverName
        ),
        gps: `${historyEntry.latitude} | ${historyEntry.longitude}`,
      }

      const entryLinesToRender = lodash.entries(connectionHistoryEntry).map(([key, value]) => {
        return { key: `${t(`container_detail.${key}`)}: `, value: value || "---" }
      })
      linesToRender.push(...entryLinesToRender)

      if (lastIndex !== currentIndex) {
        linesToRender.push({ key: "–––––––––––––––––––––––––––––––––––––––––––", value: " " })
      }

      ++currentIndex
    }

    return linesToRender
  }

  function renderConnectionHistoryLines(
    data: ConnectionHistory | undefined,
    error: Error | undefined,
    loading: boolean
  ) {
    const linesToRender = listConnectionHistory(data, error, loading)

    const renderedElements: JSX.Element[] = []
    let currentIndex = 0

    for (const lineToRender of linesToRender) {
      renderedElements.push(
        <div style={{ display: "flex" }} key={currentIndex++}>
          <span style={{ marginRight: 4, color: theme.palette.text.secondary }}>{lineToRender.key}</span>
          {lineToRender.value && <span className={classes.popupInfoText}>{lineToRender.value}</span>}
        </div>
      )
    }

    return renderedElements
  }

  return (
    <div>
      <Popover
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <div className={classes.popupText}>{renderConnectionHistoryLines(data, error, loading)}</div>
      </Popover>
      <IconButton onClick={handleClick} size="small">
        <Info className={classes.infoIcon} />
      </IconButton>
    </div>
  )
}
