import React, { Fragment, FunctionComponent } from "react"
import { ContainerIssuePageContent } from "./ContainerIssuePageContent"
import { ContainerIssuePageContextProvider } from "./context/ContainerIssuePageContext"

interface IContainerIssuePageProps {}

export const ContainerIssuePage: FunctionComponent<IContainerIssuePageProps> = (props) => {
  return (
    <Fragment>
      <ContainerIssuePageContextProvider>
        <ContainerIssuePageContent />
      </ContainerIssuePageContextProvider>
    </Fragment>
  )
}
