import { gql } from "apollo-boost"
import { resignContainer, resignContainerVariables } from "./types/resignContainer"

export const RESIGN_CONTAINER_MUTATION = gql`
  mutation resignContainer($qrCode: ID!, $resignContainerReason: ResignContainerReason!) {
    resignContainer(qrCode: $qrCode, resignContainerReason: $resignContainerReason) {
      message
    }
  }
`
export type ResignContainerResult = resignContainer
export type ResignContainerVariables = resignContainerVariables
