import { IconButton, makeStyles, Popover, Theme, Typography } from "@material-ui/core"
import { Info } from "@material-ui/icons"
import { Stack } from "@mui/material"
import moment from "moment"
import React, { useMemo } from "react"
import { useQuery } from "react-apollo"
import { useTranslation } from "react-i18next"
import {
  CONTAINER_HISTORY_QUERY,
  ContainerHistoryResult,
  ContainerHistoryVariables,
} from "../../../api/graphql/query/container_history"
import { convertRating } from "../../../utils/rating"

interface ChangeInfo {
  oldRating?: string
  newRating?: string
  comment?: string
  changeDate: string
}

const useStyles = makeStyles((theme: Theme) => ({
  popupText: {
    padding: theme.spacing(2),
  },
  popupInfoText: {
    whiteSpace: "nowrap",
    color: theme.palette.primary.main,
  },
  infoIcon: {
    color: theme.palette.info.main,
  },
}))

interface Props {
  qrCode: string
}

export const ContainerRatingPopover: React.FC<Props> = (props) => {
  const { qrCode } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const { data: invalidInventoryContainerData } = useQuery<ContainerHistoryResult, ContainerHistoryVariables>(
    CONTAINER_HISTORY_QUERY,
    {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      skip: !qrCode,
      variables: {
        qrCode,
      },
    },
  )

  const data = useMemo(() => {
    const sortedData = (invalidInventoryContainerData?.containerHistoryForQrCode ?? []).sort((a, b) =>
      moment(a.inserted_at).isSameOrBefore(moment(b.inserted_at)) ? 1 : -1,
    )
    const relevantInfo: ChangeInfo[] = []

    // if there is only one history entry, we cannot iterate sortedData and compare with a previous entry
    // so we just return the single entry
    if (sortedData.length === 1) {
      relevantInfo.push({
        oldRating: t("container_history.no_rating"),
        newRating: sortedData[0].rating ? convertRating(sortedData[0].rating) : t("container_history.no_rating"),
        comment: sortedData[0].comment ?? undefined,
        changeDate: moment(sortedData[0].inserted_at).format("lll"),
      })
      return relevantInfo
    }

    sortedData.forEach((element, index) => {
      const previousEntry = sortedData[index + 1]

      if (!previousEntry) return

      if (element.rating !== previousEntry?.rating) {
        relevantInfo.push({
          oldRating: previousEntry.rating ? convertRating(previousEntry.rating) : t("container_history.no_rating"),
          newRating: element.rating ? convertRating(element.rating) : t("container_history.no_rating"),
          comment: element.comment ?? undefined,
          changeDate: moment(element.inserted_at).format("lll"),
        })
        return
      }

      if (element.comment && element.comment !== previousEntry.comment) {
        relevantInfo.push({
          comment: element.comment ?? undefined,
          changeDate: moment(element.inserted_at).format("lll"),
        })
        return
      }
    })
    return relevantInfo
  }, [invalidInventoryContainerData, t])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Popover
        open={!!anchorEl}
        id={!!anchorEl ? "rating-history-popover" : undefined}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <div className={classes.popupText}>
          {data.map((info, index) => {
            return (
              <Stack key={`${info.newRating}-${info.newRating}-${index}`} direction="column" spacing={0}>
                <Typography>
                  {t("container_history.time_stamp")}
                  <span className={classes.popupInfoText}>{info.changeDate}</span>
                </Typography>
                {info.newRating !== undefined && info.oldRating !== undefined && (
                  <Typography>
                    {t("container_history.rating")}
                    <span className={classes.popupInfoText}>
                      {t("container_history.rating_changed", { oldRating: info.oldRating, newRating: info.newRating })}
                    </span>
                  </Typography>
                )}
                {info.comment !== undefined && info.comment.length > 0 && (
                  <Typography>
                    {t("container_history.comment")}
                    <span className={classes.popupInfoText}>{info.comment}</span>
                  </Typography>
                )}
                <Typography>–––––––––––––––––––––––––––––––––––––––––––</Typography>
              </Stack>
            )
          })}
        </div>
      </Popover>
      <IconButton onClick={handleClick} size="small">
        <Info className={classes.infoIcon} />
      </IconButton>
    </>
  )
}
