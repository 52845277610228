import { gql } from "apollo-boost"
import {
  mergedContainerDataForMap,
  mergedContainerDataForMapVariables,
  mergedContainerDataForMap_mergedContainerDataForMap,
} from "./types/mergedContainerDataForMap"

export const MERGED_CONTAINER_DATA_FOR_MAP = gql`
  query mergedContainerDataForMap(
    $locationIds: [ID!]!
    $containerTypeIds: [ID!]!
    $containerCharacteristic: ContainerCharacteristic!
    $qrCodeFilterText: String
    $connectionReferenceFilterText: String
    $containerFillStates: [String!]!
  ) {
    mergedContainerDataForMap(
      locationIds: $locationIds
      containerTypeIds: $containerTypeIds
      containerCharacteristic: $containerCharacteristic
      qrCodeFilterText: $qrCodeFilterText
      connectionReferenceFilterText: $connectionReferenceFilterText
      containerFillStates: $containerFillStates
    ) {
      qr_code
      internal_container_number,
      container_type
      varnishing
      rating
      comment
      location
      construction_year
      lastStatus
      statusInfo
      lastSensorTimeSend
      containerAddress
      lat
      long
      actual_location
      last_interaction
      last_client
      last_client_name
    }
  }
`

export type MergedContainerDataForMap = mergedContainerDataForMap_mergedContainerDataForMap
export type MergedContainerDataForMapResult = mergedContainerDataForMap
export type MergedContainerDataForMapVariables = mergedContainerDataForMapVariables
