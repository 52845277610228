import { gql } from "apollo-boost"
import {
  validInventoryContainers,
  validInventoryContainersVariables,
  validInventoryContainers_validInventoryContainers,
  validInventoryContainers_validInventoryContainers_entries
} from "./types/validInventoryContainers"

export const CONTAINER_INVENTORY_VALID_CONTAINERS_QUERY = gql`
  query validInventoryContainers(
    $date: DateTime!
    $locationId: ID!
    $page: Int!
    $pageSize: Int!
  ) {
    validInventoryContainers(
      date: $date
      locationId: $locationId
      page: $page
      pageSize: $pageSize
    ) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        qr_code,
        internal_container_number,
        latitude,
        longitude,
        address,
        varnishing,
        rating,
        comment,
        construction_year,
        typeName,
        latestLocation,
        latestUpdate
      }
    }
  }
`

export type ValidInventoryContainersResult = validInventoryContainers
export type ValidInventoryContainer = validInventoryContainers_validInventoryContainers_entries
export type ValidInventoryContainersVariables = validInventoryContainersVariables
export type ValidInventoryContainersPaginationResult = validInventoryContainers_validInventoryContainers
