import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme } from "@material-ui/core"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ContainerErrorType } from "../../../api/graphql/graphql-global-types"
import { ContainerLocationCustomAutocomplete } from "../../partials/custom-select-components/container-location-custom-autocomplete"
import { ContainerTypeCustomAutocomplete } from "../../partials/custom-select-components/container-type-custom-autocomplete"
import { ContentCard } from "../../partials/layout/content/ContentCard"
import { ContainerIssuePageContext } from "./context/ContainerIssuePageContext"

interface IContainerIssueFilterProps {}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
}))

export const ContainerIssueFilter: FunctionComponent<IContainerIssueFilterProps> = (props) => {
  const {
    qrCodeFilter,
    setQrCodeFilter,
    containerErrorTypeFilter,
    setContainerErrorTypeFilter,
    containerLocations,
    selectedContainerLocations,
    setSelectedContainerLocations,
    containerTypes,
    selectedContainerTypes,
    setSelectedContainerTypes,
    connectionReferenceFilterText,
    setConnectionReferenceFilterText,
  } = useContext(ContainerIssuePageContext)
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ContentCard>
      <Grid container direction={"row"} spacing={6}>
        <Grid item xs={3}>
          <TextField
            id="qrCodeFilter"
            name="qrCodeFilter"
            type="text"
            fullWidth
            label={t("generic.qr_code")}
            value={qrCodeFilter}
            onChange={(event: any) => setQrCodeFilter(event.target.value as string)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="connection-reference-search"
            label={t("connection_history_model.connectionReference")}
            fullWidth
            value={connectionReferenceFilterText}
            onChange={(event) => setConnectionReferenceFilterText(event.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.fullWidth}>
            <InputLabel htmlFor="containerErrorTypeFilter">{t("generic.error_type")}</InputLabel>
            <Select
              id={"containerErrorTypeFilter"}
              name={"containerErrorTypeFilter"}
              fullWidth
              value={containerErrorTypeFilter || ""}
              onChange={(e) => setContainerErrorTypeFilter(e.target.value as ContainerErrorType)}
              className={classes.select}
            >
              <MenuItem key={"no_error_types"} value={""}>
                {t("generic.no_filter_selected")}
              </MenuItem>
              {Object.keys(ContainerErrorType).map((containerErrorTypeKey) => (
                <MenuItem key={containerErrorTypeKey} value={containerErrorTypeKey}>
                  {t(`container_error_model.error_types.${containerErrorTypeKey}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <ContainerLocationCustomAutocomplete
            id="container-map-location-filter"
            title={t("generic.location")}
            currentlySelectedContainerLocations={selectedContainerLocations}
            availableContainerLocations={containerLocations || []}
            setCurrentlySelectedContainerLocations={setSelectedContainerLocations}
          />
        </Grid>
        <Grid item xs={3}>
          <ContainerTypeCustomAutocomplete
            id="container-map-type-filter"
            title={t("generic.container_type")}
            currentlySelectedContainerTypes={selectedContainerTypes}
            availableContainerTypes={containerTypes || []}
            setCurrentlySelectedContainerTypes={setSelectedContainerTypes}
          />
        </Grid>
      </Grid>
    </ContentCard>
  )
}
