import { gql } from "apollo-boost"
import { completeDamageReport, completeDamageReportVariables } from "./types/completeDamageReport"

export const COMPLETE_DAMAGE_REPORT = gql`
  mutation completeDamageReport($damageReportId: Int!) {
    completeDamageReport(damageReportId: $damageReportId) {
      message
    }
  }
`

export type CompleteDamageReport = completeDamageReport
export type CompleteDamageReportVariables = completeDamageReportVariables
