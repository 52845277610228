import { useEffect, useMemo, useState } from "react"

export function useTableCheckBoxHook<T extends { id: string | number }>(
  allEntriesOnPage: Array<T>,
  currentPage: number
) {
  const [selectedEntries, setSelectedEntries] = useState<T[]>([])
  const allEntriesSelected = useMemo(
    () => selectedEntries.length === allEntriesOnPage.length,
    [selectedEntries, allEntriesOnPage]
  )

  useEffect(() => {
    resetSelectedValues()
  }, [currentPage])

  function handleHeaderCheckBoxClick() {
    if (allEntriesSelected) {
      resetSelectedValues()
      return
    }

    setSelectedEntries([...allEntriesOnPage])
  }

  function handleRowCheckBoxClick(selectedEntry: T) {
    const foundIndex = selectedEntries.findIndex((element) => element.id === selectedEntry.id)

    if (foundIndex === -1) {
      selectedEntries.push(selectedEntry)
    } else {
      selectedEntries.splice(foundIndex, 1)
    }

    setSelectedEntries([...selectedEntries])
  }

  function resetSelectedValues() {
    setSelectedEntries([])
  }

  return {
    selectedEntries,
    setSelectedEntries,
    allEntriesSelected,
    handleHeaderCheckBoxClick,
    handleRowCheckBoxClick,
    resetSelectedValues,
  }
}
