import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ContainerType } from "../../../api/graphql/query/container_types"
import { CustomAutocomplete } from "../../partials/custom-select-components/custom-autocomplete"
import {
  CommonSelectOptionIdEnum,
  SelectOption,
  SortGroupEnum,
} from "../../partials/custom-select-components/custom-select-component-commons"

export interface IContainerTypeCustomAutocompleteProps {
  id: string
  title: string
  availableContainerTypes: ContainerType[]
  currentlySelectedContainerTypes: SelectOption[]
  setCurrentlySelectedContainerTypes: (currentlySelectedContainerTypes: SelectOption[]) => void
}

export const ContainerTypeCustomAutocomplete: React.FunctionComponent<IContainerTypeCustomAutocompleteProps> = (
  props
) => {
  const {
    id,
    title,
    availableContainerTypes,
    currentlySelectedContainerTypes,
    setCurrentlySelectedContainerTypes,
  } = props
  const { t } = useTranslation()

  const containerTypeSelectOptions = useMemo(
    () => {
      const computedContainerTypeSelectOptions =
        availableContainerTypes.map(
          (containerType) => new SelectOption(String(containerType.id), containerType.name)
        ) || []
      computedContainerTypeSelectOptions.unshift(
        new SelectOption(
          CommonSelectOptionIdEnum.Null,
          t("custom_auto_complete.without_container_type"),
          SortGroupEnum.Second
        )
      )
      return computedContainerTypeSelectOptions
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableContainerTypes]
  )

  return (
    <CustomAutocomplete
      id={id}
      title={title}
      currentlySelectedValues={currentlySelectedContainerTypes}
      availableSelectOptions={containerTypeSelectOptions}
      setCurrentlySelectedValues={setCurrentlySelectedContainerTypes}
      sortSelectedOptions={false}
    />
  )
}
