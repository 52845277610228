import { gql } from "apollo-boost"
import { inventoryConfirmation, inventoryConfirmationVariables, inventoryConfirmation_inventoryConfirmation } from "./types/inventoryConfirmation"

export const INVENTORY_CONFIRMATION = gql`
  query inventoryConfirmation(
    $locationId: ID!
    $date: DateTime!
  ) {
    inventoryConfirmation(
      locationId: $locationId
      date: $date
    ) {
      id,
      clientId,
      location,
      inventorisedAt
    }
  }
`

export type InventoryConfirmationResult = inventoryConfirmation
export type InventoryConfirmation = inventoryConfirmation_inventoryConfirmation
export type InventoryConfirmationVariables = inventoryConfirmationVariables
