import { Grid, makeStyles, Theme } from "@material-ui/core"
import React, { useContext } from "react"
import { useMutation } from "react-apollo"
import { useTranslation } from "react-i18next"
import "react-table/react-table.css"
import {
  DeleteNonContainerTrackingsResult,
  DELETE_NON_CONTAINER_TRACKINGS_MUTATION,
} from "../../../api/graphql/mutations/DeleteNonContainerTrackingMutation"
import { DeleteNonContainerTrackingsVariables } from "../../../api/graphql/mutations/types/DeleteNonContainerTrackings"
import { NonContainerTrackingForTable } from "../../../api/graphql/query/get-non-container-trackings-for-table"
import { NonContainerTrackingPageContext } from "../../pages/non-container-tracking/context/NonContainerTrackingPageContext"
import { YesOrNoConfirmationDialog } from "../../partials/dialog/yes-or-no-confirmation-dialog"

const useStyles = makeStyles((theme: Theme) => ({
  spacingTop: {
    marginTop: 20,
  },
}))

export interface IDeleteNonContainerTrackingsDialogProps {
  open: boolean
  onClose: () => void
  elementsToDelete: NonContainerTrackingForTable[]
  isDeleteAllElements: boolean
  resetSelectedValues: () => void
}

export const DeleteNonContainerTrackingsDialog: React.FunctionComponent<IDeleteNonContainerTrackingsDialogProps> = (
  props
) => {
  const { open, onClose, elementsToDelete, isDeleteAllElements, resetSelectedValues } = props

  const classes = useStyles()
  const { t } = useTranslation()

  const { refetchNonContainerTrackings, currentPage, setCurrentPage, totalPages } = useContext(
    NonContainerTrackingPageContext
  )

  const [deleteNonContainerTrackings] = useMutation<
    DeleteNonContainerTrackingsResult,
    DeleteNonContainerTrackingsVariables
  >(DELETE_NON_CONTAINER_TRACKINGS_MUTATION, {
    variables: {
      idsToDelete: elementsToDelete.map((elementToDelete) => elementToDelete.id),
    },
    onCompleted: () => {
      onClose()
      resetSelectedValues()

      if (isDeleteAllElements && currentPage === totalPages - 1) {
        // if all elements of the last page got deleted, we need to switch to the preceding page
        setCurrentPage(Math.max(currentPage - 1, 0))
      }

      refetchNonContainerTrackings()
    },
  })

  function renderDeleteInfoText() {
    return elementsToDelete.length === 1
      ? t("delete_non_container_trackings_dialog.delete_entries_text_singular")
      : t("delete_non_container_trackings_dialog.delete_entries_text", {
          selectedEntriesCount: elementsToDelete.length,
        })
  }

  return (
    <YesOrNoConfirmationDialog
      open={open}
      onClose={onClose}
      onConfirm={() => deleteNonContainerTrackings()}
      content={
        <Grid container direction="column">
          <Grid item xs={12}>
            {t("delete_non_container_trackings_dialog.title")}
          </Grid>
          <Grid item container xs={12} className={classes.spacingTop} justifyContent="center">
            <Grid item xs={12}>
              {renderDeleteInfoText()}
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
