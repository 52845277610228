import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import { FC, PropsWithChildren } from "react"
import "react-table/react-table.css"
import { ContentContainer } from "../../../partials/layout/content/ContentContainer"

const useStyles = makeStyles((theme: Theme) => ({
  tableHeader: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}))

interface IContainerInventoryTableWrapperProps {
  header: string
}

export const ContainerInventoryTableWrapper: FC<PropsWithChildren<IContainerInventoryTableWrapperProps>> = (props) => {
  const classes = useStyles()
  const { header, children } = props

  return (
    <ContentContainer>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography className={classes.tableHeader}>{header}</Typography>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </ContentContainer>
  )
}
