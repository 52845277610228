import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

interface ITableEntryCountProps {
  totalNumberOfEntries: number
  isTableLoading: boolean
  actionButtonElements?: JSX.Element
}

export const TableEntryCount: FunctionComponent<ITableEntryCountProps> = (props) => {
  const { totalNumberOfEntries, isTableLoading, actionButtonElements } = props
  const { t } = useTranslation()
  const classes = useStyles()

  function determineDisplayText() {
    if (isTableLoading) {
      return t("tableCountComponent.currentlyLoading")
    }

    if (totalNumberOfEntries < 1) {
      return t("tableCountComponent.noEntriesFetched")
    }

    if (totalNumberOfEntries === 1) {
      return t("tableCountComponent.oneEntryFetched")
    }

    return t("tableCountComponent.multipleEntriesFetched", { totalNumberOfEntries })
  }

  if (!actionButtonElements) {
    return (
      <Grid container className={classes.gridContainer} justifyContent={"flex-end"}>
        <Grid item>
          <Typography>{determineDisplayText()}</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container justifyContent={"space-between"} alignItems="flex-end">
      <Grid item className={classes.gridContainer}>
        <Grid item>{actionButtonElements}</Grid>
      </Grid>
      <Grid item className={classes.gridContainer}>
        <Grid item>
          <Typography>{determineDisplayText()}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
