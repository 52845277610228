import { Button, Grid } from "@material-ui/core"
import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { ContainerCharacteristic } from "../../../api/graphql/graphql-global-types"
import { ContainerPageContextProvider } from "../../../context/ContainerPageContext"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { ContainerDataOverviewWrapper } from "./ContainerDataOverviewWrapper"
import { ContainerDownloadButton } from "./ContainerDownloadButton"
import { ContainerFilter } from "./ContainerFilter"
import { ContainerStatisticDownloadDialog } from "./ContainerStatisticDownloadDialog"

interface ILogisticContainerPageProps {}

export const LogisticContainerPage: React.FunctionComponent<ILogisticContainerPageProps> = (props) => {
  const { t } = useTranslation()
  const [isContainerStatisticDownloadDialogOpen, setIsContainerStatisticDownloadDialogOpen] = useState(false)

  return (
    <Fragment>
      <PageHeading title={t("container.heading_logistic")}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={() => setIsContainerStatisticDownloadDialogOpen(true)}
            >
              {t("container.container_download.button")}
            </Button>
          </Grid>
          <Grid item>
            <ContainerDownloadButton characteristic={ContainerCharacteristic.LOGISTIC} />
          </Grid>
        </Grid>
      </PageHeading>
      <ContainerPageContextProvider containerCharacteristic={ContainerCharacteristic.LOGISTIC}>
        <ContainerFilter />
        <ContainerDataOverviewWrapper />
      </ContainerPageContextProvider>
      <ContainerStatisticDownloadDialog
        open={isContainerStatisticDownloadDialogOpen}
        handleClose={() => setIsContainerStatisticDownloadDialogOpen(false)}
        characteristic={ContainerCharacteristic.LOGISTIC}
      />
    </Fragment>
  )
}
