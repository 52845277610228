import { gql } from "apollo-boost"
import {
  containerStatisticCSV,
  containerStatisticCSVVariables,
  containerStatisticCSV_containerStatisticCSV,
} from "./types/containerStatisticCSV"

export const CONTAINER_STATISTIC_CSV = gql`
  query containerStatisticCSV($characteristic: ContainerCharacteristic!, $fromDate: DateTime!, $toDate: DateTime!) {
    containerStatisticCSV(characteristic: $characteristic, fromDate: $fromDate, toDate: $toDate) {
      data
    }
  }
`
export type ContainerStatisticCSV = containerStatisticCSV_containerStatisticCSV
export type ContainerStatisticCSVResult = containerStatisticCSV
export type ContainerStatisticCSVVariables = containerStatisticCSVVariables
