/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CloneDatabaseStep {
  CLONING_NOT_ALLOWED = "CLONING_NOT_ALLOWED",
  DROPPING_TABLES = "DROPPING_TABLES",
  DROPPING_TABLES_FAILED = "DROPPING_TABLES_FAILED",
  DUMPING_OWN = "DUMPING_OWN",
  DUMPING_OWN_FAILED = "DUMPING_OWN_FAILED",
  DUMPING_SOURCE = "DUMPING_SOURCE",
  DUMPING_SOURCE_FAILED = "DUMPING_SOURCE_FAILED",
  GENERAL_ERROR = "GENERAL_ERROR",
  IMPORTING_DUMP = "IMPORTING_DUMP",
  MIGRATE_FAILED = "MIGRATE_FAILED",
  MIGRATING = "MIGRATING",
  POST_PROCESSING = "POST_PROCESSING",
  POST_PROCESSING_FAILED = "POST_PROCESSING_FAILED",
  STARTING = "STARTING",
  SUCCESS = "SUCCESS",
}

/**
 * Characteristic of Container, either Logistic or Production 
 */
export enum ContainerCharacteristic {
  LOGISTIC = "LOGISTIC",
  PRODUCTION = "PRODUCTION",
  RESIGNED = "RESIGNED",
}

export enum ContainerErrorType {
  CONTAINER_IN_PRODUCTION_USAGE = "CONTAINER_IN_PRODUCTION_USAGE",
  DEFECT_CONTAINER = "DEFECT_CONTAINER",
  INVALID_CONTAINER_CHARACTERISTIC = "INVALID_CONTAINER_CHARACTERISTIC",
  NO_CONTAINER_TYPE = "NO_CONTAINER_TYPE",
  NO_ORIGIN_LOCATION = "NO_ORIGIN_LOCATION",
  ORDER_COMPLETION_NO_LOCATION = "ORDER_COMPLETION_NO_LOCATION",
  ORDER_COMPLETION_OTHER_LOCATION = "ORDER_COMPLETION_OTHER_LOCATION",
  OTHER_ORIGIN_LOCATION = "OTHER_ORIGIN_LOCATION",
  TOO_OFTEN_SCANNED = "TOO_OFTEN_SCANNED",
  UNCONNECTED_FOR_TOO_LONG = "UNCONNECTED_FOR_TOO_LONG",
}

export enum ContainerFillState {
  EMPTY = "EMPTY",
  FILLING = "FILLING",
  FULL = "FULL",
  FULL_LOGISTIC = "FULL_LOGISTIC",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum JwtSubjectEnum {
  AUTHENTICATED = "AUTHENTICATED",
  GRAPH_QL_TYPE_GENERATION = "GRAPH_QL_TYPE_GENERATION",
}

export enum NonContainerTrackingReason {
  EXTERNAL_CONTAINER = "EXTERNAL_CONTAINER",
  NO_QR_CODE_AVAILABLE = "NO_QR_CODE_AVAILABLE",
  YESTERDAYS_CONTAINER = "YESTERDAYS_CONTAINER",
}

export enum ResignContainerReason {
  PASTED_OVER = "PASTED_OVER",
  RESIGN_CONTAINER = "RESIGN_CONTAINER",
}

export enum UserPermissionNameEnum {
  ADMIN_MENU = "ADMIN_MENU",
  CLONE_DATABASE_ACTION = "CLONE_DATABASE_ACTION",
  CONTAINER_FILL_STATE_FILTER = "CONTAINER_FILL_STATE_FILTER",
  CONTAINER_OVERVIEW_CONVERT_TO_PRODUCTION = "CONTAINER_OVERVIEW_CONVERT_TO_PRODUCTION",
  CONTAINER_OVERVIEW_LIST_MENU = "CONTAINER_OVERVIEW_LIST_MENU",
  CONTAINER_OVERVIEW_RESIGN_CONTAINER = "CONTAINER_OVERVIEW_RESIGN_CONTAINER",
  CONTAINER_OVERVIEW_SET_STATUS_AND_MATERIAL = "CONTAINER_OVERVIEW_SET_STATUS_AND_MATERIAL",
  CONTAINER_PRODUCTION_OVERVIEW_PAGE = "CONTAINER_PRODUCTION_OVERVIEW_PAGE",
  DAMAGE_REPORT_OVERVIEW = "DAMAGE_REPORT_OVERVIEW",
  VEHICLE_DAMAGE_REPORT_OVERVIEW = "VEHICLE_DAMAGE_REPORT_OVERVIEW",
}

export interface CompleteVehicleDamageReportInput {
  damageReportId: string;
  name: string;
  comment: string;
  fixedIntern: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
