import { Button, Dialog, DialogActions, DialogContent, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { FunctionComponent, ReactNode } from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    color: "#f44336",
  },
  dialogWidth: {
    width: 400,
  },
}))

interface IDeleteDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  content: ReactNode
  isConfirmButtonDisabled?: () => boolean
}

export const YesOrNoConfirmationDialog: FunctionComponent<IDeleteDialogProps> = (props) => {
  const { open, onClose, onConfirm, content, isConfirmButtonDisabled } = props

  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogWidth}>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          {t("dialog.no")}
        </Button>
        <Button
          onClick={onConfirm}
          className={classes.deleteButton}
          disabled={isConfirmButtonDisabled ? isConfirmButtonDisabled() : false}
        >
          {t("dialog.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
