import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { ContainerPageContext } from "../../../context/ContainerPageContext"
import { IGenericContainer } from "../../../domain/models/interfaces/IGenericSensorOrContainer"
import { ContentContainer } from "../../partials/layout/content/ContentContainer"
import { IPin, Map } from "../../partials/map/Map"
import { MarkerContainerInfoWindowContent } from "../../partials/map/MarkerInfoWindow"

interface IContainerMapWrapperProps {}

export const ContainerMapWrapper: FunctionComponent<IContainerMapWrapperProps> = (props) => {
  const { containersForMap } = useContext(ContainerPageContext)
  const [pinsForMap, setPinsForMap] = useState<any[]>([])

  useEffect(() => {
    const pinsForMap: IPin[] = containersForMap.map((c) => {
      const genericContainer: IGenericContainer = {
        qr_code: c.qr_code,
        lat: c.lat!,
        long: c.long!,
        address: c.containerAddress,
        container_type: c.container_type,
        varnishing: c.varnishing,
        location: c.location,
        construction_year: c.construction_year,
        lastStatus: c.lastStatus,
        statusInfo: c.statusInfo,
        lastSensorTimeSend: c.lastSensorTimeSend,
        actualLocation: c.actual_location,
        last_interaction: c.last_interaction,
        last_client_name: c.last_client_name,
      }

      return {
        ...genericContainer,
        markerInfoWindowContent: <MarkerContainerInfoWindowContent genericContainer={genericContainer} />,
      }
    })
    setPinsForMap(pinsForMap)
  }, [containersForMap])

  return (
    <ContentContainer>
      <Map pins={pinsForMap} />
    </ContentContainer>
  )
}
