import { gql } from "apollo-boost"
import { deleteContainerIssues, deleteContainerIssuesVariables } from "./types/deleteContainerIssues"

export const DELETE_CONTAINER_ISSUES_MUTATION = gql`
  mutation deleteContainerIssues($idsToDelete: [Int!]!) {
    deleteContainerIssues(idsToDelete: $idsToDelete) {
      message
    }
  }
`

export type DeleteContainerErrorResult = deleteContainerIssues
export type DeleteContainerErrorVariables = deleteContainerIssuesVariables
