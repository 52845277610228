import { Grid } from "@material-ui/core"
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers"
import moment from "moment"
import { FC, useMemo } from "react"
import { useQuery } from "react-apollo"
import { useTranslation } from "react-i18next"
import {
  INVENTORY_CONFIRMATIONS,
  InventoryConfirmationsResult,
  InventoryConfirmationsVariables,
} from "../../../../api/graphql/query/inventory_confirmations"
import { ContainerInventoryLocationAutocomplete } from "../../../partials/custom-select-components/container-inventory-location-autocomplete"
import { CustomDatePicker } from "../../../partials/datepicker/custom-datepicker"
import { ContentCard } from "../../../partials/layout/content/ContentCard"
import { ConfirmInventoryButton } from "../ConfirmInventoryButton"
import { useContainerInventoryContext } from "../ContainerInventoryProvider"

export const ContainerInventoryFilter: FC = () => {
  const { selectedDate, setSelectedDate, setSelectedLocation, selectedLocation, containerLocations } =
    useContainerInventoryContext()
  const { t } = useTranslation()

  const { data: inventoryConfirmationsResult, refetch: refetchInventoryConfirmations } = useQuery<
    InventoryConfirmationsResult,
    InventoryConfirmationsVariables
  >(INVENTORY_CONFIRMATIONS, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    skip: !selectedLocation,
    variables: {
      locationId: selectedLocation?.id ?? "-1",
    },
  })

  const inventoryConfirmation = useMemo(
    () => inventoryConfirmationsResult?.inventoryConfirmations ?? [],
    [inventoryConfirmationsResult]
  )

  // highlight inventorised days
  const customDaySlot = (props: PickersDayProps<moment.Moment> & { selectedDay?: moment.Moment | null }) => {
    const { day, ...other } = props
    const dates = inventoryConfirmation.map((c) => moment(c.inventorisedAt).format("DD:MM:YYYY"))
    const existingDate = dates.includes(day.format("DD:MM:YYYY"))
    if (existingDate) {
      return <PickersDay {...other} day={day} style={{ border: "1px solid #c64559" }} />
    }

    return <PickersDay {...other} day={day} />
  }

  const handleRefetchDates = async () => {
    await refetchInventoryConfirmations()
  }

  return (
    <ContentCard>
      <Grid container direction={"row"} spacing={6}>
        <Grid item xs={4}>
          <ContainerInventoryLocationAutocomplete
            id={"container-inventory-location-filter"}
            title={t("generic.location")}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            locations={containerLocations}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomDatePicker
            label={t("general.date")}
            fullWidth
            format="DD.MMM YYYY"
            value={selectedDate}
            onChange={(date) => {
              if (!date) return
              setSelectedDate(date.toDate())
            }}
            customDaySlot={customDaySlot}
          />
        </Grid>
        <Grid item xs={4}>
          <ConfirmInventoryButton
            selectedDate={selectedDate}
            selectedLocationId={selectedLocation?.id ? Number(selectedLocation?.id) : null}
            onInventorised={handleRefetchDates}
          />
        </Grid>
      </Grid>
    </ContentCard>
  )
}
