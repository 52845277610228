import React, { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import ReactTable, { Column, SortedChangeFunction } from "react-table"
import "react-table/react-table.css"

interface ICustomStripedTableProps {
  data: any[]
  columns: Column[]
  page: number
  onPageChange: (currentPage: number) => void
  pageSize: number
  onPageSizeChange: (pageSize: number) => void
  pages: number
  loading: boolean
  multiSort?: boolean
  sortable?: boolean
  sorted?: any[]
  onSortedChange?: SortedChangeFunction
  defaultSorted?: Array<{ id: string; desc: boolean }>
}

export const CustomStripedTable: React.FunctionComponent<PropsWithChildren<ICustomStripedTableProps>> = (props) => {
  const { t } = useTranslation()

  const { children, sortable, ...rest } = props

  return (
    <ReactTable
      manual
      showPageJump={false} // React won't reset the page with the jump active: https://stackoverflow.com/a/63270551
      className="-striped"
      sortable={sortable || false}
      multiSort={false}
      pageSizeOptions={[10, 20, 50]}
      nextText={t("general.next")}
      previousText={t("general.back")}
      pageText={t("general.page")}
      ofText={t("general.of")}
      rowsText={t("general.rows")}
      loadingText={t("general.loading")}
      noDataText={t("general.no_data")}
      {...rest}
    />
  )
}
