import React, { createContext, FunctionComponent, PropsWithChildren, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { useTranslation } from "react-i18next"
import {
  ContainerLocation,
  ContainerLocationsResult,
  CONTAINER_LOCATIONS_QUERY,
} from "../../../api/graphql/query/container_locations"
import {
  DamageReport,
  DamageReportsResult,
  DamageReportsVariables,
  DAMAGE_REPORTS_QUERY,
} from "../../../api/graphql/query/damage_reports"
import { SelectOption } from "../../../components/partials/custom-select-components/custom-select-component-commons"
import { DamageReportStatus } from "../../partials/custom-select-components/damage-report-status-autocomplete"

export interface IDamageReportsPageContext {
  damageReports: DamageReport[]
  refetchDamageReports: () => void

  totalDamageReportsCount: number
  currentPage: number
  setCurrentPage: (currentPage: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  totalPages: number

  containerLocations: ContainerLocation[]
  selectedContainerLocations: SelectOption[]
  setSelectedContainerLocations: (locations: SelectOption[]) => void

  selectedStatus: SelectOption | null
  setSelectedStatus: (locations: SelectOption | null) => void

  qrCodeFilterText: string
  setQrCodeFilterText: (text: string) => void

  isDamageReportsLoading: boolean
  isDamageReportsError: Error | undefined
}

export const DamageReportsPageContext = createContext<IDamageReportsPageContext>({} as IDamageReportsPageContext)

interface IDamageReportsPageContextProviderProps {}

export const DamageReportsPageContextProvider: FunctionComponent<PropsWithChildren<IDamageReportsPageContextProviderProps>> = (props) => {
  const { children } = props
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(20)

  const option = new SelectOption(DamageReportStatus.Open, t(`damage_reports.filter.status.${DamageReportStatus.Open}`))
  const [selectedStatus, setSelectedStatus] = useState<SelectOption | null>(option)
  const [selectedContainerLocations, setSelectedContainerLocations] = useState<SelectOption[]>([])
  const [qrCodeFilterText, setQrCodeFilterText] = useState<string>("")

  const { data: containerLocationsData } = useQuery<ContainerLocationsResult>(CONTAINER_LOCATIONS_QUERY, {
    errorPolicy: "all",
  })

  const {
    data: damageReportsData,
    loading: isDamageReportsLoading,
    error: isDamageReportsError,
    refetch: refetchDamageReports,
  } = useQuery<DamageReportsResult, DamageReportsVariables>(DAMAGE_REPORTS_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    variables: {
      page: currentPage,
      pageSize: pageSize,
      locationIds: selectedContainerLocations.map((selectedContainerLocation) => selectedContainerLocation.id),
      qrCodeFilter: qrCodeFilterText || null,
      damageReportStatus: selectedStatus?.id,
    },
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [selectedContainerLocations, qrCodeFilterText, selectedStatus])

  const damageReports = damageReportsData?.damageReports.entries || []

  const totalDamageReportsCount = damageReportsData?.damageReports.totalEntries || 0
  const totalPages = damageReportsData?.damageReports.totalPages || 1

  const containerLocations = containerLocationsData?.containerLocations || []

  return (
    <DamageReportsPageContext.Provider
      value={{
        damageReports,
        refetchDamageReports,
        totalDamageReportsCount,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        totalPages,
        containerLocations,
        selectedContainerLocations,
        setSelectedContainerLocations,
        isDamageReportsLoading,
        isDamageReportsError,
        qrCodeFilterText,
        setQrCodeFilterText,
        selectedStatus,
        setSelectedStatus,
      }}
    >
      {children}
    </DamageReportsPageContext.Provider>
  )
}
