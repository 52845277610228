export class CommonHelper {
  public static buildDriverCredentials(
    driverPersonalNumber?: string | null,
    driverName?: string | null
  ): string | undefined {
    if (!driverPersonalNumber && !driverName) {
      return undefined
    }

    if (driverPersonalNumber && driverName) {
      return `${driverPersonalNumber} - ${driverName}`
    }

    return driverPersonalNumber || driverName || undefined
  }

  public static buildVehicleCredentials(
    vehicleNumber?: string | null,
    vehicleRegistrationNumber?: string | null
  ): string | undefined {
    if (!vehicleRegistrationNumber && !vehicleNumber) {
      return undefined
    }

    if (vehicleRegistrationNumber && vehicleNumber) {
      return `${vehicleNumber} - ${vehicleRegistrationNumber}`
    }

    return vehicleNumber?.toString() || vehicleRegistrationNumber || undefined
  }
}
