import i18n from "../../../utils/i18n"
export enum SortGroupEnum {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
}
export enum CommonSelectOptionIdEnum {
  SelectAll = "SelectAll",
  ClearSelection = "ClearSelection",
  Null = "NULL",
  Other = "OTHER",
}
export class SelectOption {
  constructor(public id: string, public name: string, public sortGroup: SortGroupEnum = SortGroupEnum.Fourth) {}
}
export const PredefinedSelections = {
  SelectAll: new SelectOption(
    CommonSelectOptionIdEnum.SelectAll,
    i18n.t("custom_autocomplete.selectAll"),
    SortGroupEnum.First
  ),
  ClearSelection: new SelectOption(
    CommonSelectOptionIdEnum.ClearSelection,
    i18n.t("custom_autocomplete.clearSelection"),
    SortGroupEnum.First
  ),
}
