import lodash from "lodash";

//----------------------------------------------------------------------------------------------
//
export enum EnvKey {
  // REACT_APP variables
  REACT_APP_REST_ENDPOINT,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_CONTAINER_INFO_TOKEN,
  REACT_APP_SLO_CLIENT_ID,

  // Hardcoded
  DUMMY_HARDCODED
}

// Hardcoded variables
// they can be used as fallback
const EnvKeyHardcoded: Object = {
  DUMMY_HARDCODED: "this is a dummy variable, only to show the functionality",
}

//----------------------------------------------------------------------------------------------

/// Different sources of environment variables
const EnvSources = {
// variables from .env file
  process: process.env,
// variables from EnvKeyHardcoded object
  hardcoded: EnvKeyHardcoded,
}

//----------------------------------------------------------------------------------------------

export class Environment {

// MARK: - Accessors
  static valueFor(key: EnvKey | string): any | null {
    const keyAsString = Environment.keyString(key)
    let value: any | null

    value = lodash.get(EnvSources.process, keyAsString)
         ?? lodash.get(EnvSources.hardcoded, keyAsString)

    if(lodash.isNil(value)) {
      console.warn(`🌍 ENVIRONMENT: No value for KEY: ${keyAsString}`)
      return null
    }
    return value
  }

  static numberFor(key: EnvKey): number | null {
    const value = Environment.valueFor(key)
    if(!lodash.isNumber(value)) {
      const keyAsString = Environment.keyString(key)
      console.warn(`🌍 ENVIRONMENT: No number for KEY: ${keyAsString} ${value}`)
      return null
    }
    return value
  }

  static stringFor(key: EnvKey): string | null {
    const value = Environment.valueFor(key)
    if(!lodash.isString(value) || lodash.isEmpty(value)) {
      const keyAsString = Environment.keyString(key)
      console.warn(`🌍 ENVIRONMENT: No string for KEY: ${keyAsString} ${value}`)
      return null
    }
    return value
  }
//----------------------------------------------------------------------------------------------

// MARK: - Helpers

  // Iterates all known environment keys and fails HARD if any key is not set!
  static validateAllKeys() {
    let failed = false
    for (let keyInt of Object.values(EnvKey)) {
      if (!lodash.isInteger(keyInt)) { continue }

      const value = Environment.valueFor(keyInt)
      if (lodash.isNil(value)) {
        failed = true
      }
    }
    if (failed) {
      // Intentionally fails HARD! go check: EnvKey <-> .env
      throw new Error(`🌍 ENVIRONMENT: Not every key was assigned to a value. See console for more information`)
    }
  }

  static printAllKeys() {
    for (const keyInt in Object.values(EnvKey)) {
      if (!lodash.isInteger(keyInt)) { continue }

      const value = Environment.valueFor(keyInt)
      const keyAsString = Environment.keyString(keyInt)
      console.log(`🌍 ENV: ${keyAsString} -> ${value}`)
    }
  }

  private static keyString(keyInt: EnvKey | string): string {
    if (lodash.isString(keyInt)) { return keyInt }
    return EnvKey[keyInt]
  }
//----------------------------------------------------------------------------------------------

}
