import React from "react"
import { ContentContainer } from "../../partials/layout/content/ContentContainer"
import { ContainerTable } from "./ContainerTable"

export interface IContainerTableWrapperProps {}

export const ContainerTableWrapper: React.FunctionComponent<IContainerTableWrapperProps> = (props) => {
  return (
    <ContentContainer>
      <ContainerTable />
    </ContentContainer>
  )
}
