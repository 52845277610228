import React, { Fragment, FunctionComponent } from "react"
import { NonContainerTrackingPageContent } from "./NonContainerTrackingPageContent"
import { NonContainerTrackingPageContextProvider } from "./context/NonContainerTrackingPageContext"

interface INonContainerTrackingPageProps {}

export const NonContainerTrackingPage: FunctionComponent<INonContainerTrackingPageProps> = (props) => {
  return (
    <Fragment>
      <NonContainerTrackingPageContextProvider>
        <NonContainerTrackingPageContent />
      </NonContainerTrackingPageContextProvider>
    </Fragment>
  )
}
