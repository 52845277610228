import { makeStyles, TextField, Theme } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { SelectOption } from "./custom-select-component-commons"

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete_root: {
    minWidth: 100,
    width: "100%",
  },
  custom_autocomplete_option: {
    "&[aria-selected='true']": {
      backgroundColor: "rgba(0, 0, 0, 0.4) !important",
      color: "white !important",
    },
    "& #SelectAll": {
      fontWeight: "bold",
    },
    "& #ClearSelection": {
      fontWeight: "bold",
    },
  },
}))

export enum VehicleDamageReportStatus {
  Open = "Open",
  Completed = "Completed",
}

interface IVehicleDamageReportStatusAutocompleteProps {
  id: string
  title: string
  selectedStatus: SelectOption | null
  setSelectedStatus: (status: SelectOption | null) => void
}

export const VehicleDamageReportStatusAutocomplete: React.FC<IVehicleDamageReportStatusAutocompleteProps> = (props) => {
  const classes = useStyles()
  const { id, title, selectedStatus, setSelectedStatus } = props
  const { t } = useTranslation()

  const options = useMemo(() => {
    return Object.values(VehicleDamageReportStatus).map(
      (status) => new SelectOption(status, t(`vehicle_damage_reports.filter.status.${status}`))
    )
  }, [t])

  return (
    <Autocomplete
      value={selectedStatus}
      multiple={false}
      id={id}
      options={options}
      getOptionLabel={(selectOption) => selectOption.name}
      renderInput={(params) => <TextField {...params} variant="standard" label={title} placeholder={title} />}
      renderOption={(selectOption) => <span id={selectOption.id}>{selectOption.name}</span>}
      classes={{
        root: classes.autocomplete_root,
        option: classes.custom_autocomplete_option,
      }}
      noOptionsText={t("custom_autocomplete.noOptions")}
      onChange={(e, value) => {
        setSelectedStatus(value)
      }}
    />
  )
}
