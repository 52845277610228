import axios, { AxiosInstance } from "axios"
import { LOCAL_STORAGE_CONSTANTS } from "../utils/constants"

export enum MissingType {
  CONTAINER = "container",
}

export default class SensorPortalApi {
  baseUrl: string
  client: AxiosInstance

  constructor(url: string, removeJwtToken: () => void) {
    const properties = {
      timeout: 3000000,
      headers: {
        "x-authorization": localStorage.getItem(LOCAL_STORAGE_CONSTANTS.JWT_TOKEN),
      },
    }
    this.baseUrl = url
    this.client = axios.create({
      baseURL: this.baseUrl,
      ...properties,
    })

    this.client.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          removeJwtToken()
          window.location.href = `${window.location.origin}/login`
        }
      }
    )
  }

  async login(email: string, password: string) {
    const url = "auth/login"
    return this.client.post(url, { email, password })
  }
}
