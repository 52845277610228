import { Grid, makeStyles, Theme, useTheme } from "@material-ui/core"
import lodash from "lodash"
import "moment/locale/de"
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ErrorPlaceholder } from "../../../../partials/placeholders/error-placeholder"
import { LoadingPlaceholder } from "../../../../partials/placeholders/loading-placeholder"
import { DashboardFilterContext } from "../../DashboardFilterContext"
import { DashboardWidget, IDashboardWidgetProps } from "./DashboardWidget"

interface IDashboardTriptychonProps {}

const useStyles = makeStyles((theme: Theme) => ({
  center: {
    paddingTop: 40,
  },
  loadingContainer: {
    height: 113,
    minHeight: 113,
  },
}))

export const DashboardTriptychon: FunctionComponent<IDashboardTriptychonProps> = (props) => {
  const { overallProductivity, contextLoading, contextError } = useContext(DashboardFilterContext)
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const [widgets, setWidgets] = useState<IDashboardWidgetProps[]>()

  useEffect(() => {
    let newWidgets: IDashboardWidgetProps[] = []

    if (!lodash.isNil(overallProductivity)) {
      const atClient = overallProductivity.productiveDaysAtClient ?? 0
      const atPartner = overallProductivity.unproductiveDaysAtPartner ?? 0
      const notProductive = overallProductivity.notProductiveDays ?? 0
      const total = atClient + atPartner + notProductive
      const hasAnyTotal = total > 0
      const atClientPercent = hasAnyTotal ? (100 * atClient) / total : 0
      const atPartnerPercent = hasAnyTotal ? (100 * atPartner) / total : 0
      const notProductivePercent = hasAnyTotal ? (100 * notProductive) / total : 0

      let partnerTitle = "Partner"
      if(overallProductivity.clientPartnerNames.length === 1){
          partnerTitle = overallProductivity.clientPartnerNames[0]
      }
      else if (overallProductivity.clientPartnerNames.length > 1){
        partnerTitle= t("dashboard.filter.multiplePartners")
      }

      newWidgets.push({
        title: overallProductivity.clientName || "",
        days: atClient,
        percent: atClientPercent,
        paletteColors: theme.palette.primary,
        tooltip: t("dashboard.widget.tooltip.client"),
      })

      newWidgets.push({
        title: partnerTitle,
        days: atPartner,
        percent: atPartnerPercent,
        paletteColors: theme.palette.secondary,
        tooltip: t("dashboard.widget.tooltip.partner"),
      })

      newWidgets.push({
        title: t("dashboard.widget.assignment.plant"),
        days: notProductive,
        percent: notProductivePercent,
        paletteColors: theme.palette.secondary,
        tooltip: t("dashboard.widget.tooltip.plant"),
      })
    }
    setWidgets(newWidgets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallProductivity])

  return (
    <Fragment>
      {contextLoading ? (
        <LoadingPlaceholder className={classes.loadingContainer} />
      ) : lodash.isError(contextError) ? (
        <ErrorPlaceholder title={t("general.error.api")} className={classes.loadingContainer} />
      ) : (
        <Grid container direction="row" spacing={3} justifyContent={"center"} alignItems={"stretch"}>
          {widgets?.map((widgetProp) => {
            return (
              <Grid item key={widgetProp.title} style={{ width: "33.33%" }}>
                <DashboardWidget {...widgetProp} />
              </Grid>
            )
          })}
        </Grid>
      )}
    </Fragment>
  )
}
