import { Grid } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import lodash from "lodash"
import { Text } from "../text/Text"

interface ILoadingPlaceholderProps {
  className?: string
  infoText?: string
}

export const LoadingPlaceholder: FunctionComponent<ILoadingPlaceholderProps> = (props) => {
  const { infoText } = props

  return (
    <Grid
      container
      direction={"column"}
      spacing={2}
      alignItems={"center"}
      justifyContent={"center"}
      className={props.className ?? ""}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
      {lodash.isString(infoText) && (
        <Grid item>
          <Text variant={"h5"}>{infoText}</Text>
        </Grid>
      )}
    </Grid>
  )
}
