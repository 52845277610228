import { Typography } from "@material-ui/core"
import React, { Fragment, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ContainerPageContext } from "../../../context/ContainerPageContext"
import { ContentCard } from "../../partials/layout/content/ContentCard"
import { ContainerMapWrapper } from "./ContainerMapWrapper"
import { ContainerTableWrapper } from "./ContainerTableWrapper"

export interface IContainerDataOverviewWrapperProps {}

export const ContainerDataOverviewWrapper: React.FunctionComponent<IContainerDataOverviewWrapperProps> = (props) => {
  const { t } = useTranslation()
  const { hasError, mergedContainerDataForMapError } = useContext(ContainerPageContext)

  return (
    <Fragment>
      {!hasError && !mergedContainerDataForMapError && (
        <Fragment>
          <ContainerMapWrapper />
          <ContainerTableWrapper />
        </Fragment>
      )}
      {(hasError || mergedContainerDataForMapError) && (
        <ContentCard>
          <Typography>{t("error.no_data.no_data_fetched")}</Typography>
        </ContentCard>
      )}
    </Fragment>
  )
}
