import { containerCSV, containerCSV_containerCSV } from "./types/containerCSV"
import { gql } from "apollo-boost"

export const CONTAINER_CSV = gql`
  query containerCSV(
    $locationIds: [ID!]!
    $containerTypeIds: [ID!]!
    $containerCharacteristic: ContainerCharacteristic!
    $qrCodeFilterText: String
    $connectionReferenceFilterText: String
    $containerFillStates: [String!]!
  ) {
    containerCSV(
      locationIds: $locationIds
      containerTypeIds: $containerTypeIds
      containerCharacteristic: $containerCharacteristic
      qrCodeFilterText: $qrCodeFilterText
      connectionReferenceFilterText: $connectionReferenceFilterText
      containerFillStates: $containerFillStates
    ) {
      data
    }
  }
`
export type ContainerCSV = containerCSV_containerCSV
export type ContainerCSVResult = containerCSV
