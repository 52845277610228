import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { formatDate } from "../../../../domain/helpers/formatText"
import { ContentCard } from "../../../partials/layout/content/ContentCard"
import { AdminPageContext } from "../AdminPageContext"

const useStyles = makeStyles((theme: Theme) => ({
  cloneDbHeading: {
    fontSize: "20px",
    color: theme.palette.primary.main,
  },
}))

interface IAdminContentSectionProps {}

export const AdminContentSection: React.FunctionComponent<IAdminContentSectionProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { cloneDatabaseState, triggerCloneDatabase } = useContext(AdminPageContext)

  return (
    <ContentCard>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.cloneDbHeading} align="center">
                {t("admin_menu.clone_db.title")}:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{t("clone_database_state_model.current_step")}:</Typography>
              <Typography>
                {cloneDatabaseState?.current_step
                  ? t(`clone_database_state_model.clone_database_step.${cloneDatabaseState.current_step}`)
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{t("clone_database_state_model.started_at")}:</Typography>
              <Typography>{formatDate(cloneDatabaseState?.started_at)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{t("clone_database_state_model.finished_at")}:</Typography>
              <Typography>{formatDate(cloneDatabaseState?.finished_at)}</Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <Grid item>
                <Button variant="contained" type="button" color="primary" onClick={triggerCloneDatabase}>
                  {t("admin_menu.clone_db.trigger_clone_button")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentCard>
  )
}
