import { Grid } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { InventoryListCsvDownloadButton } from "./InventoryListDownloadButton"
import { UnchangedContainersFilter } from "./unchanged-containers-filter/UnchangedContainersFilter"
import { UnchangedContainersTable } from "./unchanged-containers-table/UnchangedContainersTable"
import { UnchangedContainersPageContextProvider } from "./UnchangedContainersPageContext"

interface IUnchangedContainersPageProps {}

export const UnchangedContainersPage: React.FunctionComponent<IUnchangedContainersPageProps> = (props) => {
  const { t } = useTranslation()

  return (
    <UnchangedContainersPageContextProvider>
      <PageHeading title={t("unchanged_container.heading")}>
        <InventoryListCsvDownloadButton />
      </PageHeading>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UnchangedContainersFilter />
        </Grid>
        <Grid item xs={12}>
          <UnchangedContainersTable />
        </Grid>
      </Grid>
    </UnchangedContainersPageContextProvider>
  )
}
