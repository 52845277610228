import React, { FunctionComponent } from "react"
import { Grid, makeStyles, Theme, Tooltip } from "@material-ui/core"
import { Text } from "../../../../partials/text/Text"
import { SimplePaletteColorOptions } from "@material-ui/core/styles/createPalette"
import { useTranslation } from "react-i18next"

export interface IDashboardWidgetProps {
  title: string
  days: number
  percent: number
  paletteColors: SimplePaletteColorOptions
  tooltip?: string
}

// should sum up to 100 ;)
const triplePoint = {
  first: 28,
  second: 28,
  third: 44,
}

const triplePointPercent = {
  first: `${triplePoint.first}%`,
  second: `${triplePoint.second}%`,
  third: `${triplePoint.third}%`,
}

const backGroundStop = {
  first: triplePoint.first,
  second: triplePoint.first + triplePoint.second,
}

const useStyles = makeStyles((theme: Theme) => ({
  myBackground: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: 4,
    height: 120,
  },
  widgetText: {
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}))

function createTripletBackground(paletteColors: SimplePaletteColorOptions): string {
  return `linear-gradient( 
            to bottom,
            ${paletteColors.light},
            ${paletteColors.light} ${backGroundStop.first}%,
            ${paletteColors.main} ${backGroundStop.first}%,
            ${paletteColors.main} ${backGroundStop.second}%,
            ${paletteColors.dark} ${backGroundStop.second}%
           )`
}

export const DashboardWidget: FunctionComponent<IDashboardWidgetProps> = (props) => {
  const { paletteColors } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const backgroundStyle = {
    background: createTripletBackground(paletteColors),
  }

  return (
    <div className={classes.myBackground} style={backgroundStyle}>
      <Grid
        container
        direction="column"
        spacing={0}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ height: "100%" }}
      >
        <Grid item style={{ height: triplePointPercent.first }}>
          <Text
            variant={"h3"}
            color={"textPrimary"}
            className={classes.widgetText + " " + classes.centered}
            themeColor={paletteColors.contrastText}
          >
            {props.title}
          </Text>
        </Grid>
        <Grid item style={{ height: triplePointPercent.second }}>
          <Text
            variant={"h3"}
            className={classes.widgetText + " " + classes.centered}
            themeColor={paletteColors.contrastText}
          >
            {`${Number(props.percent).toFixed(2)} %`}
          </Text>
        </Grid>
        <Tooltip title={props.tooltip ?? ""}>
          <Grid item style={{ height: triplePointPercent.third }}>
            <Text
              variant={"h3"}
              className={classes.widgetText + " " + classes.centered}
              themeColor={paletteColors.contrastText}
            >
              {`${Number(props.days)} ${props.days === 1 ? t("dashboard.widget.day") : t("dashboard.widget.days")}`}
            </Text>
          </Grid>
        </Tooltip>
      </Grid>
    </div>
  )
}
