import React, { PropsWithChildren } from 'react';
import { Theme, makeStyles } from "@material-ui/core";

interface IHeadingProps {
    children: any
}

const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
        marginTop:12
    }
}))

export const ContentContainer: React.FunctionComponent<PropsWithChildren<IHeadingProps>> = (props) => {
    const classes = useStyles()
    const { children } = props
    return (
        <div className={classes.contentContainer}>
            {children}
        </div>
    )
}
