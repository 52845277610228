import { gql } from "apollo-boost"
import { completeVehicleDamageReport, completeVehicleDamageReportVariables } from "./types/completeVehicleDamageReport"

export const COMPLETE_VEHICLE_DAMAGE_REPORT = gql`
  mutation completeVehicleDamageReport($data: CompleteVehicleDamageReportInput!) {
    completeVehicleDamageReport(data: $data) {
      message
    }
  }
`

export type CompleteVehicleDamageReport = completeVehicleDamageReport
export type CompleteVehicleDamageReportVariables = completeVehicleDamageReportVariables
