import moment from "moment"
import { ContainerCharacteristic } from "./../api/graphql/graphql-global-types"

export const exportContainersCSV = (containerCharacteristic: ContainerCharacteristic, data: string) => {
  if (data) {
    const element = document.createElement("a")
    const file = new Blob(["\uFEFF", data], {
      type: "text/csv",
    })
    element.href = URL.createObjectURL(file)
    element.download = `containers_${
      containerCharacteristic === ContainerCharacteristic.PRODUCTION ? "production" : "logistic"
    }_${moment().format("YYYY-MM-DD")}.csv`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}

export const exportContainerStatisticCSV = (characteristic: ContainerCharacteristic, data: string) => {
  if (data) {
    const element = document.createElement("a")
    const file = new Blob(["\uFEFF", data], {
      type: "text/csv",
    })
    element.href = URL.createObjectURL(file)
    element.download = `${characteristic}_container_statistic_export_${moment().format("YYYY-MM-DD")}.csv`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}

export const exportInventoryListCSV = (data: string) => {
  if (data) {
    const element = document.createElement("a")
    const file = new Blob(["\uFEFF", data], {
      type: "text/csv",
    })
    element.href = URL.createObjectURL(file)
    element.download = `inventory_list_${moment().format("YYYY-MM-DD")}.csv`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}
