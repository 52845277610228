import React, { useEffect, useMemo, useRef, useState } from "react"
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"
import { Card } from "@material-ui/core"
import { MarkerInfoWindow } from "./MarkerInfoWindow"
import { createMarker } from "../../../assets/map/Markers"
import { GREEN_MARKER, RED_MARKER } from "../../../styles/theme"
import { IGenericContainer } from "../../../domain/models/interfaces/IGenericSensorOrContainer"
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer"
import { Environment, EnvKey } from "../../../utils/environment"

export interface IPin extends IGenericContainer {
  markerInfoWindowContent?: JSX.Element | undefined
}

interface IMapProps {
  pins: IPin[]
}

interface IRawMapProps {
  pins: IPin[]
}

// has to be hardcoded, since we dont have the possibility to utilize environment variables
const googleMapsApiKey = Environment.stringFor(EnvKey.REACT_APP_GOOGLE_MAPS_API_KEY) ?? "--"
const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&v=3.exp&libraries=geometry,drawing,places,visualization`

const defaultCenter = { lat: 47.0707, lng: 15.4395 }

const loadingElement = <div />
const containerElement = <div style={{ height: "50vh" }} />
const mapElement = <div style={{ height: "50vh" }} />

const RawMap = withScriptjs(
  withGoogleMap((props: IRawMapProps) => {
    const mapRef = useRef(null)
    const { pins } = props

    const [activeContainerMarker, setActiveContainerMarker] = useState<string | undefined | null>(null)

    const markers = useMemo(
      () =>
        pins.map((pin) => {
          const id = pin.qr_code
          let markerIcon = createMarker(GREEN_MARKER)
          if (pin.filllevel && pin.filllevel > 75) {
            markerIcon = createMarker(RED_MARKER)
          }
          return (
            <Marker
              key={`${id}`}
              position={{ lat: pin.lat, lng: pin.long }}
              animation={2}
              onClick={() => setActiveContainerMarker(id)}
              icon={markerIcon}
            />
          )
        }),
      [pins]
    )

    useEffect(() => {
      const latLngBounds = pins.reduce((prev, curr) => {
        return prev.extend({ lat: curr.lat, lng: curr.long })
      }, new google.maps.LatLngBounds())

      const map = mapRef.current as any
      if (!latLngBounds.isEmpty()) {
        map.fitBounds(latLngBounds, 5)
      }
    }, [pins])

    const selectedPin = useMemo(() => {
      return pins.find((pin) => pin.qr_code === activeContainerMarker)
    }, [activeContainerMarker, pins])

    return (
      <GoogleMap ref={mapRef} defaultCenter={defaultCenter} defaultZoom={7} options={{ disableDefaultUI: true }}>
        <MarkerClusterer maxZoom={13}>{markers}</MarkerClusterer>
        {selectedPin && (
          <MarkerInfoWindow
            setActiveSensorMarker={setActiveContainerMarker}
            position={{ lat: selectedPin.lat, lng: selectedPin.long }}
          >
            {selectedPin.markerInfoWindowContent}
          </MarkerInfoWindow>
        )}
      </GoogleMap>
    )
  })
)

export const Map: React.FunctionComponent<IMapProps> = (props: IMapProps) => {
  const { pins } = props

  return (
    <Card>
      <RawMap
        loadingElement={loadingElement}
        containerElement={containerElement}
        googleMapURL={googleMapURL}
        mapElement={mapElement}
        pins={pins}
      />
    </Card>
  )
}
