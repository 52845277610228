import { Grid, makeStyles, Theme } from "@material-ui/core"
import { FC, useContext } from "react"
import { useMutation } from "react-apollo"
import { useTranslation } from "react-i18next"
import "react-table/react-table.css"
import {
  DELETE_CONTAINER_ISSUES_MUTATION,
  DeleteContainerErrorResult,
  DeleteContainerErrorVariables,
} from "../../../api/graphql/mutations/DeleteContainerIssuesMutation"
import { ContainerIssueForTable } from "../../../api/graphql/query/get-container-issues-for-table"
import { YesOrNoConfirmationDialog } from "../../partials/dialog/yes-or-no-confirmation-dialog"
import { ContainerIssuePageContext } from "./context/ContainerIssuePageContext"

const useStyles = makeStyles((theme: Theme) => ({
  spacingTop: {
    marginTop: 20,
  },
}))

export interface IDeleteContainerIssuesDialogProps {
  open: boolean
  onClose: () => void
  elementsToDelete: ContainerIssueForTable[]
  isDeleteAllElements: boolean
  resetSelectedValues: () => void
}

export const DeleteContainerIssuesDialog: FC<IDeleteContainerIssuesDialogProps> = (props) => {
  const { open, onClose, elementsToDelete, isDeleteAllElements, resetSelectedValues } = props

  const classes = useStyles()
  const { t } = useTranslation()

  const { refetchContainerIssues, currentPage, setCurrentPage, totalPages } = useContext(ContainerIssuePageContext)

  const [deleteContainerIssuesTrackings] = useMutation<DeleteContainerErrorResult, DeleteContainerErrorVariables>(
    DELETE_CONTAINER_ISSUES_MUTATION,
    {
      variables: {
        idsToDelete: elementsToDelete.map((elementToDelete) => elementToDelete.id),
      },
      onCompleted: () => {
        onClose()
        resetSelectedValues()

        if (isDeleteAllElements && currentPage === totalPages - 1) {
          // if all elements of the last page got deleted, we need to switch to the preceding page
          setCurrentPage(Math.max(currentPage - 1, 0))
        }

        refetchContainerIssues()
      },
    }
  )

  function renderDeleteInfoText() {
    return elementsToDelete.length === 1
      ? t("container_issues.delete_dialog.delete_entries_text_singular")
      : t("container_issues.delete_dialog.delete_entries_text", {
          selectedEntriesCount: elementsToDelete.length,
        })
  }

  return (
    <YesOrNoConfirmationDialog
      open={open}
      onClose={onClose}
      onConfirm={() => deleteContainerIssuesTrackings()}
      content={
        <Grid container direction="column">
          <Grid item xs={12}>
            {t("container_issues.delete_dialog.title")}
          </Grid>
          <Grid item container xs={12} className={classes.spacingTop} justifyContent="center">
            <Grid item xs={12}>
              {renderDeleteInfoText()}
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
