import { Grid, TextField } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { UserPermissionNameEnum } from "../../../api/graphql/graphql-global-types"
import { GlobalFilterContext } from "../../../context/GlobalFilterContext"
import { UserContext } from "../../../context/UserContext"
import { ContainerFillStateCustomAutocomplete } from "../../partials/custom-select-components/container-fill-state-custom-autocomplete"
import { ContainerLocationCustomAutocomplete } from "../../partials/custom-select-components/container-location-custom-autocomplete"
import { ContainerTypeCustomAutocomplete } from "../../partials/custom-select-components/container-type-custom-autocomplete"
import { ContentCard } from "../../partials/layout/content/ContentCard"

export interface IContainerFilterProps {}

export const ContainerFilter: React.FunctionComponent<IContainerFilterProps> = (props) => {
  const { t } = useTranslation()

  const { hasPermission } = useContext(UserContext)

  const {
    containerLocations,
    selectedContainerLocations,
    setSelectedContainerLocations,
    containerTypes,
    selectedContainerTypes,
    setSelectedContainerTypes,
    qrCodeFilterText,
    setQrCodeFilterText,
    setSelectedPartners,
    connectionReferenceFilterText,
    setConnectionReferenceFilterText,
    selectedContainerFillStates,
    setSelectedContainerFillStates,
  } = useContext(GlobalFilterContext)

  // componentDidMount-Hook
  useEffect(
    () => {
      // TODO: refactor in future - we want to have a seperate context for each page.
      setSelectedContainerLocations([])
      setSelectedContainerTypes([])
      setSelectedPartners([])
      setQrCodeFilterText("")
      setConnectionReferenceFilterText("")
      setSelectedContainerFillStates([])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <ContentCard>
      <Grid container direction={"row"} spacing={6}>
        <Grid item xs={3}>
          <TextField
            id="qr-code-search"
            label={t("generic.qr_code")}
            fullWidth
            value={qrCodeFilterText}
            onChange={(event) => setQrCodeFilterText(event.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="connection-reference-search"
            label={t("connection_history_model.connectionReference")}
            fullWidth
            value={connectionReferenceFilterText}
            onChange={(event) => setConnectionReferenceFilterText(event.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <ContainerLocationCustomAutocomplete
            id="container-map-location-filter"
            title={t("generic.location")}
            currentlySelectedContainerLocations={selectedContainerLocations}
            availableContainerLocations={containerLocations || []}
            setCurrentlySelectedContainerLocations={setSelectedContainerLocations}
          />
        </Grid>
        <Grid item xs={3}>
          <ContainerTypeCustomAutocomplete
            id="container-map-type-filter"
            title={t("generic.container_type")}
            currentlySelectedContainerTypes={selectedContainerTypes}
            availableContainerTypes={containerTypes || []}
            setCurrentlySelectedContainerTypes={setSelectedContainerTypes}
          />
        </Grid>
        {hasPermission(UserPermissionNameEnum.CONTAINER_FILL_STATE_FILTER) && (
          <Grid item xs={3}>
            <ContainerFillStateCustomAutocomplete
              id="container-map-fill-state-filter"
              title={t("container_model.container_fill_state.description")}
              currentlySelectedContainerFillStates={selectedContainerFillStates}
              setCurrentlySelectedContainerFillStates={setSelectedContainerFillStates}
            />
          </Grid>
        )}
      </Grid>
    </ContentCard>
  )
}
