import { Grid, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { useContext } from "react"
import { Route, Routes } from "react-router"
import { UserPermissionNameEnum } from "../../../api/graphql/graphql-global-types"
import { GlobalFilterContextProvider } from "../../../context/GlobalFilterContext"
import { UserContext } from "../../../context/UserContext"
import "../../../styles/css/ReactTableStyle.css"
import { Sidebar } from "../../partials/sidebar/Sidebar"
import { AdminPage } from "../admin/admin-page"
import { ContainerInventoryPage } from "../container-inventory/ContainerInventoryPage"
import { ContainerIssuePage } from "../container-issue/ContainerIssuePage"
import { LogisticContainerPage } from "../container/LogisticContainerPage"
import { ProductionContainerPage } from "../container/ProductionContainerPage"
import { DamageReportsPage } from "../damage-report/DamageReportsPage"
import { Dashboard } from "../dashboard/DashboardPage"
import { NonContainerTrackingPage } from "../non-container-tracking/NonContainerTrackingPage"
import { UnchangedContainersPage } from "../unchanged-containers/UnchangedContainersPage"
import { VehicleDamageReportsPage } from "../vehicle-damage-report/VehicleDamageReportsPage"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: "100%",
    display: "flex",
    flex: 1,
    flexFlow: "column",
    padding: 16,
    marginBottom: 16,
  },
  rootContainer: {
    "flex-wrap": "noWrap",
  },
}))

export const Portal: React.FC = () => {
  const classes = useStyles()

  const { hasPermission } = useContext(UserContext)

  return (
    <Grid container className={classes.rootContainer}>
      <Grid item>
        <Sidebar />
      </Grid>
      <Grid item className={classes.content}>
        <GlobalFilterContextProvider>
          <Routes>
            <Route path={`/dashboard`} element={<Dashboard />} />
            <Route path={`/unchanged-containers`} element={<UnchangedContainersPage />} />
            <Route path={`/container`} element={<LogisticContainerPage />} />
            {hasPermission(UserPermissionNameEnum.CONTAINER_PRODUCTION_OVERVIEW_PAGE) && (
              <Route path={`/container-production`} element={<ProductionContainerPage />} />
            )}
            <Route path={`/container-issues`} element={<ContainerIssuePage />} />
            {hasPermission(UserPermissionNameEnum.DAMAGE_REPORT_OVERVIEW) && (
              <Route path={`/damage-reports`} element={<DamageReportsPage />} />
            )}
            {hasPermission(UserPermissionNameEnum.VEHICLE_DAMAGE_REPORT_OVERVIEW) && (
              <Route path={`/vehicle-damage-reports`} element={<VehicleDamageReportsPage />} />
            )}
            <Route path={`/container-inventory`} element={<ContainerInventoryPage />} />
            <Route path={`/non-container-tracking`} element={<NonContainerTrackingPage />} />
            {hasPermission(UserPermissionNameEnum.ADMIN_MENU) && <Route path={`/admin-menu`} element={<AdminPage />} />}
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </GlobalFilterContextProvider>
      </Grid>
    </Grid>
  )
}
