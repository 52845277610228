import { gql } from "apollo-boost"
import {
  mergedContainerDataForTable,
  mergedContainerDataForTableVariables,
  mergedContainerDataForTable_mergedContainerDataForTable,
  mergedContainerDataForTable_mergedContainerDataForTable_pagination,
  mergedContainerDataForTable_mergedContainerDataForTable_container,
} from "./types/mergedContainerDataForTable"

export const MERGED_CONTAINER_DATA_FOR_TABLE = gql`
  query mergedContainerDataForTable(
    $currentPage: Int!
    $pageSize: Int!
    $sorting: String!
    $locationIds: [ID!]!
    $containerTypeIds: [ID!]!
    $containerCharacteristic: ContainerCharacteristic!
    $qrCodeFilterText: String
    $connectionReferenceFilterText: String
    $containerFillStates: [String!]!
  ) {
    mergedContainerDataForTable(
      currentPage: $currentPage
      pageSize: $pageSize
      sorting: $sorting
      locationIds: $locationIds
      containerTypeIds: $containerTypeIds
      containerCharacteristic: $containerCharacteristic
      qrCodeFilterText: $qrCodeFilterText
      connectionReferenceFilterText: $connectionReferenceFilterText
      containerFillStates: $containerFillStates
    ) {
      container {
        qr_code
        internal_container_number,
        container_type
        varnishing
        rating
        comment
        location
        construction_year
        lastStatus
        statusInfo
        lastSensorTimeSend
        containerAddress
        lat
        long
        actual_location
        last_interaction
        last_client
        last_client_name
      }

      pagination {
        page
        size
        total
        pages
        hasNextPage
      }
    }
  }
`

export type MergedContainerDataForTable_Container = mergedContainerDataForTable_mergedContainerDataForTable_container
export type MergedContainerDataForTable_Pagination = mergedContainerDataForTable_mergedContainerDataForTable_pagination
export type MergedContainerDataForTable = mergedContainerDataForTable_mergedContainerDataForTable
export type MergedContainerDataForTableResult = mergedContainerDataForTable
export type MergedContainerDataForTableVariables = mergedContainerDataForTableVariables
