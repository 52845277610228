import { gql } from "apollo-boost"
import { invalidInventoryContainers, invalidInventoryContainersVariables, invalidInventoryContainers_invalidInventoryContainers, invalidInventoryContainers_invalidInventoryContainers_entries } from "./types/invalidInventoryContainers"

export const CONTAINER_INVENTORY_INVALID_CONTAINERS_QUERY = gql`
  query invalidInventoryContainers(
    $date: DateTime!
    $locationId: ID!
    $page: Int!
    $pageSize: Int!
  ) {
    invalidInventoryContainers(
      date: $date
      locationId: $locationId
      page: $page
      pageSize: $pageSize
    ) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        qr_code,
        internal_container_number,
        latitude,
        longitude,
        address,
        varnishing,
        rating,
        comment,
        construction_year,
        typeName,
        latestLocation,
        latestUpdate
      }
    }
  }
`
export type InvalidInventoryContainersResult = invalidInventoryContainers
export type InvalidInventoryContainer = invalidInventoryContainers_invalidInventoryContainers_entries
export type InvalidInventoryContainersVariables = invalidInventoryContainersVariables
export type InvalidInventoryContainersPaginationResult = invalidInventoryContainers_invalidInventoryContainers
