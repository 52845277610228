import { Grid } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { AdminContentSection } from "./admin-content/admin-content-section"
import { AdminPageContextProvider } from "./AdminPageContext"

interface IAdminPageProps {}

export const AdminPage: React.FunctionComponent<IAdminPageProps> = (props) => {
  const { t } = useTranslation()

  return (
    <AdminPageContextProvider>
      <PageHeading title={t("admin_menu.heading")} />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <AdminContentSection />
        </Grid>
      </Grid>
    </AdminPageContextProvider>
  )
}
