import { gql } from "apollo-boost"
import {
  getNonContainerTrackingsForTable,
  getNonContainerTrackingsForTableVariables,
  getNonContainerTrackingsForTable_getNonContainerTrackingsForTable_entries,
} from "./types/getNonContainerTrackingsForTable"

export const NON_CONTAINER_TRACKINGS_FOR_TABLE_QUERY = gql`
  query getNonContainerTrackingsForTable(
    $page: Int!
    $pageSize: Int!
    $reasonFilter: NonContainerTrackingReason
    $qrCodeFilterText: String
    $referenceFilterText: String
  ) {
    getNonContainerTrackingsForTable(
      page: $page
      pageSize: $pageSize
      reasonFilter: $reasonFilter
      qrCodeFilterText: $qrCodeFilterText
      referenceFilterText: $referenceFilterText
    ) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        id
        qr_code
        reason
        personal_number
        driver_name
        reference
        latitude
        longitude
        address
        created_at
      }
    }
  }
`

export type GetNonContainerTrackingsForTableResult = getNonContainerTrackingsForTable
export type GetNonContainerTrackingsForTableVariables = getNonContainerTrackingsForTableVariables
export type NonContainerTrackingForTable = getNonContainerTrackingsForTable_getNonContainerTrackingsForTable_entries
