import React, { createContext, FunctionComponent, PropsWithChildren, useState } from "react"
import { useQuery } from "react-apollo"
import { useDebounce } from "usehooks-ts"
import { Client, ClientResult, CLIENTS_QUERY } from "../api/graphql/query/clients"
import {
  ContainerLocation,
  ContainerLocationsResult,
  CONTAINER_LOCATIONS_QUERY,
} from "../api/graphql/query/container_locations"
import { ContainerType, ContainerTypesResult, CONTAINER_TYPES_QUERY } from "../api/graphql/query/container_types"
import { SelectOption } from "../components/partials/custom-select-components/custom-select-component-commons"

export interface IGlobalFilterContext {
  containerLocations: ContainerLocation[]
  selectedContainerLocations: SelectOption[]
  setSelectedContainerLocations: (locations: SelectOption[]) => void

  containerTypes: ContainerType[]
  selectedContainerTypes: SelectOption[]
  setSelectedContainerTypes: (types: SelectOption[]) => void

  partners: Client[]
  selectedPartners: SelectOption[]
  setSelectedPartners: (types: SelectOption[]) => void

  qrCodeFilterText: string
  setQrCodeFilterText: (text: string) => void
  debouncedQrCodeFilterText: string

  connectionReferenceFilterText: string
  setConnectionReferenceFilterText: (text: string) => void
  debouncedConnectionReferenceFilterText: string

  selectedContainerFillStates: SelectOption[]
  setSelectedContainerFillStates: (types: SelectOption[]) => void
}

export const GlobalFilterContext = createContext<IGlobalFilterContext>({
  containerLocations: [],
  selectedContainerLocations: [],
  setSelectedContainerLocations: (_) => {},

  containerTypes: [],
  selectedContainerTypes: [],
  setSelectedContainerTypes: (_) => {},

  partners: [],
  selectedPartners: [],
  setSelectedPartners: (_) => {},

  qrCodeFilterText: "",
  setQrCodeFilterText: (_) => {},
  debouncedQrCodeFilterText: "",

  connectionReferenceFilterText: "",
  setConnectionReferenceFilterText: (_) => {},
  debouncedConnectionReferenceFilterText: "",

  selectedContainerFillStates: [],
  setSelectedContainerFillStates: (_) => {},
})

interface IGlobalFilterContextProviderProps {}

export const GlobalFilterContextProvider: FunctionComponent<PropsWithChildren<IGlobalFilterContextProviderProps>> = (props) => {
  const { children } = props
  const [selectedContainerLocations, setSelectedContainerLocations] = useState<SelectOption[]>([])
  const [selectedContainerTypes, setSelectedContainerTypes] = useState<SelectOption[]>([])
  const [selectedPartners, setSelectedPartners] = useState<SelectOption[]>([])

  const [qrCodeFilterText, setQrCodeFilterText] = useState<string>("")
  const debouncedQrCodeFilterText = useDebounce(qrCodeFilterText, 500)

  const [connectionReferenceFilterText, setConnectionReferenceFilterText] = useState<string>("")
  const debouncedConnectionReferenceFilterText = useDebounce(connectionReferenceFilterText, 500)

  const [selectedContainerFillStates, setSelectedContainerFillStates] = useState<SelectOption[]>([])

  const { data: containerLocationsData } = useQuery<ContainerLocationsResult>(CONTAINER_LOCATIONS_QUERY, {
    errorPolicy: "all",
  })

  const { data: containerTypesData } = useQuery<ContainerTypesResult>(CONTAINER_TYPES_QUERY, {
    errorPolicy: "all",
  })

  const { data: clientData } = useQuery<ClientResult>(CLIENTS_QUERY, {
    errorPolicy: "all",
  })

  const containerLocations = containerLocationsData?.containerLocations || []
  const containerTypes = containerTypesData?.containerTypes || []
  const partners = clientData?.clients || []

  return (
    <GlobalFilterContext.Provider
      value={{
        containerLocations,
        selectedContainerLocations,
        setSelectedContainerLocations,
        containerTypes,
        selectedContainerTypes,
        setSelectedContainerTypes,
        partners,
        selectedPartners,
        setSelectedPartners,
        qrCodeFilterText,
        setQrCodeFilterText,
        debouncedQrCodeFilterText,
        connectionReferenceFilterText,
        setConnectionReferenceFilterText,
        debouncedConnectionReferenceFilterText,
        selectedContainerFillStates,
        setSelectedContainerFillStates,
      }}
    >
      {children}
    </GlobalFilterContext.Provider>
  )
}
