import React, { useState, useEffect } from "react"
import Lightbox from "react-image-lightbox"

interface ICustomLightboxProps {
  imageUrls: string[]
  onClose: () => void
}

export const CustomLightbox: React.FunctionComponent<ICustomLightboxProps> = (props) => {
  const { imageUrls, onClose } = props
  const [imageIndex, setImageIndex] = useState<number>(0)

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  return (
    <Lightbox
      mainSrc={imageUrls[imageIndex]}
      nextSrc={imageUrls.length > 1 ? imageUrls[(imageIndex + 1) % imageUrls.length] : undefined}
      prevSrc={imageUrls.length > 1 ? imageUrls[(imageIndex + imageUrls.length - 1) % imageUrls.length] : undefined}
      onCloseRequest={onClose}
      onMovePrevRequest={() => setImageIndex((imageIndex + imageUrls.length - 1) % imageUrls.length)}
      onMoveNextRequest={() => setImageIndex((imageIndex + 1) % imageUrls.length)}
      reactModalStyle={{ overlay: { zIndex: 1300 } }}
    />
  )
}
