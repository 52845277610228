import { gql } from "apollo-boost"
import {
  containerIssuesForMap,
  containerIssuesForMapVariables,
  containerIssuesForMap_containerIssuesForMap,
} from "./types/containerIssuesForMap"

export const CONTAINER_ISSUES_FOR_MAP_QUERY = gql`
  query containerIssuesForMap(
    $locationIds: [String!]!
    $containerTypeIds: [String!]!
    $qrCodeFilter: String
    $containerErrorTypeFilter: ContainerErrorType
    $connectionReferenceFilterText: String
  ) {
    containerIssuesForMap(
      locationIds: $locationIds
      containerTypeIds: $containerTypeIds
      qrCodeFilter: $qrCodeFilter
      containerErrorTypeFilter: $containerErrorTypeFilter
      connectionReferenceFilterText: $connectionReferenceFilterText
    ) {
      id
      qr_code
      error_type
      errorMessage
      errorCreatedAt
      containerTypeName
      locationName
      lat
      long
      containerAddress
    }
  }
`

export type ContainerIssuesForMapResult = containerIssuesForMap
export type ContainerIssuesForMapVariables = containerIssuesForMapVariables
export type ContainerIssueForMap = containerIssuesForMap_containerIssuesForMap
