import React, { createContext, FunctionComponent, PropsWithChildren, useState } from "react"
import { useMutation, useQuery } from "react-apollo"
import {
  TriggerCloneDatabaseStateResult,
  TRIGGER_CLONE_DATABASE_MUTATION,
} from "../../../api/graphql/mutations/trigger-clone-database-mutation"
import {
  GetCloneDatabaseState,
  GetCloneDatabaseStateResult,
  GET_CLONE_DATABASE_STATE,
} from "../../../api/graphql/query/get-clone-database-state"

export interface IAdminPageContext {
  cloneDatabaseState: GetCloneDatabaseState | undefined
  refetchCloneDatabaseState: () => void
  isCloneDatabaseStateLoading: boolean
  isCloneDatabaseStateError: Error | undefined
  triggerCloneDatabase: () => void
}

export const AdminPageContext = createContext<IAdminPageContext>({} as IAdminPageContext)

interface IAdminPageContextProviderProps {}

export const AdminPageContextProvider: FunctionComponent<PropsWithChildren<IAdminPageContextProviderProps>> = (props) => {
  const { children } = props

  const [cloneDatabaseState, setCloneDatabaseState] = useState<GetCloneDatabaseState | undefined>(undefined)

  const {
    data: cloneDatabaseStateData,
    loading: isCloneDatabaseStateLoading,
    error: isCloneDatabaseStateError,
    refetch: refetchCloneDatabaseState,
  } = useQuery<GetCloneDatabaseStateResult>(GET_CLONE_DATABASE_STATE, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setCloneDatabaseState(cloneDatabaseStateData?.getCloneDatabaseState || undefined)
    },
  })

  const [triggerCloneDatabaseMutation] = useMutation<TriggerCloneDatabaseStateResult>(TRIGGER_CLONE_DATABASE_MUTATION, {
    onCompleted: ({ triggerCloneDatabase }) => {
      setCloneDatabaseState(triggerCloneDatabase || undefined)
    },
  })

  function triggerCloneDatabase() {
    triggerCloneDatabaseMutation()
  }

  return (
    <AdminPageContext.Provider
      value={{
        cloneDatabaseState,
        refetchCloneDatabaseState,
        isCloneDatabaseStateLoading,
        isCloneDatabaseStateError,
        triggerCloneDatabase,
      }}
    >
      {children}
    </AdminPageContext.Provider>
  )
}
