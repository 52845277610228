import { Card, Checkbox, Grid, IconButton, Theme, makeStyles } from "@material-ui/core"
import { DeleteForever } from "@material-ui/icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { CellInfo, Column } from "react-table"
import "react-table/react-table.css"
import { ContainerIssueForTable } from "../../../api/graphql/query/get-container-issues-for-table"
import { formatDate } from "../../../domain/helpers/formatText"
import { useTableCheckBoxHook } from "../../hooks/table-check-box-hook/table-check-box-hook"
import { renderHeader, renderTooltippedSpan } from "../../partials/layout/table/ReactTableHelper"
import { CustomStripedTable } from "../../partials/table/CustomStripedTable"
import { TableEntryCount } from "../../partials/table/TableEntryCount"
import { DeleteContainerIssuesDialog } from "./DeleteContainerIssuesDialog"
import { PopoverContainerHistory } from "./partials/PopoverContainerHistory"

const useStyles = makeStyles((theme: Theme) => ({
  headerCheckbox: {
    padding: 0,
    color: "white",
    height: "35px",

    "&.Mui-checked": {
      color: "white",
    },
  },
  checkBox: {
    padding: 0,
    color: "secondary",

    "&.Mui-checked": {
      color: "secondary",
    },
  },
}))

interface IContainerIssueTableProps {
  containerIssues: ContainerIssueForTable[]
  totalNumberOfContainerIssues: number
  refetchContainerIssues: () => void
  currentPage: number
  setCurrentPage: (page: number) => void
  pageSize: number
  pages: number
  setPageSize: (page: number) => void
  loading: boolean
}

export const ContainerIssueTable: React.FunctionComponent<IContainerIssueTableProps> = (props) => {
  const {
    containerIssues,
    totalNumberOfContainerIssues,
    loading,
    currentPage,
    setCurrentPage,
    pages,
    pageSize,
    setPageSize,
  } = props

  const { t } = useTranslation()
  const classes = useStyles()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const {
    selectedEntries: selectedContainerIssues,
    allEntriesSelected,
    handleHeaderCheckBoxClick,
    handleRowCheckBoxClick,
    resetSelectedValues,
  } = useTableCheckBoxHook<ContainerIssueForTable>(containerIssues, currentPage)

  function getCheckboxHeader() {
    const numSelected = selectedContainerIssues.length

    return (
      <Grid container={true} alignItems={"center"} justifyContent={"center"}>
        <Grid item={true}>
          <Checkbox
            indeterminate={numSelected > 0 && !allEntriesSelected}
            checked={allEntriesSelected}
            onChange={() => handleHeaderCheckBoxClick()}
            disableRipple={true}
            className={classes.headerCheckbox}
          />
        </Grid>
      </Grid>
    )
  }

  const columns: Column[] = [
    {
      Header: getCheckboxHeader(),
      width: 50,
      Cell: ({ original }: { original: ContainerIssueForTable }) => {
        const isRowSelected = !!selectedContainerIssues.find(
          (selectedContainerIssue) => selectedContainerIssue.id === original.id
        )

        return (
          <Grid container={true} alignItems={"center"} justifyContent={"center"}>
            <Grid item={true}>
              <Checkbox
                checked={isRowSelected}
                onChange={() => handleRowCheckBoxClick(original)}
                disableRipple={true}
                className={classes.checkBox}
              />
            </Grid>
          </Grid>
        )
      },
    },
    {
      Header: t("generic.qr_code"),
      accessor: "qr_code",
      width: 100,
    },
    {
      Header: t("generic.error_type"),
      accessor: "error_type",
      width: 300,
      Cell: ({ original }: { original: ContainerIssueForTable }) =>
        renderTooltippedSpan(t(`container_error_model.error_types.${original.error_type}`)),
    },
    {
      Header: t("generic.error_message"),
      accessor: "errorMessage",
    },
    {
      Header: t("generic.container_type"),
      accessor: "containerTypeName",
      width: 300,
    },
    {
      Header: t("generic.location"),
      accessor: "locationName",
      width: 200,
    },
    {
      Header: t("generic.inserted_at"),
      accessor: "errorCreatedAt",
      width: 200,
      Cell: ({ original }: { original: ContainerIssueForTable }) => formatDate(original.errorCreatedAt),
    },
    {
      Header: "",
      sortable: false,
      width: 80,
      Cell: ({ original }: { original: ContainerIssueForTable }) => {
        return (
          <Grid container item direction="row" spacing={1} justifyContent="space-evenly">
            <Grid item>
              <PopoverContainerHistory qrCode={original.qr_code} />
            </Grid>
          </Grid>
        )
      },
    },
  ].map((column) => ({
    Cell: ({ original }: { original: any }) => {
      if (!column.accessor) {
        return null
      }

      return renderTooltippedSpan(original[column.accessor])
    },
    ...column,
    Header: (cellInfo: CellInfo, col: any) => {
      if (typeof column.Header === "string") {
        return renderHeader(cellInfo, col, column.Header)
      }

      return column.Header
    },
  }))

  function renderDeleteButton() {
    return (
      <IconButton
        color="primary"
        onClick={() => setIsDeleteDialogOpen(true)}
        disabled={loading || selectedContainerIssues.length < 1}
      >
        <DeleteForever />
      </IconButton>
    )
  }

  return (
    <>
      <TableEntryCount
        totalNumberOfEntries={totalNumberOfContainerIssues}
        isTableLoading={loading}
        actionButtonElements={renderDeleteButton()}
      />
      <Card>
        <CustomStripedTable
          data={containerIssues}
          columns={columns}
          page={currentPage}
          pages={pages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          loading={loading}
          defaultSorted={[{ id: "errorCreatedAt", desc: true }]}
        />
      </Card>
      <DeleteContainerIssuesDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        elementsToDelete={selectedContainerIssues}
        isDeleteAllElements={allEntriesSelected}
        resetSelectedValues={resetSelectedValues}
      />
    </>
  )
}
