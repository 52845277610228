import { Card, Grid, IconButton, Tooltip, Typography } from "@material-ui/core"
import { Check, Image as ImageIcon } from "@material-ui/icons"
import lodash from "lodash"
import moment from "moment"
import React, { useContext, useState, VFC } from "react"
import { useMutation } from "react-apollo"
import { useTranslation } from "react-i18next"
import { CellInfo, Column } from "react-table"
import "react-table/react-table.css"
import {
  CompleteDamageReport,
  CompleteDamageReportVariables,
  COMPLETE_DAMAGE_REPORT,
} from "../../../../api/graphql/mutations/complete-damage-report"
import { DamageReport } from "../../../../api/graphql/query/damage_reports"
import { formatDate } from "../../../../domain/helpers/formatText"
import { CommonHelper } from "../../../../utils/commonHelpers"
import { YesOrNoConfirmationDialog } from "../../../partials/dialog/yes-or-no-confirmation-dialog"
import { renderHeader, renderTooltippedSpan } from "../../../partials/layout/table/ReactTableHelper"
import { CustomLightbox } from "../../../partials/lightbox/custom-lightbox"
import { CustomStripedTable } from "../../../partials/table/CustomStripedTable"
import { TableEntryCount } from "../../../partials/table/TableEntryCount"
import { PopoverContainerHistory } from "../../container-issue/partials/PopoverContainerHistory"
import { DamageReportsPageContext } from "../DamageReportsPageContext"

export const DamageReportsTable: VFC = (props) => {
  const { t } = useTranslation()

  const {
    damageReports,
    totalDamageReportsCount,
    refetchDamageReports,
    isDamageReportsLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalPages,
  } = useContext(DamageReportsPageContext)

  const [imageUrlsToShow, setImageUrlsToShow] = useState<string[] | undefined>(undefined)
  const [damageReportIdToComplete, setDamageReportIdToComplete] = useState<number | undefined>(undefined)

  const [completeDamageReportMutation] = useMutation<CompleteDamageReport, CompleteDamageReportVariables>(
    COMPLETE_DAMAGE_REPORT,
    {
      onCompleted: () => {
        setDamageReportIdToComplete(undefined)
        refetchDamageReports()
      },
    }
  )

  const getCompletedTooltip = (damageReport: DamageReport) => {
    const isCompleted = damageReport.completed_at != null

    if (!isCompleted) return ""

    const driverInfo =
      damageReport.completed_driver_personal_number && damageReport.completed_driver_name
        ? `${damageReport.completed_driver_personal_number} - ${damageReport.completed_driver_name}`
        : damageReport.completed_driver_name
        ? damageReport.completed_driver_name
        : t("damage_reports.table.completedTooltip.noInfo")

    const driverText = t("damage_reports.table.completedTooltip.completedBy", { by: driverInfo })
    const completedText = t("damage_reports.table.completedTooltip.completedAt", {
      date: formatDate(damageReport.completed_at),
    })

    return (
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography style={{ fontSize: "14px" }}>{completedText}</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: "14px" }}>{driverText}</Typography>
        </Grid>
      </Grid>
    )
  }

  const columns: Column[] = [
    {
      Header: t("damage_reports.table.table_header.container_qr_code"),
      accessor: "container_qr_code",
      width: 150,
    },
    {
      Header: t("damage_reports.table.table_header.reason"),
      Cell: ({ original }: { original: DamageReport }) => {
        let additionalInfoText: string
        if (!original.reason) {
          additionalInfoText = t("damage_reports.table.no_additional_info_text")
        } else {
          additionalInfoText = original.reason
        }

        return renderTooltippedSpan(additionalInfoText)
      },
    },
    {
      Header: t("damage_reports.table.table_header.driver_credentials"),
      Cell: ({ original }: { original: DamageReport }) => {
        const driverCredentials = CommonHelper.buildDriverCredentials(
          original.driver_personal_number,
          original.driver_name
        )

        return renderTooltippedSpan(driverCredentials || "")
      },
      width: 160,
    },
    {
      Header: t("damage_reports.table.table_header.locationName"),
      accessor: "locationName",
      width: 220,
    },
    {
      Header: t("damage_reports.table.table_header.report_mail_sent_at"),
      width: 160,
      Cell: ({ original }: { original: DamageReport }) => {
        return renderTooltippedSpan(formatDate(original.report_mail_sent_at))
      },
      sortMethod: (a: any, b: any) => {
        return moment(b).diff(moment(a))
      },
    },
    {
      Header: t("damage_reports.table.table_header.damage_report_photos"),
      width: 100,
      Cell: ({ original }: { original: DamageReport }) => {
        return (
          <IconButton
            onClick={(_event) => {
              if (lodash.isNil(original.damage_report_photos)) {
                return
              }

              setImageUrlsToShow(original.damage_report_photos.map((damageReportPhoto) => damageReportPhoto.image_url))
            }}
            color="primary"
            disabled={lodash.isNil(original.damage_report_photos) || lodash.isEmpty(original.damage_report_photos)}
          >
            <ImageIcon />
          </IconButton>
        )
      },
    },
    {
      Header: t("damage_reports.table.table_header.inserted_at"),
      accessor: "inserted_at",
      width: 160,
      Cell: ({ original }: { original: DamageReport }) => {
        return renderTooltippedSpan(formatDate(original.inserted_at))
      },
      sortMethod: (a: any, b: any) => {
        return moment(b).diff(moment(a))
      },
    },
    {
      Header: "",
      width: 80,
      Cell: ({ original }: { original: DamageReport }) => {
        const isCompleted = original.completed_at != null

        return (
          <Grid container item direction="row" spacing={1} justifyContent="space-evenly">
            <Tooltip title={getCompletedTooltip(original)} placement={"top"}>
              <Grid item>
                <IconButton
                  color="primary"
                  disabled={isCompleted}
                  onClick={() => setDamageReportIdToComplete(original.id)}
                  size="small"
                >
                  <Check />
                </IconButton>
              </Grid>
            </Tooltip>
            <Grid item>
              <PopoverContainerHistory qrCode={original.container_qr_code} />
            </Grid>
          </Grid>
        )
      },
    },
  ].map((column) => ({
    Cell: ({ original }: { original: any }) => {
      if (!column.accessor) {
        return null
      }

      return renderTooltippedSpan(original[column.accessor])
    },
    ...column,
    Header: (cellInfo: CellInfo, col: any) => renderHeader(cellInfo, col, column.Header),
  }))

  return (
    <>
      <TableEntryCount totalNumberOfEntries={totalDamageReportsCount} isTableLoading={isDamageReportsLoading} />
      <Card>
        <CustomStripedTable
          data={damageReports}
          columns={columns}
          page={currentPage}
          pages={totalPages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          loading={isDamageReportsLoading}
          defaultSorted={[{ id: "inserted_at", desc: true }]}
        />
        {!!imageUrlsToShow && (
          <CustomLightbox imageUrls={imageUrlsToShow} onClose={() => setImageUrlsToShow(undefined)} />
        )}
        <YesOrNoConfirmationDialog
          open={!!damageReportIdToComplete}
          onClose={() => setDamageReportIdToComplete(undefined)}
          onConfirm={() =>
            completeDamageReportMutation({ variables: { damageReportId: damageReportIdToComplete! } })
          }
          content={t("damage_reports.table.dialog.complete_damage_report")}
        />
      </Card>
    </>
  )
}
