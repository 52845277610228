import { Grid } from "@material-ui/core"
import { VFC } from "react"
import { useTranslation } from "react-i18next"
import { CellInfo, Column } from "react-table"
import { InvalidInventoryContainer } from "../../../../api/graphql/query/container_inventory_invalid_containers"
import { ValidInventoryContainer } from "../../../../api/graphql/query/container_inventory_valid_containers"
import { ContentContainer } from "../../../partials/layout/content/ContentContainer"
import { renderHeader, renderTooltippedSpan } from "../../../partials/layout/table/ReactTableHelper"
import { CustomStripedTable } from "../../../partials/table/CustomStripedTable"
import { TableEntryCount } from "../../../partials/table/TableEntryCount"
import { PopoverContainerHistory } from "../../container-issue/partials/PopoverContainerHistory"
import { IPaginationInput } from "../ContainerInventoryProvider"
import moment from "moment"

type InventoryContainer = ValidInventoryContainer | InvalidInventoryContainer

interface IContainerInventoryTableProps {
  containerPagination: IPaginationInput
  setContainerPagination: (pagination: IPaginationInput) => void
  containerTotalPages: number
  containerTotalEntries: number
  areContainersLoading: boolean
  containers: InventoryContainer[]
  displayLastLocation: boolean
}

export const ContainersInventoryTable: VFC<IContainerInventoryTableProps> = (props) => {
  const {
    containerPagination,
    setContainerPagination,
    containerTotalPages,
    areContainersLoading,
    containers,
    containerTotalEntries,
    displayLastLocation,
  } = props
  const { t } = useTranslation()

  const setCurrentPage = (currentPage: number) => {
    setContainerPagination({ ...containerPagination, currentPage })
  }

  const setPageSize = (pageSize: number) => {
    setContainerPagination({ ...containerPagination, pageSize })
  }

  const renderLastLocation = (original: InventoryContainer) => {
    return renderTooltippedSpan(`${moment(original.latestUpdate).format("lll")} - ${original.latestLocation}`)
  }

  const columns: Column[] = [
    {
      Header: t("container_inventory.table_header.qr_code"),
      accessor: "qr_code",
    },
    {
      Header: t("container_inventory.table_header.container_type"),
      accessor: "typeName",
    },
    {
      Header: t("container_inventory.table_header.varnishing"),
      accessor: "varnishing",
    },
    {
      Header: t("container_inventory.table_header.construction_year"),
      accessor: "construction_year",
    },
    {
      Header: t("container_inventory.table_header.address"),
      accessor: "address",
    },
    {
      Header: t("container_inventory.table_header.gps"),
      Cell: ({ original }: { original: InventoryContainer }) =>
        renderTooltippedSpan(`${original.latitude ?? "-"} | ${original.longitude ?? "-"}`),
    },
    {
      Header: t("container_inventory.table_header.last_location"),
      show: displayLastLocation,
      Cell: ({ original }: { original: InventoryContainer }) => {
        return original.latestLocation && original.latestUpdate ? renderLastLocation(original) : ""
      },
    },
    {
      Header: "",
      sortable: false,
      width: 50,
      Cell: ({ original }: { original: ValidInventoryContainer | InvalidInventoryContainer }) => {
        return (
          <Grid container item justifyContent="center">
            <Grid item>
              <PopoverContainerHistory qrCode={original.qr_code} />
            </Grid>
          </Grid>
        )
      },
    },
  ].map((column) => ({
    Cell: ({ original }: { original: any }) => {
      if (!column.accessor) {
        return null
      }

      return renderTooltippedSpan(original[column.accessor])
    },
    ...column,
    Header: (cellInfo: CellInfo, col: any) => {
      if (typeof column.Header === "string") {
        return renderHeader(cellInfo, col, column.Header)
      }

      return column.Header
    },
  }))

  return (
    <ContentContainer>
      <TableEntryCount totalNumberOfEntries={containerTotalEntries} isTableLoading={areContainersLoading} />
      <CustomStripedTable
        data={containers}
        columns={columns}
        page={containerPagination.currentPage}
        pages={containerTotalPages}
        onPageChange={setCurrentPage}
        pageSize={containerPagination.pageSize}
        onPageSizeChange={setPageSize}
        loading={areContainersLoading}
      />
    </ContentContainer>
  )
}
