import { Grid, makeStyles, MenuItem, Select, TextField, Theme } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "react-table/react-table.css"
import { ContainerFillState } from "../../../api/graphql/graphql-global-types"
import { YesOrNoConfirmationDialog } from "../dialog/yes-or-no-confirmation-dialog"

const useStyles = makeStyles((theme: Theme) => ({
  spacingTop: {
    marginTop: 20,
  },
  errorText: {
    color: "red",
  },
}))

export interface ISetStatusAndMaterialDialogProps {
  open: boolean
  onClose: () => void
  onSetStatusAndMaterial: (fillState: ContainerFillState, material?: string) => void
}

export const SetStatusAndMaterialDialog: React.FunctionComponent<ISetStatusAndMaterialDialogProps> = (props) => {
  const { open, onClose, onSetStatusAndMaterial } = props

  const classes = useStyles()
  const { t } = useTranslation()

  const [fillState, setFillState] = useState<ContainerFillState>(ContainerFillState.EMPTY)
  const [material, setMaterial] = useState<string | undefined>(undefined)

  const containerFillStateOptions = Array.of(
    ContainerFillState.EMPTY,
    ContainerFillState.FILLING,
    ContainerFillState.FULL_LOGISTIC,
    ContainerFillState.FULL
  )

  function isConfirmButtonDisabled() {
    return fillState !== ContainerFillState.EMPTY && !material?.trim()
  }

  function onConfirm() {
    onSetStatusAndMaterial(fillState!, material || undefined)
    resetValues()
  }

  function onSetFillState(fillState: ContainerFillState) {
    if (fillState === ContainerFillState.EMPTY) {
      setMaterial(undefined)
    }
    setFillState(fillState)
  }

  function resetValues() {
    setFillState(ContainerFillState.EMPTY)
    setMaterial(undefined)
  }

  function resetAndCloseDialog() {
    resetValues()
    onClose()
  }

  return (
    <YesOrNoConfirmationDialog
      open={open}
      onClose={resetAndCloseDialog}
      onConfirm={onConfirm}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      content={
        <Grid container direction="column">
          <Grid item xs={12}>
            {t("dialog.setStatusAndMaterial")}
          </Grid>
          <Grid item xs={12} className={classes.spacingTop}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item xs={3}>
                    {t("dialog.material")}:
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      style={{ width: "100%" }}
                      disabled={fillState === ContainerFillState.EMPTY}
                      value={material}
                      onChange={(event: any) => {
                        setMaterial(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item xs={3}>
                    {t("dialog.status")}:
                  </Grid>
                  <Grid item xs={9}>
                    <Select
                      style={{ width: "100%" }}
                      required
                      value={fillState}
                      onChange={(event: any) => {
                        onSetFillState(event.target.value as ContainerFillState)
                      }}
                    >
                      {containerFillStateOptions.map((containerFillState) => (
                        <MenuItem key={containerFillState} value={containerFillState}>
                          {t(`container_model.container_fill_state.${containerFillState}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
