import { Card, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { VehicleDamageReportsPageContext } from "../VehicleDamageReportsPageContext";
import { CellInfo, Column } from "react-table";
import { VehicleDamageReport } from "../../../../api/graphql/query/vehicle_damage_reports";
import { renderHeader, renderTooltippedSpan } from "../../../partials/layout/table/ReactTableHelper";
import { TableEntryCount } from "../../../partials/table/TableEntryCount";
import { CustomStripedTable } from "../../../partials/table/CustomStripedTable";
import { formatDate } from "../../../../domain/helpers/formatText";
import {
  VehicleDamageReportStatus,
} from "../../../partials/custom-select-components/vehicle-damage-report-status-autocomplete";
import { CommonHelper } from "../../../../utils/commonHelpers";
import { Check, InfoOutlined, Image as ImageIcon } from "@material-ui/icons";
import { CompleteVehicleDamageReportModal } from "../CompleteVehicleDamageReportModal";
import moment from "moment";
import lodash from "lodash";
import { CustomLightbox } from "../../../partials/lightbox/custom-lightbox";

interface IVehicleDamageReportsTableProps {
}

export const VehicleDamageReportsTable: React.FC<IVehicleDamageReportsTableProps> = (props) => {
  const { t } = useTranslation();
  const [reportToCompleteId, setReportToCompleteId] = useState<number | undefined>();
  const {
    damageReports,
    refetchDamageReports,
    totalDamageReportsCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalPages,
    isDamageReportsLoading,
  } = useContext(VehicleDamageReportsPageContext);

  const [imageUrlsToShow, setImageUrlsToShow] = useState<string[] | undefined>(undefined);

  const getCompletedTooltip = (damageReport: VehicleDamageReport) => {
    const isCompleted = damageReport.completed_at != null;

    if (!isCompleted) return "";

    return (
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography style={{ fontSize: "14px" }}>
            {t("vehicle_damage_reports.completed_at", {
              date: moment(damageReport.completed_at).format("DD.MM.YYYY HH:mm"),
            })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: "14px" }}>
            {t("vehicle_damage_reports.completed_name", { name: damageReport.completed_name })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: "14px" }}>
            {t("vehicle_damage_reports.comment", { comment: damageReport.completed_info_text })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: "14px" }}>
            {t("vehicle_damage_reports.fixed_intern",
              { fixed_intern: damageReport.fixed_intern ? t("vehicle_damage_reports.yes") : t("vehicle_damage_reports.no") }
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const handleCompleteVehicleDamageReportFinished = () => {
    setReportToCompleteId(undefined);
    refetchDamageReports();
  };

  const columns: Column[] = [
    {
      Header: t("vehicle_damage_reports.table.table_header.vehicle"),
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        const vehicleCredentials = CommonHelper.buildVehicleCredentials(
          original.vehicle_number,
          original.vehicle_registration_number,
        );

        return renderTooltippedSpan(vehicleCredentials || "");
      },
      width: 120,
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.is_total_loss"),
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        return original.is_total_loss ? <span style={{ textAlign: "center", width: "100%" }}>!</span> : "";
      },
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.reason"),
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        return original.reason;
      },
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.comment"),
      width: 160,
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        return original.info_text;
      },
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.driver"),
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        const driverCredentials = CommonHelper.buildDriverCredentials(
          original.driver_personal_number,
          original.driver_name,
        );

        return renderTooltippedSpan(driverCredentials || "");
      },
      width: 160,
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.locationName"),
      accessor: "locationName",
      width: 220,
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.report_mail_sent_at"),
      width: 160,
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        return renderTooltippedSpan(formatDate(original.report_mail_sent_at));
      },
      sortMethod: (a: any, b: any) => {
        return moment(b).diff(moment(a));
      },
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.damage_report_photos"),
      width: 100,
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        return (
          <IconButton
            onClick={(_event) => {
              if (lodash.isNil(original.damage_report_photos)) {
                return;
              }

              setImageUrlsToShow(original.damage_report_photos.map((damageReportPhoto) => damageReportPhoto.image_url));
            }}
            color="primary"
            disabled={lodash.isNil(original.damage_report_photos) || lodash.isEmpty(original.damage_report_photos)}
          >
            <ImageIcon />
          </IconButton>
        );
      },
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.status"),
      width: 100,
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        return original.completed_at
          ? t(`vehicle_damage_reports.filter.status.${VehicleDamageReportStatus.Completed}`)
          : t(`vehicle_damage_reports.filter.status.${VehicleDamageReportStatus.Open}`);
      },
    },
    {
      Header: t("vehicle_damage_reports.table.table_header.equipment_number"),
      width: 130,
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        return original.equipment_number;
      },
    },
    {
      Header: "",
      width: 80,
      Cell: ({ original }: { original: VehicleDamageReport }) => {
        const isCompleted = original.completed_at != null;

        return (
          <Grid container item direction="row" spacing={1} justifyContent="space-evenly">
            {!isCompleted && (
              <Grid item>
                <IconButton
                  color="primary"
                  disabled={isCompleted}
                  onClick={() => setReportToCompleteId(original.id)}
                  size="small"
                >
                  <Check color="primary" />
                </IconButton>
              </Grid>
            )}
            {isCompleted && (
              <Grid item>
                <Tooltip title={getCompletedTooltip(original)} placement={"top"}>
                  <InfoOutlined />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        );
      },
    },
  ].map((column) => ({
    Cell: ({ original }: { original: VehicleDamageReport }) => {
      if (!column.accessor) return null;

      return renderTooltippedSpan(original[column.accessor as keyof VehicleDamageReport]);
    },
    ...column,
    Header: (cellInfo: CellInfo, col: any) => renderHeader(cellInfo, col, column.Header),
  }));

  return (
    <>
      {reportToCompleteId && (
        <CompleteVehicleDamageReportModal
          damageReportId={reportToCompleteId}
          open={reportToCompleteId !== undefined}
          handleClose={() => handleCompleteVehicleDamageReportFinished()}
        />
      )}
      <TableEntryCount totalNumberOfEntries={totalDamageReportsCount} isTableLoading={isDamageReportsLoading} />
      <Card>
        <CustomStripedTable
          data={damageReports}
          columns={columns}
          page={currentPage}
          pages={totalPages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          loading={isDamageReportsLoading}
          defaultSorted={[{ id: "created_at", desc: true }]}
        />
        {!!imageUrlsToShow && (
          <CustomLightbox imageUrls={imageUrlsToShow} onClose={() => setImageUrlsToShow(undefined)} />
        )}
      </Card>
    </>
  );
};
