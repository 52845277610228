import { gql } from "apollo-boost"

export const OVERALL_PRODUCTIVITY_QUERY = gql`
  query overallProductivity(
    $from: String!
    $to: String!
    $locationIds: [ID!]!
    $containerTypeIds: [ID!]!
    $partnerIds: [ID!]!
  ) {
    overallProductivity(
      from: $from
      to: $to
      locationIds: $locationIds
      containerTypeIds: $containerTypeIds
      partnerIds: $partnerIds
    ) {
      clientName
      clientPartnerNames
      startDate
      endDate
      rangeInDays
      productiveDaysAtClient
      unproductiveDaysAtPartner
      notProductiveDays
      containerCount
    }
  }
`
