import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-apollo"
import {
  CONTAINER_INVENTORY_INVALID_CONTAINERS_QUERY,
  InvalidInventoryContainer,
  InvalidInventoryContainersResult,
  InvalidInventoryContainersVariables,
} from "../../../api/graphql/query/container_inventory_invalid_containers"
import {
  CONTAINER_INVENTORY_VALID_CONTAINERS_QUERY,
  ValidInventoryContainer,
  ValidInventoryContainersResult,
  ValidInventoryContainersVariables,
} from "../../../api/graphql/query/container_inventory_valid_containers"
import {
  ContainerLocation,
  ContainerLocationsResult,
  CONTAINER_LOCATIONS_QUERY,
} from "../../../api/graphql/query/container_locations"
import { SelectOption } from "../../partials/custom-select-components/custom-select-component-commons"

interface IContainerInventoryContextType {
  selectedDate: Date
  setSelectedDate: (date: Date) => void

  selectedLocation: SelectOption | null
  setSelectedLocation: (option: SelectOption | null) => void

  correctContainerPagination: IPaginationInput
  setCorrectContainerPagination: (pagination: IPaginationInput) => void
  correctContainerTotalPages: number
  correctContainerTotalEntries: number

  errorContainerPagination: IPaginationInput
  setErrorContainerPagination: (pagination: IPaginationInput) => void
  errorContainerTotalPages: number
  errorContainerTotalEntries: number

  containerLocations: ContainerLocation[]
  correctContainers: ValidInventoryContainer[]
  areCorrectContainersLoading: boolean
  errorContainers: InvalidInventoryContainer[]
  areErrorContainersLoading: boolean

  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

const ContainerInventoryContext = createContext<IContainerInventoryContextType>({} as IContainerInventoryContextType)

export const ContainerInventoryProvider = (props: any) => {
  const value = useContainerInventoryProvider()
  return <ContainerInventoryContext.Provider value={value}>{props.children}</ContainerInventoryContext.Provider>
}

export const useContainerInventoryContext = () => {
  return useContext(ContainerInventoryContext)
}

export interface IPaginationInput {
  currentPage: number
  pageSize: number
}

const emptyPagination: IPaginationInput = {
  currentPage: 0,
  pageSize: 10,
}

const useContainerInventoryProvider = (): IContainerInventoryContextType => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedLocation, setSelectedLocation] = useState<SelectOption | null>(null)
  const [correctContainerPagination, setCorrectContainerPagination] = useState<IPaginationInput>(emptyPagination)
  const [errorContainerPagination, setErrorContainerPagination] = useState<IPaginationInput>(emptyPagination)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setCorrectContainerPagination(emptyPagination)
    setErrorContainerPagination(emptyPagination)
  }, [selectedDate, selectedLocation])

  //#region Queries
  const { data: containerLocationsData } = useQuery<ContainerLocationsResult>(CONTAINER_LOCATIONS_QUERY, {
    errorPolicy: "all",
  })

  const { data: validInventoryContainerData, loading: areCorrectContainersLoading } = useQuery<
    ValidInventoryContainersResult,
    ValidInventoryContainersVariables
  >(CONTAINER_INVENTORY_VALID_CONTAINERS_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    skip: !selectedDate || !selectedLocation,
    variables: {
      date: selectedDate ?? new Date(),
      page: correctContainerPagination.currentPage,
      pageSize: correctContainerPagination.pageSize,
      locationId: selectedLocation?.id ?? "-1",
    },
  })

  const { data: invalidInventoryContainerData, loading: areErrorContainersLoading } = useQuery<
    InvalidInventoryContainersResult,
    InvalidInventoryContainersVariables
  >(CONTAINER_INVENTORY_INVALID_CONTAINERS_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    skip: !selectedDate || !selectedLocation,
    variables: {
      date: selectedDate ?? new Date(),
      page: errorContainerPagination.currentPage,
      pageSize: errorContainerPagination.pageSize,
      locationId: selectedLocation?.id ?? "-1",
    },
  })
  //#endregion

  const containerLocations = useMemo(() => containerLocationsData?.containerLocations ?? [], [containerLocationsData])

  const correctContainers = validInventoryContainerData?.validInventoryContainers.entries ?? []
  const correctContainerTotalPages = validInventoryContainerData?.validInventoryContainers.totalPages ?? 1
  const correctContainerTotalEntries = validInventoryContainerData?.validInventoryContainers.totalEntries ?? 0

  const errorContainers = invalidInventoryContainerData?.invalidInventoryContainers.entries ?? []
  const errorContainerTotalPages = invalidInventoryContainerData?.invalidInventoryContainers.totalPages ?? 1
  const errorContainerTotalEntries = invalidInventoryContainerData?.invalidInventoryContainers.totalEntries ?? 0

  return {
    selectedDate,
    setSelectedDate,
    selectedLocation,
    setSelectedLocation,
    correctContainerPagination,
    setCorrectContainerPagination,
    correctContainerTotalPages,
    errorContainerPagination,
    setErrorContainerPagination,
    errorContainerTotalPages,
    containerLocations,
    correctContainers,
    errorContainers,
    areCorrectContainersLoading,
    areErrorContainersLoading,
    correctContainerTotalEntries,
    errorContainerTotalEntries,
    setIsLoading,
    isLoading,
  }
}
