import { makeStyles, TextField, Theme } from "@material-ui/core"
import { DatePicker, PickersDayProps } from "@mui/x-date-pickers"
import moment from "moment"
import { VFC } from "react"

const useStyles = makeStyles((theme: Theme) => ({
  selectedDate: {
    "& .MuiPickersDay-root": {
      "&.Mui-selected": {
        backgroundColor: "#C20E1A !important",
        "&:hover": {
          backgroundColor: "#C20E1A",
        },
      },
    },
    "& .MuiPickersYear-yearButton": {
      "&.Mui-selected": {
        backgroundColor: "#C20E1A",
        "&:hover": {
          backgroundColor: "#C20E1A",
        },
      },
    },
    "& .MuiPickersMonth-monthButton": {
      "&.Mui-selected": {
        backgroundColor: "#C20E1A",
        "&:hover": {
          backgroundColor: "#C20E1A",
        },
      },
    },
  },
}))

interface ICustomDatePickerProps {
  label: string
  fullWidth?: boolean
  format?: string
  value: Date
  onChange: (date: moment.Moment | null) => void
  disableFuture?: boolean
  disablePast?: boolean
  customDaySlot?: React.ComponentType<PickersDayProps<moment.Moment>>
}

export const CustomDatePicker: VFC<ICustomDatePickerProps> = (props) => {
  const { label, fullWidth, format, value, onChange, disableFuture, disablePast, customDaySlot } = props
  const classes = useStyles()

  return (
    <DatePicker
      label={label}
      slotProps={{
        textField: { fullWidth },
        desktopPaper: {
          className: classes.selectedDate,
        },
        mobilePaper: {
          className: classes.selectedDate,
        },
      }}
      slots={{ textField: TextField as any, day: customDaySlot }}
      format={format}
      value={moment(value)}
      onChange={onChange}
      disableFuture={disableFuture}
      disablePast={disablePast}
    />
  )
}
