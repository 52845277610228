import { gql } from "apollo-boost"
import {
  vehicleDamageReports,
  vehicleDamageReportsVariables,
  vehicleDamageReports_vehicleDamageReports_entries,
} from "./types/vehicleDamageReports"

export const VEHICLE_DAMAGE_REPORTS_QUERY = gql`
  query vehicleDamageReports(
    $page: Int!
    $pageSize: Int!
    $locationIds: [ID!]!
    $damageReportStatus: String
    $vehicleNumber: String
  ) {
    vehicleDamageReports(
      page: $page
      pageSize: $pageSize
      locationIds: $locationIds
      damageReportStatus: $damageReportStatus
      vehicleNumber: $vehicleNumber
    ) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        id
        vehicle_number
        vehicle_registration_number
        is_total_loss
        locationName
        driver_personal_number
        driver_name
        info_text
        reason
        report_mail_sent_at
        equipment_number
        created_at
        completed_name
        completed_info_text
        completed_at
        fixed_intern
        damage_report_photos {
          image_url
        }
      }
    }
  }
`

export type VehicleDamageReportsResult = vehicleDamageReports
export type VehicleDamageReportsVariables = vehicleDamageReportsVariables
export type VehicleDamageReport = vehicleDamageReports_vehicleDamageReports_entries
