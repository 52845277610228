import React from "react"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { Grid } from "@mui/material"
import { VehicleDamageReportsPageContextProvider } from "./VehicleDamageReportsPageContext"
import { VehicleDamageReportsTable } from "./vehicle-damage-report-table/VehicleDamageReportsTable"
import { VehicleDamageReportsFilter } from "./vehicle-damage-report-filter/VehicleDamageReportsFilter"

interface IVehicleDamageReportsPageProps {}

export const VehicleDamageReportsPage: React.FC<IVehicleDamageReportsPageProps> = (props) => {
  const { t } = useTranslation()

  return (
    <VehicleDamageReportsPageContextProvider>
      <PageHeading title={t("vehicle_damage_reports.heading")} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VehicleDamageReportsFilter />
        </Grid>
        <Grid item xs={12}>
          <VehicleDamageReportsTable />
        </Grid>
      </Grid>
    </VehicleDamageReportsPageContextProvider>
  )
}
