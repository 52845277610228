import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import { VFC } from "react"

const useStyles = makeStyles((theme: Theme) => ({
  spacing: {
    padding: "20px 20px 20px 0px",
  },
}))

interface IContainerInventoryEmptyProps {
  text: string
}

export const ContainerInventoryEmpty: VFC<IContainerInventoryEmptyProps> = (props) => {
  const { text } = props
  const classes = useStyles()

  return (
    <Grid container direction="row" className={classes.spacing}>
      <Grid item>
        <Typography variant="body1">{text}</Typography>
      </Grid>
    </Grid>
  )
}
