import React, { createContext, FunctionComponent, PropsWithChildren, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { useDebounce } from "usehooks-ts"
import { ContainerErrorType } from "../../../../api/graphql/graphql-global-types"
import {
  ContainerLocation,
  ContainerLocationsResult,
  CONTAINER_LOCATIONS_QUERY,
} from "../../../../api/graphql/query/container_locations"
import {
  ContainerType,
  ContainerTypesResult,
  CONTAINER_TYPES_QUERY,
} from "../../../../api/graphql/query/container_types"
import {
  ContainerIssueForMap,
  ContainerIssuesForMapResult,
  ContainerIssuesForMapVariables,
  CONTAINER_ISSUES_FOR_MAP_QUERY,
} from "../../../../api/graphql/query/get-container-issues-for-map"
import {
  ContainerIssueForTable,
  ContainerIssuesForTableResult,
  ContainerIssuesForTableVariables,
  CONTAINER_ISSUES_FOR_TABLE_QUERY,
} from "../../../../api/graphql/query/get-container-issues-for-table"
import { SelectOption } from "../../../partials/custom-select-components/custom-select-component-commons"

export interface IContainerIssuePageContext {
  containerIssues: ContainerIssueForTable[]
  totalContainerIssueCount: number
  currentPage: number
  setCurrentPage: (currentPage: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  totalPages: number
  containerIssuesLoading: boolean
  containerIssuesError: boolean
  refetchContainerIssues: () => void

  containerIssuesForMap: ContainerIssueForMap[]
  containerIssuesForMapLoading: boolean
  containerIssuesForMapError: boolean
  refetchContainerIssuesForMap: () => void

  qrCodeFilter: string
  setQrCodeFilter: (qrCodeFilter: string) => void

  connectionReferenceFilterText: string
  setConnectionReferenceFilterText: (text: string) => void

  containerErrorTypeFilter: ContainerErrorType | undefined
  setContainerErrorTypeFilter: (containerErrorTypeFilter: ContainerErrorType | undefined) => void

  containerLocations: ContainerLocation[]
  selectedContainerLocations: SelectOption[]
  setSelectedContainerLocations: (locations: SelectOption[]) => void

  containerTypes: ContainerType[]
  selectedContainerTypes: SelectOption[]
  setSelectedContainerTypes: (types: SelectOption[]) => void
}
export const ContainerIssuePageContext = createContext<IContainerIssuePageContext>({
  containerIssues: [],
  totalContainerIssueCount: 0,
  currentPage: 0,
  setCurrentPage: (_) => {},
  pageSize: 20,
  setPageSize: (_) => {},
  totalPages: 1,
  containerIssuesLoading: false,
  containerIssuesError: false,
  refetchContainerIssues: () => {},

  containerIssuesForMap: [],
  containerIssuesForMapLoading: false,
  containerIssuesForMapError: false,
  refetchContainerIssuesForMap: () => {},

  qrCodeFilter: "",
  setQrCodeFilter: () => {},

  connectionReferenceFilterText: "",
  setConnectionReferenceFilterText: (_) => {},

  containerErrorTypeFilter: undefined,
  setContainerErrorTypeFilter: () => {},

  containerLocations: [],
  selectedContainerLocations: [],
  setSelectedContainerLocations: (_) => {},

  containerTypes: [],
  selectedContainerTypes: [],
  setSelectedContainerTypes: (_) => {},
})

interface IContainerIssuePageContextProviderProps {}

export const ContainerIssuePageContextProvider: FunctionComponent<PropsWithChildren<IContainerIssuePageContextProviderProps>> = (
  props
) => {
  const { children } = props

  const [containerIssues, setContainerIssues] = useState<ContainerIssueForTable[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)

  const [containerIssuesForMap, setContainerIssuesForMap] = useState<ContainerIssueForMap[]>([])

  const [qrCodeFilter, setQrCodeFilter] = useState<string>("")
  const debouncedQrCodeFilter = useDebounce(qrCodeFilter, 500)

  const [connectionReferenceFilterText, setConnectionReferenceFilterText] = useState<string>("")
  const debouncedConnectionReferenceFilterText = useDebounce(connectionReferenceFilterText, 500)

  const [containerErrorTypeFilter, setContainerErrorTypeFilter] = useState<ContainerErrorType | undefined>(undefined)

  const [selectedContainerLocations, setSelectedContainerLocations] = useState<SelectOption[]>([])
  const [selectedContainerTypes, setSelectedContainerTypes] = useState<SelectOption[]>([])

  const {
    data: containerIssuesData,
    loading: containerIssuesLoading,
    error: containerIssuesError,
    refetch: refetchContainerIssues,
  } = useQuery<ContainerIssuesForTableResult, ContainerIssuesForTableVariables>(CONTAINER_ISSUES_FOR_TABLE_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    variables: {
      page: currentPage,
      pageSize: pageSize,
      qrCodeFilter: debouncedQrCodeFilter || null,
      containerErrorTypeFilter: containerErrorTypeFilter || null,
      locationIds: selectedContainerLocations.map((selectedContainerLocation) => selectedContainerLocation.id),
      containerTypeIds: selectedContainerTypes.map((selectedContainerType) => selectedContainerType.id),
      connectionReferenceFilterText: debouncedConnectionReferenceFilterText || null,
    },
    onCompleted: () => setContainerIssues(containerIssuesData?.containerIssuesForTable.entries || []),
  })

  const {
    data: containerIssuesForMapData,
    loading: containerIssuesForMapLoading,
    error: containerIssuesForMapError,
    refetch: refetchContainerIssuesForMap,
  } = useQuery<ContainerIssuesForMapResult, ContainerIssuesForMapVariables>(CONTAINER_ISSUES_FOR_MAP_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    variables: {
      qrCodeFilter: debouncedQrCodeFilter || null,
      containerErrorTypeFilter: containerErrorTypeFilter || null,
      locationIds: selectedContainerLocations.map((selectedContainerLocation) => selectedContainerLocation.id),
      containerTypeIds: selectedContainerTypes.map((selectedContainerType) => selectedContainerType.id),
      connectionReferenceFilterText: debouncedConnectionReferenceFilterText || null,
    },
    onCompleted: () => setContainerIssuesForMap(containerIssuesForMapData?.containerIssuesForMap || []),
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [
    qrCodeFilter,
    containerErrorTypeFilter,
    selectedContainerLocations,
    selectedContainerTypes,
    connectionReferenceFilterText,
  ])

  const { data: containerLocationsData } = useQuery<ContainerLocationsResult>(CONTAINER_LOCATIONS_QUERY, {
    errorPolicy: "all",
  })

  const { data: containerTypesData } = useQuery<ContainerTypesResult>(CONTAINER_TYPES_QUERY, {
    errorPolicy: "all",
  })

  const containerLocations = containerLocationsData?.containerLocations || []
  const containerTypes = containerTypesData?.containerTypes || []

  return (
    <ContainerIssuePageContext.Provider
      value={{
        containerIssues,
        totalContainerIssueCount: containerIssuesData?.containerIssuesForTable.totalEntries || 0,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        totalPages: containerIssuesData?.containerIssuesForTable.totalPages || 1,
        containerIssuesLoading,
        containerIssuesError: !!containerIssuesError,
        refetchContainerIssues,
        containerIssuesForMap,
        containerIssuesForMapLoading,
        containerIssuesForMapError: !!containerIssuesForMapError,
        refetchContainerIssuesForMap,
        qrCodeFilter,
        setQrCodeFilter,
        containerErrorTypeFilter,
        setContainerErrorTypeFilter,
        containerLocations,
        selectedContainerLocations,
        setSelectedContainerLocations,
        containerTypes,
        selectedContainerTypes,
        setSelectedContainerTypes,
        connectionReferenceFilterText,
        setConnectionReferenceFilterText,
      }}
    >
      {children}
    </ContainerIssuePageContext.Provider>
  )
}
