import moment, { Moment } from "moment"
import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useState } from "react"
import { useQuery } from "react-apollo"
import { OVERALL_PRODUCTIVITY_QUERY } from "../../../api/graphql/query/overall_productivity"
import {
  overallProductivity,
  overallProductivityVariables,
  overallProductivity_overallProductivity,
} from "../../../api/graphql/query/types/OverallProductivity"
import { GlobalFilterContext } from "../../../context/GlobalFilterContext"
import { ITimeRange } from "../../../utils/ITimeRange"

export interface IDashboardFilterContext {
  overallProductivity: overallProductivity_overallProductivity | undefined
  timeRange: ITimeRange
  setTimeRange: (timeRange: ITimeRange) => void
  contextLoading: boolean
  contextError: Error | undefined
}

export const DashboardFilterContext = createContext<IDashboardFilterContext>({
  overallProductivity: undefined,
  timeRange: { fromDate: moment(), toDate: moment() },
  setTimeRange: (_) => {},
  contextLoading: true,
  contextError: undefined,
})

interface IDashboardFilterContextProviderProps {}

export const DashboardFilterContextProvider: FunctionComponent<PropsWithChildren<IDashboardFilterContextProviderProps>> = (props) => {
  const { children } = props

  const [fromDate, setFromDate] = useState<Moment>(moment())
  const [toDate, setToDate] = useState<Moment>(moment())
  const { selectedContainerLocations, selectedContainerTypes, selectedPartners } = useContext(GlobalFilterContext)

  const {
    data: overallProductivityData,
    loading: overallProductivityLoading,
    error: overallProductivityError,
  } = useQuery<overallProductivity, overallProductivityVariables>(OVERALL_PRODUCTIVITY_QUERY, {
    variables: {
      from: fromDate.clone().startOf("day").toISOString(),
      to: toDate.clone().endOf("day").toISOString(),
      locationIds: selectedContainerLocations.map((selectedContainerLocation) => selectedContainerLocation.id),
      containerTypeIds: selectedContainerTypes.map((selectedContainerType) => selectedContainerType.id),
      partnerIds: selectedPartners.map((partner) => partner.id),
    },
    errorPolicy: "all",
  })

  const overallProductivity = overallProductivityData?.overallProductivity
  const contextLoading = overallProductivityLoading
  const contextError = overallProductivityError

  const setTimeRange = (timeRange: ITimeRange) => {
    setFromDate(timeRange.fromDate)
    setToDate(timeRange.toDate)
  }

  return (
    <DashboardFilterContext.Provider
      value={{
        overallProductivity,
        timeRange: { fromDate, toDate },
        setTimeRange,
        contextLoading,
        contextError,
      }}
    >
      {children}
    </DashboardFilterContext.Provider>
  )
}
