import { gql } from "apollo-boost"
import {
  setFillStateAndMaterial,
  setFillStateAndMaterialVariables,
  setFillStateAndMaterial_setFillStateAndMaterial,
} from "./types/setFillStateAndMaterial"

export const SET_FILL_STATE_AND_MATERIAL_MUTATION = gql`
  mutation setFillStateAndMaterial($qrCode: ID!, $fillState: ContainerFillState!, $material: String) {
    setFillStateAndMaterial(qrCode: $qrCode, fillState: $fillState, material: $material) {
      message
    }
  }
`

export type SetFillStateAndMaterialResult = setFillStateAndMaterial
export type SetFillStateAndMaterial = setFillStateAndMaterial_setFillStateAndMaterial
export type SetFillStateAndMaterialVariables = setFillStateAndMaterialVariables
