import { createTheme } from "@material-ui/core"

export const DRAWER_WIDTH = 220
export const RED_MARKER = "#C20E1A"
export const GREEN_MARKER = "#39b54a"

export const AppTheme = createTheme({
  palette: {
    text: {
      primary: "#6e6e6e",
      secondary: "#6e6e6e",
    },
    primary: {
      light: "#c76d72",
      main: "#C20E1A",
      dark: "#7a0a11",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#969696",
      main: "#7f7f7f",
      dark: "#545555",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#eceff1",
      paper: "#f4f7f9",
    },
    info: {
      main: "#0066CC",
    },
    error: {
      main: "#e86161",
    },
  },
  // @ts-ignore
  customColors: {
    background: "#16181f",
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica Neue',Helvetica,Arial,sans-serif",
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "1px solid #C20E1A",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "40px",
        "& $notchedOutline": {
          borderColor: "#2b303e",
        },
      },
      notchedOutline: {
        borderRadius: "0",
        borderWidth: "2px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 12px) scale(1)",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "0",
        boxShadow: "none",
      },
      contained: {
        borderRadius: 2,
        boxShadow: "0 0 12px -5px rgba(0, 0, 0, 0.3)",
        minWidth: 105,
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
  },
})
