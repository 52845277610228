import { Grid, TextField } from "@material-ui/core"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ContainerLocationCustomAutocomplete } from "../../../partials/custom-select-components/container-location-custom-autocomplete"
import { ContainerTypeCustomAutocomplete } from "../../../partials/custom-select-components/container-type-custom-autocomplete"
import { PartnerCustomAutocomplete } from "../../../partials/custom-select-components/partner-custom-autocomplete"
import { ContentCard } from "../../../partials/layout/content/ContentCard"
import { UnchangedContainersPageContext } from "../UnchangedContainersPageContext"
import { CustomDatePicker } from "../../../partials/datepicker/custom-datepicker"

interface IUnchangedContainersFilterProps {}

export const UnchangedContainersFilter: FunctionComponent<IUnchangedContainersFilterProps> = (props) => {
  const {
    unchangedContainerDateFilter,
    setUnchangedContainerDateFilter,
    containerLocations,
    selectedContainerLocations,
    setSelectedContainerLocations,
    containerTypes,
    selectedContainerTypes,
    setSelectedContainerTypes,
    partners,
    selectedPartners,
    setSelectedPartners,
    connectionReferenceFilterText,
    setConnectionReferenceFilterText,
    qrCodeFilterText,
    setQrCodeFilterText,
  } = useContext(UnchangedContainersPageContext)

  const { t } = useTranslation()

  return (
    <ContentCard>
      <Grid container direction={"row"} spacing={6}>
        <Grid item xs={4}>
          <ContainerLocationCustomAutocomplete
            id="unchanged-containers-current-location-filter"
            title={t("generic.actual_location")}
            currentlySelectedContainerLocations={selectedContainerLocations}
            availableContainerLocations={containerLocations}
            setCurrentlySelectedContainerLocations={setSelectedContainerLocations}
          />
        </Grid>
        <Grid item xs={4}>
          <ContainerTypeCustomAutocomplete
            id="unchanged-containers-container-type-filter"
            title={t("generic.container_type")}
            currentlySelectedContainerTypes={selectedContainerTypes}
            availableContainerTypes={containerTypes}
            setCurrentlySelectedContainerTypes={setSelectedContainerTypes}
          />
        </Grid>
        <Grid item xs={4}>
          <PartnerCustomAutocomplete
            id="unchanged-containers-partner-filter"
            title={t("generic.partner")}
            currentlySelectedPartners={selectedPartners}
            availablePartners={partners}
            setCurrentlySelectedPartners={setSelectedPartners}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomDatePicker
            label={t("unchanged_container.filter.since")}
            fullWidth
            format="DD.MMM YYYY"
            value={unchangedContainerDateFilter.toDate()}
            onChange={(date) => {
              if (!date) return
              setUnchangedContainerDateFilter(date)
            }}
            disableFuture
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="unchanged-containers-qr-code-filter"
            label={t("generic.qr_code")}
            fullWidth
            value={qrCodeFilterText}
            onChange={(event) => setQrCodeFilterText(event.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="connection-reference-search"
            label={t("connection_history_model.connectionReference")}
            fullWidth
            value={connectionReferenceFilterText}
            onChange={(event) => setConnectionReferenceFilterText(event.target.value)}
          />
        </Grid>
      </Grid>
    </ContentCard>
  )
}
