import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import React, { PropsWithChildren } from "react"

interface IHeadingProps {
  title: String
}

const useStyles = makeStyles((theme: Theme) => ({
  pageHeadingText: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}))

export const PageHeading: React.FunctionComponent<PropsWithChildren<IHeadingProps>> = (props) => {
  const classes = useStyles()
  const { children, title } = props

  return (
    <Grid container alignItems={"center"} justifyContent={"space-between"}>
      <Grid item>
        <Typography className={classes.pageHeadingText}>{title.toUpperCase()}</Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  )
}
