import { gql } from "apollo-boost"
import { containerTypes, containerTypes_containerTypes } from "./types/containerTypes"

export const CONTAINER_TYPES_QUERY = gql`
  query containerTypes {
    containerTypes {
      id
      name
    }
  }
`

export type ContainerType = containerTypes_containerTypes
export type ContainerTypesResult = containerTypes
