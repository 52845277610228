import { Button } from "@material-ui/core"
import React, { useContext } from "react"
import { useLazyQuery } from "react-apollo"
import { useTranslation } from "react-i18next"
import {
  InventoryListCSVResult,
  InventoryListCSVVariables,
  INVENTORY_LIST_CSV,
} from "../../../api/graphql/query/inventory_list_csv"
import { exportInventoryListCSV } from "../../../utils/exportCSV"
import { UnchangedContainersPageContext } from "./UnchangedContainersPageContext"

interface IInventoryListCsvDownloadButtonProps {}

export const InventoryListCsvDownloadButton: React.FunctionComponent<IInventoryListCsvDownloadButtonProps> = (
  props
) => {
  const { t } = useTranslation()

  const { selectedContainerLocations, selectedContainerTypes, selectedPartners } = useContext(
    UnchangedContainersPageContext
  )

  const [inventoryListCsvQuery, { data: csvData }] = useLazyQuery<InventoryListCSVResult, InventoryListCSVVariables>(
    INVENTORY_LIST_CSV,
    {
      onCompleted: () => exportInventoryListCSV(csvData?.inventoryListCsv.data as string),
      fetchPolicy: "no-cache",
    }
  )

  function getInventoryListCsv() {
    inventoryListCsvQuery({
      variables: {
        locationIds: selectedContainerLocations.map((selectedContainerLocation) => selectedContainerLocation.id),
        containerTypeIds: selectedContainerTypes.map((selectedContainerType) => selectedContainerType.id),
        partnerIds: selectedPartners.map((partner) => partner.id),
      },
    })
  }

  return (
    <Button variant="contained" type="button" color="primary" onClick={getInventoryListCsv}>
      {t("unchanged_container.download_button")}
    </Button>
  )
}
