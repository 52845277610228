import { Grid, TextField } from "@material-ui/core"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ContainerLocationCustomAutocomplete } from "../../../partials/custom-select-components/container-location-custom-autocomplete"
import { ContentCard } from "../../../partials/layout/content/ContentCard"
import { VehicleDamageReportsPageContext } from "../VehicleDamageReportsPageContext"
import { VehicleDamageReportStatusAutocomplete } from "../../../partials/custom-select-components/vehicle-damage-report-status-autocomplete"

interface IVehicleDamageReportsFilterProps {}

export const VehicleDamageReportsFilter: React.FC<IVehicleDamageReportsFilterProps> = (props) => {
  const {
    containerLocations,
    selectedContainerLocations,
    setSelectedContainerLocations,
    selectedStatus,
    setSelectedStatus,
    vehicleNumber,
    setVehicleNumber,
  } = useContext(VehicleDamageReportsPageContext)

  const { t } = useTranslation()

  return (
    <ContentCard>
      <Grid container direction={"row"} spacing={6}>
        <Grid item xs={4}>
          <TextField
            id="vehicle-damage-report-vehicle-number-filter"
            label={t("vehicle_damage_reports.filter.vehicle_number")}
            fullWidth
            value={vehicleNumber}
            onChange={(event) => setVehicleNumber(event.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ContainerLocationCustomAutocomplete
            id="vehicle-damage-reports-location-filter"
            title={t("vehicle_damage_reports.filter.location")}
            currentlySelectedContainerLocations={selectedContainerLocations}
            availableContainerLocations={containerLocations}
            setCurrentlySelectedContainerLocations={setSelectedContainerLocations}
          />
        </Grid>
        <Grid item xs={4}>
          <VehicleDamageReportStatusAutocomplete
            id={"vehicle-damage-report-status-filter"}
            title={t("dialog.status")}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />
        </Grid>
      </Grid>
    </ContentCard>
  )
}
