import {
  ContainerFillState,
  NonContainerTrackingReason,
  ResignContainerReason,
} from "./../api/graphql/graphql-global-types"
import { CloneDatabaseStep, ContainerErrorType } from "../api/graphql/graphql-global-types"
import { DamageReportStatus } from "../components/partials/custom-select-components/damage-report-status-autocomplete"
import { VehicleDamageReportStatus } from "../components/partials/custom-select-components/vehicle-damage-report-status-autocomplete"

export const de = {
  general: {
    date: "Datum",
    next: "Weiter",
    back: "Zurück",
    page: "Seite",
    of: "von",
    rows: "Einträge",
    problems: "Probleme",
    loading: "Daten werden geladen...",
    yes: "Ja",
    no: "Nein",
    sign_in: "Anmelden",
    login: "Login",
    email_address: "E-Mail",
    password: "Passwort",
    no_data: "keine Daten gefunden",
    error: {
      api: "Konnte nicht geladen werden",
      generic: "Es ist ein unerwarteter Fehler aufgetreten.",
      wrong_login: "Falsche Email oder falsches Passwort",
    },
    defect_report: "Schadensmeldung",
  },
  navigation: {
    dashboard: "Dashboard",
    unchanged_containers: "Unveränderte Behälter",
    container: "Behälter-Logistik",
    container_production: "Behälter-Produktion",
    container_inventory: "Behälter-Inventur",
    container_issues: "Behälter-Probleme",
    damage_reports: "Schadensmeldungen",
    vehicle_damage_reports: "Fahrzeugschadensmeldungen",
    non_container_tracking: "Tracking ohne Behälter",
    admin_menu: "Admin-Funktionen",
    logout: "Abmelden",
  },
  dashboard: {
    heading: "Dashboard",
    filter: {
      types: "Behälter-Typ",
      partner: "Partner",
      multiplePartners: "mehrere Partner",
      from: "Von",
      to: "Bis",
      predefined: {
        title: "Voreinstellung",
        currentMonth: "Aktueller Monat",
        lastMonth: "Letzter Monat",
        last30Days: "Letzten 30 Tage",
        currentDay: "Heute",
      },
    },
    widget: {
      day: "Tag",
      days: "Tage",
      assignment: {
        sdag: "SDAG Kunden",
        pinkrobin: "Pink Robin",
        plant: "am Standplatz",
      },
      tooltip: {
        client: "Produktive Tage",
        partner: "Nicht produktiv",
        plant: "Nicht produktiv",
      },
    },
  },
  custom_auto_complete: {
    without_container_location: "[ohne Position]",
    other_container_location: "[Andere]",
    without_container_type: "[ohne Behälter-Typ]",
    without_client: "[ohne Partner]",
  },
  container: {
    heading_logistic: "Logistik-Behälter",
    heading_production: "Produktions-Behälter",
    heading_inventory: "Behälter-Inventur",
    download: "Download Behälter",
    container_download: {
      button: "Download Statistik",
    },
    context_menu: {
      resign: "Behälter ausscheiden",
      convert_to_production: "Zu Produktionscontainer konvertieren",
      set_status_and_material: "Status und Material setzen",
    },
  },
  statistic_download_dialog: {
    title: "Zeitraum auswählen",
    from: "Von",
    to: "Bis",
    cancel: "Abbrechen",
    download: "Download",
  },
  container_issues: {
    heading: "Behälter-Probleme",
    filter: {
      error_type: {
        no_error_types: "<Kein Filter>",
      },
    },
    delete_dialog: {
      title: "Einträge löschen?",
      delete_entries_text: "Wollen Sie {{selectedEntriesCount}} Einträge wirklich löschen?",
      delete_entries_text_singular: "Wollen Sie diesen Eintrag wirklich löschen?",
    },
  },
  error: {
    no_data: {
      no_data_fetched: "Es konnten keine Daten geladen werden",
    },
  },
  generic: {
    sensor_id: "SensorID",
    internal_container_number: "Interne Behälter-Nr.",
    qr_code: "QR-Code",
    address: "Adresse",
    filllevel: "Füllstand",
    temperature: "Temp",
    gps: "GPS",
    latest_info: "Letzte Behälter-Info",
    no_info: "Keine Informationen",
    container_type: "Behälter-Typ",
    partner: "Partner",
    branding: "Branding",
    rating: "Rating",
    comment: "Kommentar",
    status: "Status",
    actual_location: "Aktueller Standort",
    last_interaction: "Letzte Aktion",
    last_client: "Letzter Client",
    location: "Standort-Zugehörigkeit",
    construction_year: "Baujahr",
    error_type: "Fehler-Typ",
    error_message: "Fehler-Text",
    inserted_at: "Erfassungsdatum",
    no_filter_selected: "<Kein Filter>",
  },
  container_inventory: {
    confirm: "Inventur bestätigen",
    confirm_success: "Inventur erfolgreich bestätigt!",
    confirm_error: "Beim bestätigen ist ein Fehler aufgetreten!",
    selectFilter: "Bitte wählen Sie Standort und Datum aus.",
    correctContainer: "Richtig inventarisierte Behälter",
    errorContainer: "Fehlerhaft inventarisierte Behälter",
    table_header: {
      address: "Adresse",
      latitude: "Lat",
      longitude: "Long",
      statusInfo: "Info",
      qr_code: "QR-Code",
      varnishing: "Branding",
      construction_year: "Baujahr",
      container_type: "Behälter-Typ",
      gps: "GPS",
      last_location: "Letzter Standort",
    },
  },
  unchanged_container: {
    heading: "Unveränderte Behälter",
    title: "Unveränderte Behälter",
    subtitle: "{{containerCount}} Behälter wurde(n) seit {{daysSinceLastMovementOrSignal}} Tag(en) nicht mehr bewegt",
    download_button: "Download Inventurliste",
    filter: {
      since: "Unverändert seit",
    },
    table_header: {
      address: "Adresse",
      latitude: "Lat",
      longitude: "Long",
      since: "Letztes Update",
      status: "Status",
      statusInfo: "Info",
      sensorId: "SensorID",
      qr_code: "QR-Code",
      productive: "Produktiv",
      overlap: "Überlappung",
      gps: "GPS",
      last_location: "Letzter Standort",
      last_partner: "Letzter Partner",
      container_type: "Behälter-Typ",
    },
  },
  damage_reports: {
    heading: "Schadensmeldungen",
    title: "Schadensmeldungen",
    filter: {
      location: "Melde-Standort",
      status: {
        [DamageReportStatus.Completed]: "Abgeschlossen",
        [DamageReportStatus.Open]: "Offen",
      },
    },
    table: {
      table_header: {
        container_qr_code: "QR-Code",
        reason: "Grund",
        driver_credentials: "Fahrer",
        locationName: "Melde-Standort",
        report_mail_sent_at: "Mail gesendet",
        inserted_at: "Erstellt am",
        damage_report_photos: "Fotos",
      },
      no_additional_info_text: "<Keine Zusatzinformationen>",
      dialog: {
        complete_damage_report: "Schadensmeldung abschließen?",
      },
      completedTooltip: {
        completedAt: "Abgeschlossen am: {{date}}",
        completedBy: "Abgeschlossen von: {{by}}",
        noInfo: "Keine Information vorhanden",
      },
    },
  },
  vehicle_damage_reports: {
    heading: "Fahrzeugschadensmeldungen",
    title: "Schadensmeldungen",
    filter: {
      location: "Melde-Standort",
      status: {
        [VehicleDamageReportStatus.Completed]: "Abgeschlossen",
        [VehicleDamageReportStatus.Open]: "Offen",
      },
      vehicle_number: "Fahrzeugnummer",
    },
    table: {
      table_header: {
        damage_report_id: "ID",
        vehicle: "Wagen",
        is_total_loss: "Totalschaden",
        reason: "Grund",
        driver: "Fahrer",
        comment: "Kommentar",
        locationName: "Melde-Standort",
        report_mail_sent_at: "Mail gesendet",
        damage_report_photos: "Fotos",
        status: "Status",
        equipment_number: "Equipmentnummer",
      },
    },
    complete_dialog: {
      title: "Fahrzeugschadensmeldung abschließen?",
      no: "Nein",
      yes: "Ja",
      error: "Beim Abschließen der Fahrzeugschadensmeldung ist ein Fehler aufgetreten!",
      comment: "Kommentar",
      name: "Name",
      fixed_intern: "Intern behoben",
    },
    completed_name: "Abgeschlossen von: {{name}}",
    comment: "Kommentar: {{comment}}",
    fixed_intern: "Intern behoben: {{fixed_intern}}",
    completed_at: "Abgeschlossen am: {{date}}",
    no: "Nein",
    yes: "Ja",
  },
  non_container_tracking_page: {
    heading: "Tracking ohne Behälter",
    title: "Tracking ohne Behälter",
    filter: {
      non_container_tracking_reason: {
        no_reason: "<Kein Filter>",
      },
    },
    table: {
      no_additional_info_text: "<Keine Zusatzinformationen>",
      dialog: {
        delete_damage_report: "Schadensmeldung und dazugehörige Fotos löschen?",
      },
    },
  },
  admin_menu: {
    heading: "Admin-Funktionen",
    title: "Admin-Funktionen",
    clone_db: {
      title: "Datenbank klonen",
      trigger_clone_button: "Vorgang starten",
    },
  },
  container_detail: {
    address: "Adresse",
    latitude: "LAT",
    longitude: "LONG",
    since: "Letztes Update",
    status: "Status",
    statusInfo: "Info",
    sensorId: "SensorID",
    qr_code: "QR-Code",
    productive: "Produktiv",
    overlap: "Überlappung",
    gps: "GPS",
    partnerName: "User",
    driverCredentials: "Fahrer",
  },

  multi_select: {
    chooseAll: "Alle auswählen",
    clearSelection: "Alle abwählen",
  },
  custom_autocomplete: {
    selectAll: "Alle auswählen",
    clearSelection: "Alle abwählen",
    tagText: "Element(e) ausgewählt",
    loading: "Lädt...",
    noOptions: "Keine Einträge gefunden",
  },
  dialog: {
    no: "Nein",
    yes: "Ja",
    convertContainer: "Behälter konvertieren?",
    setStatusAndMaterial: "Status und Material setzen?",
    status: "Status",
    material: "Material",
    resolveContainerError: "Behälter-Problem wirklich löschen?",
  },
  resign_container_dialog: {
    title: "Behälter ausscheiden?",
    reason: "Grund",
    material: "Material",
  },
  delete_non_container_trackings_dialog: {
    title: "Einträge löschen?",
    delete_entries_text: "Wollen Sie {{selectedEntriesCount}} Einträge wirklich löschen?",
    delete_entries_text_singular: "Wollen Sie diesen Eintrag wirklich löschen?",
  },
  tableCountComponent: {
    currentlyLoading: "- Einträge wurden gefunden",
    noEntriesFetched: "Keine Einträge gefunden",
    oneEntryFetched: "1 Eintrag wurde gefunden",
    multipleEntriesFetched: "{{totalNumberOfEntries}} Einträge wurden gefunden",
  },
  container_model: {
    container_fill_state: {
      description: "Füllstand",
      [ContainerFillState.NOT_AVAILABLE]: "[Ohne Füllstand]",
      [ContainerFillState.EMPTY]: "Leer",
      [ContainerFillState.FILLING]: "In Befüllung",
      [ContainerFillState.FULL]: "Voll",
      [ContainerFillState.FULL_LOGISTIC]: "Voll von Logistik",
    },
    resign_container_reason: {
      [ResignContainerReason.RESIGN_CONTAINER]: "Behälter verschrottet",
      [ResignContainerReason.PASTED_OVER]: "QR-Code-Überklebung",
    },
  },
  container_error_model: {
    error_types: {
      [ContainerErrorType.DEFECT_CONTAINER]: "Schadensmeldung",
      [ContainerErrorType.INVALID_CONTAINER_CHARACTERISTIC]: "Falsches Behältermerkmal",
      [ContainerErrorType.NO_CONTAINER_TYPE]: "Fehlender Behältertyp",
      [ContainerErrorType.NO_ORIGIN_LOCATION]: "Fehlende Standortzugehörigkeit",
      [ContainerErrorType.ORDER_COMPLETION_NO_LOCATION]: "Fehlender Standort bei Auftragsabschluss",
      [ContainerErrorType.ORDER_COMPLETION_OTHER_LOCATION]: "Anderer Standort bei Auftragsabschluss",
      [ContainerErrorType.OTHER_ORIGIN_LOCATION]: "Andere Standortzugehörigkeit bei Inventarisierung",
      [ContainerErrorType.TOO_OFTEN_SCANNED]: "Öfters als 5 mal gescannt",
      [ContainerErrorType.UNCONNECTED_FOR_TOO_LONG]: "Fehlender Auftragsabschluss nach Abzieher/Tauscher",
      [ContainerErrorType.CONTAINER_IN_PRODUCTION_USAGE]: "Behälter mit Containerinhalt",
    },
  },
  connection_history_model: {
    connectionReference: "Objektnummer",
  },
  non_container_tracking_model: {
    qr_code: "QR-Code",
    driver_credentials: "Fahrer",
    reference: "Objektnummer",
    address: "Adresse",
    created_at: "Erstellt am",
    non_container_tracking_reason: {
      description: "Grund",
      [NonContainerTrackingReason.EXTERNAL_CONTAINER]: "Nicht unser Behälter | Fremdbehälter",
      [NonContainerTrackingReason.NO_QR_CODE_AVAILABLE]: "QR-Code vom Behälter gestern/heute",
      [NonContainerTrackingReason.YESTERDAYS_CONTAINER]: "Behälter nicht inventarisiert/beklebt",
    },
  },
  clone_database_state_model: {
    current_step: "Aktueller Schritt",
    started_at: "Gestartet",
    finished_at: "Abgeschlossen",
    clone_database_step: {
      [CloneDatabaseStep.STARTING]: "Vorbereitung",
      [CloneDatabaseStep.CLONING_NOT_ALLOWED]: "Vorgang nicht erlaubt",
      [CloneDatabaseStep.DUMPING_SOURCE]: "Backup erstellen",
      [CloneDatabaseStep.DUMPING_SOURCE_FAILED]: "Backup fehlgeschlagen",
      [CloneDatabaseStep.DUMPING_OWN]: "Backup erstellen",
      [CloneDatabaseStep.DUMPING_OWN_FAILED]: "Backup fehlgeschlagen",
      [CloneDatabaseStep.DROPPING_TABLES]: "Datenbank zurücksetzen",
      [CloneDatabaseStep.DROPPING_TABLES_FAILED]: "Zurücksetzen fehlgeschlagen",
      [CloneDatabaseStep.IMPORTING_DUMP]: "Backup importieren",
      [CloneDatabaseStep.MIGRATING]: "Datenbank migrieren",
      [CloneDatabaseStep.MIGRATE_FAILED]: "Migration fehlgeschlagen",
      [CloneDatabaseStep.POST_PROCESSING]: "Import finalisieren",
      [CloneDatabaseStep.POST_PROCESSING_FAILED]: "Finalisierung fehlgeschlagen",
      [CloneDatabaseStep.GENERAL_ERROR]: "Unerwarteter Fehler",
      [CloneDatabaseStep.SUCCESS]: "Erfolgreich abgeschlossen",
    },
  },
  container_history: {
    rating: "Bewertung: ",
    no_rating: "keine Bewertung",
    rating_changed: "geändert von {{oldRating}} auf {{newRating}}",
    time_stamp: "Datum: ",
    comment: "Kommentar: ",
    comment_removed: "Kommentar entfernt",
  },
}
