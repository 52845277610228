import React from "react"
import { Route, Routes } from "react-router"
import { LoginPage } from "../login/login-page"
import { Portal } from "../portal/Portal"

export const App: React.FC = () => {
  return (
    <Routes>
      <Route path={"/login"} element={<LoginPage />} />
      <Route path={"/portal/*"} element={<Portal />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  )
}
