import { Typography } from "@material-ui/core"
import lodash from "lodash"
import React, { Fragment, FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { IGenericContainer } from "../../../domain/models/interfaces/IGenericSensorOrContainer"
import { ContentCard } from "../../partials/layout/content/ContentCard"
import { ContentContainer } from "../../partials/layout/content/ContentContainer"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { IPin, Map } from "../../partials/map/Map"
import { MarkerContainerInfoWindowContent } from "../../partials/map/MarkerInfoWindow"
import { ContainerIssueFilter } from "./ContainerIssueFilter"
import { ContainerIssueTable } from "./ContainerIssueTable"
import { ContainerIssuePageContext } from "./context/ContainerIssuePageContext"

interface IContainerIssuePageContentProps {}

export const ContainerIssuePageContent: FunctionComponent<IContainerIssuePageContentProps> = (props) => {
  const { t } = useTranslation()

  const {
    containerIssues,
    containerIssuesError,
    containerIssuesLoading,
    refetchContainerIssues,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalPages,
    totalContainerIssueCount,
    containerIssuesForMap,
    containerIssuesForMapError,
  } = useContext(ContainerIssuePageContext)

  const pinsForMap: IPin[] = lodash
    .uniqBy(containerIssuesForMap, "qr_code") // we could have multiple entries for one container
    .map((containerIssue) => {
      const genericContainer: IGenericContainer = {
        qr_code: containerIssue.qr_code,
        lat: containerIssue.lat,
        long: containerIssue.long,
        address: containerIssue.containerAddress,
      }

      return {
        ...genericContainer,
        markerInfoWindowContent: <MarkerContainerInfoWindowContent genericContainer={genericContainer} />,
      }
    })

  return (
    <Fragment>
      <PageHeading title={t("container_issues.heading")} />
      <ContainerIssueFilter />
      {!containerIssuesError && !containerIssuesForMapError && (
        <Fragment>
          <ContentContainer>
            <Map pins={pinsForMap} />
          </ContentContainer>
          <ContentContainer>
            <ContainerIssueTable
              containerIssues={containerIssues}
              totalNumberOfContainerIssues={totalContainerIssueCount}
              refetchContainerIssues={refetchContainerIssues}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pages={totalPages}
              loading={containerIssuesLoading}
            />
          </ContentContainer>
        </Fragment>
      )}
      {(containerIssuesError || containerIssuesForMapError) && (
        <ContentCard>
          <Typography>{t("error.no_data.no_data_fetched")}</Typography>
        </ContentCard>
      )}
    </Fragment>
  )
}
