import { gql } from "apollo-boost"
import {
  getNonContainerTrackingsForMap,
  getNonContainerTrackingsForMapVariables,
  getNonContainerTrackingsForMap_getNonContainerTrackingsForMap,
} from "./types/getNonContainerTrackingsForMap"

export const NON_CONTAINER_TRACKINGS_FOR_MAP_QUERY = gql`
  query getNonContainerTrackingsForMap(
    $reasonFilter: NonContainerTrackingReason
    $qrCodeFilterText: String
    $referenceFilterText: String
  ) {
    getNonContainerTrackingsForMap(
      reasonFilter: $reasonFilter
      qrCodeFilterText: $qrCodeFilterText
      referenceFilterText: $referenceFilterText
    ) {
      id
      qr_code
      reason
      personal_number
      driver_name
      reference
      latitude
      longitude
      address
      created_at
    }
  }
`

export type GetNonContainerTrackingsForMapResult = getNonContainerTrackingsForMap
export type GetNonContainerTrackingsForMapVariables = getNonContainerTrackingsForMapVariables
export type NonContainerTrackingForMap = getNonContainerTrackingsForMap_getNonContainerTrackingsForMap
