import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme } from "@material-ui/core"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { NonContainerTrackingReason } from "../../../api/graphql/graphql-global-types"
import { ContentCard } from "../../partials/layout/content/ContentCard"
import { NonContainerTrackingPageContext } from "./context/NonContainerTrackingPageContext"

interface INonContainerTrackingProps {}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
}))

export const NonContainerTrackingFilter: FunctionComponent<INonContainerTrackingProps> = (props) => {
  const {
    nonContainerTrackingReason,
    setNonContainerTrackingReason,
    qrCodeFilter,
    setQrCodeFilter,
    referenceFilterText,
    setReferenceFilterText,
  } = useContext(NonContainerTrackingPageContext)
  
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ContentCard>
      <Grid container direction={"row"} spacing={6}>
        <Grid item xs={4}>
          <TextField
            id="qr-code-filter"
            name="qr-code-filter"
            type="text"
            fullWidth
            label={t("generic.qr_code")}
            value={qrCodeFilter}
            onChange={(event: any) => setQrCodeFilter(event.target.value as string)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="connection-reference-search"
            label={t("connection_history_model.connectionReference")}
            fullWidth
            value={referenceFilterText}
            onChange={(event) => setReferenceFilterText(event.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.fullWidth}>
            <InputLabel htmlFor="non-container-tracking-reason-filter">
              {t("non_container_tracking_model.non_container_tracking_reason.description")}
            </InputLabel>
            <Select
              id={"non-container-tracking-reason-filter"}
              name={"non-container-tracking-reason-filter"}
              fullWidth
              value={nonContainerTrackingReason || ""}
              onChange={(e) => setNonContainerTrackingReason(e.target.value as NonContainerTrackingReason)}
              className={classes.select}
            >
              <MenuItem key={"no_reason_selected"} value={""}>
                {t("generic.no_filter_selected")}
              </MenuItem>
              {Object.keys(NonContainerTrackingReason).map((nonContainerTrackingReasonKey) => (
                <MenuItem key={nonContainerTrackingReasonKey} value={nonContainerTrackingReasonKey}>
                  {t(`non_container_tracking_model.non_container_tracking_reason.${nonContainerTrackingReasonKey}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </ContentCard>
  )
}
