import { gql } from "apollo-boost"

export const CONNECTION_HISTORY = gql`
  query ConnectionHistory($qrCode: String!) {
    connectionHistory(qrCode: $qrCode) {
      since
      status
      statusInfo
      address
      latitude
      longitude
      partnerName
      driverPersonalNumber
      driverName
    }
  }
`
