import { gql } from "apollo-boost"
import {
  containerIssuesForTable,
  containerIssuesForTableVariables,
  containerIssuesForTable_containerIssuesForTable_entries,
} from "./types/containerIssuesForTable"

export const CONTAINER_ISSUES_FOR_TABLE_QUERY = gql`
  query containerIssuesForTable(
    $page: Int!
    $pageSize: Int!
    $locationIds: [String!]!
    $containerTypeIds: [String!]!
    $qrCodeFilter: String
    $containerErrorTypeFilter: ContainerErrorType
    $connectionReferenceFilterText: String
  ) {
    containerIssuesForTable(
      page: $page
      pageSize: $pageSize
      locationIds: $locationIds
      containerTypeIds: $containerTypeIds
      qrCodeFilter: $qrCodeFilter
      containerErrorTypeFilter: $containerErrorTypeFilter
      connectionReferenceFilterText: $connectionReferenceFilterText
    ) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        id
        qr_code
        error_type
        errorMessage
        errorCreatedAt
        containerTypeName
        locationName
        lat
        long
        containerAddress
      }
    }
  }
`

export type ContainerIssuesForTableResult = containerIssuesForTable
export type ContainerIssuesForTableVariables = containerIssuesForTableVariables
export type ContainerIssueForTable = containerIssuesForTable_containerIssuesForTable_entries
