import moment, { Moment } from "moment"
import React, { createContext, FunctionComponent, PropsWithChildren, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { Client, ClientResult, CLIENTS_QUERY } from "../../../api/graphql/query/clients"
import {
  ContainerLocation,
  ContainerLocationsResult,
  CONTAINER_LOCATIONS_QUERY,
} from "../../../api/graphql/query/container_locations"
import { ContainerType, ContainerTypesResult, CONTAINER_TYPES_QUERY } from "../../../api/graphql/query/container_types"
import {
  UnchangedContainer,
  UnchangedContainerResult,
  UnchangedContainerVariables,
  UNCHANGED_CONTAINER_QUERY,
} from "../../../api/graphql/query/unchanged_container"
import { SelectOption } from "../../../components/partials/custom-select-components/custom-select-component-commons"

export interface IUnchangedContainersPageContext {
  unchangedContainers: UnchangedContainer[]

  totalUnchangedContainersCount: number
  currentPage: number
  setCurrentPage: (currentPage: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  totalPages: number

  unchangedContainerDateFilter: Moment
  setUnchangedContainerDateFilter: (locations: Moment) => void

  containerLocations: ContainerLocation[]
  selectedContainerLocations: SelectOption[]
  setSelectedContainerLocations: (locations: SelectOption[]) => void

  containerTypes: ContainerType[]
  selectedContainerTypes: SelectOption[]
  setSelectedContainerTypes: (types: SelectOption[]) => void

  partners: Client[]
  selectedPartners: SelectOption[]
  setSelectedPartners: (types: SelectOption[]) => void

  connectionReferenceFilterText: string
  setConnectionReferenceFilterText: (text: string) => void

  qrCodeFilterText: string
  setQrCodeFilterText: (text: string) => void

  isUnchangedContainersLoading: boolean
  isUnchangedContainersError: Error | undefined
}

export const UnchangedContainersPageContext = createContext<IUnchangedContainersPageContext>(
  {} as IUnchangedContainersPageContext
)

interface IUnchangedContainersPageContextProviderProps {}

export const UnchangedContainersPageContextProvider: FunctionComponent<PropsWithChildren<IUnchangedContainersPageContextProviderProps>> = (
  props
) => {
  const { children } = props

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)

  const [unchangedContainerDateFilter, setUnchangedContainerDateFilter] = useState<Moment>(moment().endOf("day"))
  const [selectedContainerLocations, setSelectedContainerLocations] = useState<SelectOption[]>([])
  const [selectedContainerTypes, setSelectedContainerTypes] = useState<SelectOption[]>([])
  const [selectedPartners, setSelectedPartners] = useState<SelectOption[]>([])

  const [connectionReferenceFilterText, setConnectionReferenceFilterText] = useState<string>("")
  const [qrCodeFilterText, setQrCodeFilterText] = useState<string>("")

  const { data: containerLocationsData } = useQuery<ContainerLocationsResult>(CONTAINER_LOCATIONS_QUERY, {
    errorPolicy: "all",
  })

  const { data: containerTypesData } = useQuery<ContainerTypesResult>(CONTAINER_TYPES_QUERY, {
    errorPolicy: "all",
  })

  const { data: clientData } = useQuery<ClientResult>(CLIENTS_QUERY, {
    errorPolicy: "all",
  })

  const {
    data: unchangedContainerData,
    loading: isUnchangedContainersLoading,
    error: isUnchangedContainersError,
  } = useQuery<UnchangedContainerResult, UnchangedContainerVariables>(UNCHANGED_CONTAINER_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    variables: {
      from: unchangedContainerDateFilter.endOf("day").toDate(),
      page: currentPage,
      pageSize: pageSize,
      locationIds: selectedContainerLocations.map((selectedContainerLocation) => selectedContainerLocation.id),
      containerTypeIds: selectedContainerTypes.map((selectedContainerType) => selectedContainerType.id),
      partnerIds: selectedPartners.map((partner) => partner.id),
      connectionReferenceFilterText: connectionReferenceFilterText || null,
      qrCodeFilterText: qrCodeFilterText || null,
    },
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [
    unchangedContainerDateFilter,
    selectedContainerLocations,
    selectedContainerTypes,
    selectedPartners,
    connectionReferenceFilterText,
    qrCodeFilterText,
  ])

  const unchangedContainers = unchangedContainerData?.unchangedContainer.entries || []

  const totalUnchangedContainersCount = unchangedContainerData?.unchangedContainer.totalEntries || 0
  const totalPages = unchangedContainerData?.unchangedContainer.totalPages || 1

  const containerLocations = containerLocationsData?.containerLocations || []
  const containerTypes = containerTypesData?.containerTypes || []
  const partners = clientData?.clients || []

  return (
    <UnchangedContainersPageContext.Provider
      value={{
        unchangedContainers,
        totalUnchangedContainersCount,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        totalPages,
        unchangedContainerDateFilter,
        setUnchangedContainerDateFilter,
        containerLocations,
        selectedContainerLocations,
        setSelectedContainerLocations,
        containerTypes,
        selectedContainerTypes,
        setSelectedContainerTypes,
        partners,
        selectedPartners,
        setSelectedPartners,
        connectionReferenceFilterText,
        setConnectionReferenceFilterText,
        qrCodeFilterText,
        setQrCodeFilterText,
        isUnchangedContainersLoading,
        isUnchangedContainersError,
      }}
    >
      {children}
    </UnchangedContainersPageContext.Provider>
  )
}
