import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ContainerLocation } from "../../../api/graphql/query/container_locations"
import { CustomAutocomplete } from "../../partials/custom-select-components/custom-autocomplete"
import {
  CommonSelectOptionIdEnum,
  SelectOption,
  SortGroupEnum
} from "../../partials/custom-select-components/custom-select-component-commons"

export interface IContainerLocationCustomAutocompleteProps {
  id: string
  title: string
  availableContainerLocations: ContainerLocation[]
  currentlySelectedContainerLocations: SelectOption[]
  setCurrentlySelectedContainerLocations: (selectedContainerLocations: SelectOption[]) => void
}

export const ContainerLocationCustomAutocomplete: React.FunctionComponent<IContainerLocationCustomAutocompleteProps> = (
  props
) => {
  const {
    id,
    title,
    availableContainerLocations,
    currentlySelectedContainerLocations,
    setCurrentlySelectedContainerLocations,
  } = props
  const { t } = useTranslation()

  const containerLocationSelectOptions = useMemo(
    () => {
      const computedContainerLocationSelectOptions =
        availableContainerLocations.map(
          (containerLocation) => new SelectOption(String(containerLocation.id), containerLocation.name)
        ) || []
      computedContainerLocationSelectOptions.unshift(
        new SelectOption(
          CommonSelectOptionIdEnum.Null,
          t("custom_auto_complete.without_container_location"),
          SortGroupEnum.Second
        )
      )

      computedContainerLocationSelectOptions.unshift(
        new SelectOption(
          CommonSelectOptionIdEnum.Other,
          t("custom_auto_complete.other_container_location"),
          SortGroupEnum.Second
        )
      )
      return computedContainerLocationSelectOptions
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableContainerLocations]
  )

  return (
    <CustomAutocomplete
      id={id}
      title={title}
      currentlySelectedValues={currentlySelectedContainerLocations}
      availableSelectOptions={containerLocationSelectOptions}
      setCurrentlySelectedValues={setCurrentlySelectedContainerLocations}
      sortSelectedOptions={false}
    />
  )
}
