import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import { ErrorOutline } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    minHeight: 450
  },
  icon: {
    fill: "orange",
    fontSize: "6rem"
  }
}))

interface IErrorPlaceholderProps {
  title: string
  className?: string
}

export const ErrorPlaceholder: FunctionComponent<IErrorPlaceholderProps> = props => {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.container + ` ${props.className ?? ""}`}
    >
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <ErrorOutline className={classes.icon} />
        <Typography variant={"h5"}>{props.title}</Typography>
      </Grid>
    </Grid>
  )
}
