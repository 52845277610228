import { Grid } from "@material-ui/core"
import { useMemo, VFC } from "react"
import { useTranslation } from "react-i18next"
import { ContainerInventoryEmpty } from "./ContainerInventoryEmpty"
import { useContainerInventoryContext } from "./ContainerInventoryProvider"
import { ContainerInventoryFilter } from "./filter/ContainerInventoryFilter"
import { ContainersInventoryTable } from "./table/ContainerInventoryTable"
import { ContainerInventoryTableWrapper } from "./table/ContainerInventoryTableWrapper"
import { LoadingIndicator } from "../../partials/loading-indicator/loading-indicator"

export const ContainerInventoryContent: VFC = () => {
  const {
    selectedDate,
    selectedLocation,
    correctContainerPagination,
    setCorrectContainerPagination,
    correctContainerTotalPages,
    areCorrectContainersLoading,
    correctContainers,
    errorContainerPagination,
    setErrorContainerPagination,
    errorContainerTotalPages,
    areErrorContainersLoading,
    errorContainers,
    errorContainerTotalEntries,
    correctContainerTotalEntries,
    isLoading,
  } = useContainerInventoryContext()
  const { t } = useTranslation()

  const hasFilterSet = useMemo(() => selectedDate != null && selectedLocation != null, [selectedDate, selectedLocation])

  return (
    <>
      <LoadingIndicator isLoading={isLoading} />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <ContainerInventoryFilter />
        </Grid>
        {!hasFilterSet && (
          <Grid item>
            <ContainerInventoryEmpty text={t("container_inventory.selectFilter")} />
          </Grid>
        )}
        {hasFilterSet && (
          <>
            <Grid item>
              <ContainerInventoryTableWrapper header={t("container_inventory.correctContainer")}>
                <ContainersInventoryTable
                  containerPagination={correctContainerPagination}
                  setContainerPagination={setCorrectContainerPagination}
                  containerTotalPages={correctContainerTotalPages}
                  containerTotalEntries={correctContainerTotalEntries}
                  areContainersLoading={areCorrectContainersLoading}
                  containers={correctContainers}
                  displayLastLocation={false}
                />
              </ContainerInventoryTableWrapper>
            </Grid>
            <Grid item>
              <ContainerInventoryTableWrapper header={t("container_inventory.errorContainer")}>
                <ContainersInventoryTable
                  containerPagination={errorContainerPagination}
                  setContainerPagination={setErrorContainerPagination}
                  containerTotalPages={errorContainerTotalPages}
                  containerTotalEntries={errorContainerTotalEntries}
                  areContainersLoading={areErrorContainersLoading}
                  containers={errorContainers}
                  displayLastLocation
                />
              </ContainerInventoryTableWrapper>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
