import { gql } from "apollo-boost"
import { triggerCloneDatabase, triggerCloneDatabase_triggerCloneDatabase } from "./types/triggerCloneDatabase"

export const TRIGGER_CLONE_DATABASE_MUTATION = gql`
  mutation triggerCloneDatabase {
    triggerCloneDatabase {
      id
      current_step
      started_at
      finished_at
    }
  }
`
export type TriggerCloneDatabase = triggerCloneDatabase_triggerCloneDatabase
export type TriggerCloneDatabaseStateResult = triggerCloneDatabase
