import { Backdrop, CircularProgress, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { FunctionComponent } from "react"

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

interface ILoadingIndicatorProps {
  isLoading: boolean
}

export const LoadingIndicator: FunctionComponent<ILoadingIndicatorProps> = (props) => {
  const classes = useStyles()
  const { isLoading } = props

  return (
    <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
  )
}
