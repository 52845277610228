import { gql } from "apollo-boost"
import { getCloneDatabaseState, getCloneDatabaseState_getCloneDatabaseState } from "./types/getCloneDatabaseState"

export const GET_CLONE_DATABASE_STATE = gql`
  query getCloneDatabaseState {
    getCloneDatabaseState {
      id
      current_step
      started_at
      finished_at
    }
  }
`

export type GetCloneDatabaseState = getCloneDatabaseState_getCloneDatabaseState
export type GetCloneDatabaseStateResult = getCloneDatabaseState
