import { makeStyles, TextField, Theme } from "@material-ui/core"
import Chip from "@material-ui/core/Chip"
import Autocomplete, { AutocompleteInputChangeReason } from "@material-ui/lab/Autocomplete"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PredefinedSelections, SelectOption, SortGroupEnum } from "./custom-select-component-commons"

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete_root: {
    minWidth: 100,
    width: "100%",
  },
  custom_autocomplete_option: {
    "&[aria-selected='true']": {
      backgroundColor: "rgba(0, 0, 0, 0.4) !important",
      color: "white !important",
    },
    "& #SelectAll": {
      fontWeight: "bold",
    },
    "& #ClearSelection": {
      fontWeight: "bold",
    },
  },
  chip: {
    marginBottom: 5,
  },
}))

interface ICustomAutocompleteProps {
  id: string
  title: string
  currentlySelectedValues: SelectOption[]
  availableSelectOptions: SelectOption[]
  setCurrentlySelectedValues: (selectedValues: SelectOption[]) => void
  sortSelectedOptions: boolean
}

export const CustomAutocomplete: FunctionComponent<ICustomAutocompleteProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    title,
    currentlySelectedValues,
    availableSelectOptions,
    setCurrentlySelectedValues,
    sortSelectedOptions,
  } = props
  const [selectOpenState, setSelectOpenState] = useState<boolean>(false)
  const [sortedAvailableSelectOptions, setSortedAvailableSelectOptions] = useState<SelectOption[]>([])
  const [isSorted, setIsSorted] = useState<boolean>(false)
  const [inputText, setInputText] = useState("")

  useEffect(() => {
    const sortedAvailableSelectOptions = Array.from(availableSelectOptions)
    sortedAvailableSelectOptions.unshift(PredefinedSelections.SelectAll)
    sortedAvailableSelectOptions.unshift(PredefinedSelections.ClearSelection)
    setSortedAvailableSelectOptions(sortedAvailableSelectOptions)
  }, [availableSelectOptions])

  function onInputChange(event: any, newInputValue: string, reason: AutocompleteInputChangeReason) {
    if (reason !== "reset") {
      // we don't want to loose the current input after a value has been selected
      setInputText(newInputValue)
    }
  }

  const handleChange = (e: React.ChangeEvent<{}>, values: SelectOption[]) => {
    if (values.includes(PredefinedSelections.ClearSelection)) {
      setCurrentlySelectedValues([])
      setSelectOpenState(false)
    } else if (values.includes(PredefinedSelections.SelectAll)) {
      setCurrentlySelectedValues(
        availableSelectOptions.filter(
          (selectOption) =>
            selectOption !== PredefinedSelections.SelectAll && selectOption !== PredefinedSelections.ClearSelection
        )
      )
      setSelectOpenState(false)
    } else {
      setCurrentlySelectedValues(values)
      setSelectOpenState(true)
    }

    if (sortSelectedOptions) {
      sortAndSetAvailableSelectOptions(values)
    }
  }

  function sortAndSetAvailableSelectOptions(values: SelectOption[]) {
    let updatedSortedAvailableSelectOptions = Array.from(sortedAvailableSelectOptions)
    updatedSortedAvailableSelectOptions.forEach((selectOption) => {
      if (selectOption.sortGroup === SortGroupEnum.First || selectOption.sortGroup === SortGroupEnum.Second) {
        return
      }

      if (values.map((x) => x.id).includes(selectOption.id)) {
        selectOption.sortGroup = SortGroupEnum.Third
      } else {
        selectOption.sortGroup = SortGroupEnum.Fourth
      }
    })

    updatedSortedAvailableSelectOptions.sort((option1, option2) => {
      const sortGroupComparison = option1.sortGroup - option2.sortGroup
      if (sortGroupComparison === 0) {
        return option1.name.localeCompare(option2.name)
      }

      return sortGroupComparison
    })

    setSortedAvailableSelectOptions(updatedSortedAvailableSelectOptions)
    setIsSorted(true)
  }

  const handleIsSelected = (option: SelectOption, value: SelectOption) => {
    return option.id === value.id
  }

  const handleOpen = () => {
    if (!isSorted && sortSelectedOptions) {
      sortAndSetAvailableSelectOptions(currentlySelectedValues)
    }
    setSelectOpenState(true)
  }

  return (
    <Autocomplete
      inputValue={inputText}
      onInputChange={onInputChange}
      multiple
      limitTags={1}
      id={props.id}
      autoSelect={false}
      options={sortedAvailableSelectOptions}
      getOptionLabel={(selectOption) => selectOption.name}
      value={currentlySelectedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps }}
          variant="standard"
          label={title}
          placeholder={title}
        />
      )}
      open={selectOpenState}
      onChange={handleChange}
      onClose={() => setSelectOpenState(false)}
      onOpen={handleOpen}
      disableCloseOnSelect
      renderTags={() => (
        <Chip
          className={classes.chip}
          label={`${currentlySelectedValues.length} ${t("custom_autocomplete.tagText")}`}
        ></Chip>
      )}
      renderOption={(selectOption) => <span id={selectOption.id}>{selectOption.name}</span>}
      classes={{
        root: classes.autocomplete_root,
        option: classes.custom_autocomplete_option,
      }}
      getOptionSelected={handleIsSelected}
      loadingText={t("custom_autocomplete.loading")}
      noOptionsText={t("custom_autocomplete.noOptions")}
    />
  )
}
