import { Grid, Typography } from "@material-ui/core"
import lodash from "lodash"
import React, { FunctionComponent, PropsWithChildren } from "react"
import { InfoWindow, InfoWindowProps } from "react-google-maps"
import { useTranslation } from "react-i18next"
import { formatDate, formatFillingLevel, formatTemperature } from "../../../domain/helpers/formatText"
import { IGenericContainer } from "../../../domain/models/interfaces/IGenericSensorOrContainer"

interface IMarkerInfoWindowProps extends InfoWindowProps {
  setActiveSensorMarker: Function
}

interface IMarkerContainerInfowWindowContent {
  genericContainer: IGenericContainer
}

export const MarkerInfoWindow: React.FunctionComponent<PropsWithChildren<IMarkerInfoWindowProps>> = (props) => {
  const { children, setActiveSensorMarker, ...rest } = props

  return (
    <InfoWindow onCloseClick={() => setActiveSensorMarker(undefined)} {...rest}>
      {children}
    </InfoWindow>
  )
}

export const MarkerContainerInfoWindowContent: FunctionComponent<IMarkerContainerInfowWindowContent> = (props) => {
  const { t } = useTranslation()
  const { genericContainer } = props
  return (
    <Grid>
      {!lodash.isNil(genericContainer.qr_code) && (
        <Typography>{t("generic.qr_code") + ": " + genericContainer.qr_code}</Typography>
      )}
      {!lodash.isNil(genericContainer.container_type) && (
        <Typography>{t("generic.container_type") + ": " + genericContainer.container_type}</Typography>
      )}
      {!lodash.isNil(genericContainer.varnishing) && (
        <Typography>{t("generic.branding") + ": " + genericContainer.varnishing}</Typography>
      )}
      {!lodash.isNil(genericContainer.location) && (
        <Typography>{t("generic.location") + ": " + genericContainer.location}</Typography>
      )}
      {!lodash.isNil(genericContainer.construction_year) && (
        <Typography>{t("generic.construction_year") + ": " + genericContainer.construction_year}</Typography>
      )}
      {!lodash.isNil(genericContainer.lastStatus) &&
        !lodash.isNil(genericContainer.statusInfo) &&
        !lodash.isNil(genericContainer.lastSensorTimeSend) && (
          <Grid container direction="row">
            <Grid item xs="auto">
              <Typography>
                {`${t("generic.status")}:`}
                <span>&#160;</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{genericContainer.lastStatus}</Typography>
              <Typography>{genericContainer.statusInfo}</Typography>
              <Typography>{formatDate(genericContainer.lastSensorTimeSend)}</Typography>
            </Grid>
          </Grid>
        )}
      {!lodash.isNil(genericContainer.address) && (
        <Typography>{t("generic.address") + ": " + genericContainer.address}</Typography>
      )}
      {!lodash.isNil(genericContainer.lat) && !lodash.isNil(genericContainer.long) && (
        <Typography>{t("generic.gps") + ": " + genericContainer.lat + " | " + genericContainer.long}</Typography>
      )}
      {!lodash.isNil(genericContainer.actualLocation) && (
        <Typography>{t("generic.actual_location") + ": " + genericContainer.actualLocation}</Typography>
      )}
      {!lodash.isNil(genericContainer.last_interaction) && (
        <Typography>{t("generic.last_interaction") + ": " + formatDate(genericContainer.last_interaction)}</Typography>
      )}
      {!lodash.isNil(genericContainer.last_client_name) && (
        <Typography>{t("generic.last_client") + ": " + genericContainer.last_client_name}</Typography>
      )}
      {!lodash.isNil(genericContainer.filllevel) && (
        <Typography>{t("generic.filllevel") + ": " + formatFillingLevel(genericContainer.filllevel)}</Typography>
      )}
      {!lodash.isNil(genericContainer.temperature) && (
        <Typography>{t("generic.temperature") + ": " + formatTemperature(genericContainer.temperature)}</Typography>
      )}
    </Grid>
  )
}
