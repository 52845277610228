import { MuiThemeProvider } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import React from "react"
import ReactDOM from "react-dom"
import { I18nextProvider } from "react-i18next"
import "react-image-lightbox/style.css"
import { BrowserRouter } from "react-router-dom"
import { App } from "./components/pages/app/App"
import { ApiContextProvider } from "./context/ApiContext"
import { GraphQLProvider } from "./context/GraphQLContext"
import { UserContextProvider } from "./context/UserContext"
import "./index.css"
import { AppTheme } from "./styles/theme"
import { Environment } from "./utils/environment"
import i18n from "./utils/i18n"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

Environment.validateAllKeys()

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={AppTheme}>
      <BrowserRouter>
        <MuiThemeProvider theme={AppTheme}>
          <GraphQLProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <UserContextProvider>
                <ApiContextProvider>
                  <App />
                </ApiContextProvider>
              </UserContextProvider>
            </LocalizationProvider>
          </GraphQLProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById("root")
)
