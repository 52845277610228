import { Card, Checkbox, Grid, IconButton, Theme } from "@material-ui/core"
import { DeleteForever } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { CellInfo, Column } from "react-table"
import "react-table/react-table.css"
import { NonContainerTrackingForTable } from "../../../api/graphql/query/get-non-container-trackings-for-table"
import { formatDate } from "../../../domain/helpers/formatText"
import { CommonHelper } from "../../../utils/commonHelpers"
import { useTableCheckBoxHook } from "../../hooks/table-check-box-hook/table-check-box-hook"
import { renderHeader, renderTooltippedSpan } from "../../partials/layout/table/ReactTableHelper"
import { DeleteNonContainerTrackingsDialog } from "../../partials/non-container-tracking/DeleteNonContainerTrackingsDialog"
import { CustomStripedTable } from "../../partials/table/CustomStripedTable"
import { TableEntryCount } from "../../partials/table/TableEntryCount"
import { NonContainerTrackingPageContext } from "./context/NonContainerTrackingPageContext"

const useStyles = makeStyles((theme: Theme) => ({
  headerCheckbox: {
    padding: 0,
    color: "white",
    height: "35px",

    "&.Mui-checked": {
      color: "white",
    },
  },
  checkBox: {
    padding: 0,
    color: "secondary",

    "&.Mui-checked": {
      color: "secondary",
    },
  },
}))

interface INonContainerTrackingTableProps {}

export const NonContainerTrackingTable: React.FunctionComponent<INonContainerTrackingTableProps> = (props) => {
  const {
    nonContainerTrackings,
    nonContainerTrackingsLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalPages,
    totalNonContainerTrackingCount,
  } = useContext(NonContainerTrackingPageContext)

  const { t } = useTranslation()
  const classes = useStyles()

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const {
    selectedEntries: selectedNonContainerTrackings,
    allEntriesSelected,
    handleHeaderCheckBoxClick,
    handleRowCheckBoxClick,
    resetSelectedValues,
  } = useTableCheckBoxHook<NonContainerTrackingForTable>(nonContainerTrackings, currentPage)

  function getCheckboxHeader() {
    const numSelected = selectedNonContainerTrackings.length

    return (
      <Grid container={true} alignItems={"center"} justifyContent={"center"}>
        <Grid item={true}>
          <Checkbox
            indeterminate={numSelected > 0 && !allEntriesSelected}
            checked={allEntriesSelected}
            onChange={() => handleHeaderCheckBoxClick()}
            disableRipple={true}
            className={classes.headerCheckbox}
          />
        </Grid>
      </Grid>
    )
  }

  const columns: Column[] = [
    {
      Header: getCheckboxHeader(),
      width: 50,
      Cell: ({ original }: { original: NonContainerTrackingForTable }) => {
        const isRowSelected = !!selectedNonContainerTrackings.find(
          (selectedNonContainerTracking) => selectedNonContainerTracking.id === original.id
        )

        return (
          <Grid container={true} alignItems={"center"} justifyContent={"center"}>
            <Grid item={true}>
              <Checkbox
                checked={isRowSelected}
                onChange={() => handleRowCheckBoxClick(original)}
                disableRipple={true}
                className={classes.checkBox}
              />
            </Grid>
          </Grid>
        )
      },
    },
    {
      Header: t("non_container_tracking_model.qr_code"),
      accessor: "qr_code",
      width: 100,
    },
    {
      Header: t("non_container_tracking_model.non_container_tracking_reason.description"),
      accessor: "reason",
      width: 300,
      Cell: ({ original }: { original: NonContainerTrackingForTable }) =>
        renderTooltippedSpan(t(`non_container_tracking_model.non_container_tracking_reason.${original.reason}`)),
    },
    {
      Header: t("non_container_tracking_model.driver_credentials"),
      Cell: ({ original }: { original: NonContainerTrackingForTable }) => {
        const driverCredentials = CommonHelper.buildDriverCredentials(original.personal_number, original.driver_name)

        return renderTooltippedSpan(driverCredentials || "")
      },
      width: 150,
    },
    {
      Header: t("non_container_tracking_model.reference"),
      accessor: "reference",
      width: 300,
    },
    {
      Header: t("non_container_tracking_model.address"),
      accessor: "address",
      width: 200,
    },
    {
      Header: t("generic.gps"),
      width: 250,
      Cell: ({ original }: { original: NonContainerTrackingForTable }) =>
        renderTooltippedSpan(`${original.latitude ?? "-"} | ${original.longitude ?? "-"}`),
    },
    {
      Header: t("non_container_tracking_model.created_at"),
      accessor: "created_at",
      Cell: ({ original }: { original: NonContainerTrackingForTable }) => formatDate(original.created_at),
    },
  ].map((column) => ({
    Cell: ({ original }: { original: any }) => {
      if (!column.accessor) {
        return null
      }

      return renderTooltippedSpan(original[column.accessor])
    },
    ...column,
    Header: (cellInfo: CellInfo, col: any) => {
      if (typeof column.Header === "string") {
        return renderHeader(cellInfo, col, column.Header)
      }

      return column.Header
    },
  }))

  function renderDeleteButton() {
    return (
      <IconButton
        color="primary"
        onClick={() => setIsDeleteDialogOpen(true)}
        disabled={nonContainerTrackingsLoading || selectedNonContainerTrackings.length < 1}
      >
        <DeleteForever />
      </IconButton>
    )
  }

  return (
    <>
      <TableEntryCount
        totalNumberOfEntries={totalNonContainerTrackingCount}
        isTableLoading={nonContainerTrackingsLoading}
        actionButtonElements={renderDeleteButton()}
      />
      <Card>
        <CustomStripedTable
          data={nonContainerTrackings}
          columns={columns}
          page={currentPage}
          pages={totalPages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          loading={nonContainerTrackingsLoading}
          defaultSorted={[{ id: "created_at", desc: true }]}
        />
      </Card>
      <DeleteNonContainerTrackingsDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        elementsToDelete={selectedNonContainerTrackings}
        isDeleteAllElements={allEntriesSelected}
        resetSelectedValues={resetSelectedValues}
      />
    </>
  )
}
