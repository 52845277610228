import { Grid } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { DamageReportsFilter } from "./damage-report-filter/DamageReportsFilter"
import { DamageReportsTable } from "./damage-report-table/DamageReportsTable"
import { DamageReportsPageContextProvider } from "./DamageReportsPageContext"

interface IDamageReportsPageProps {}

export const DamageReportsPage: React.FunctionComponent<IDamageReportsPageProps> = (props) => {
  const { t } = useTranslation()

  return (
    <DamageReportsPageContextProvider>
      <PageHeading title={t("damage_reports.heading")} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DamageReportsFilter />
        </Grid>
        <Grid item xs={12}>
          <DamageReportsTable />
        </Grid>
      </Grid>
    </DamageReportsPageContextProvider>
  )
}
