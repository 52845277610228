import { gql } from "apollo-boost"
import {
  unchangedContainer,
  unchangedContainerVariables,
  unchangedContainer_unchangedContainer,
  unchangedContainer_unchangedContainer_entries,
} from "./types/UnchangedContainer"

export const UNCHANGED_CONTAINER_QUERY = gql`
  query unchangedContainer(
    $from: DateTime!
    $page: Int!
    $pageSize: Int!
    $locationIds: [ID!]!
    $containerTypeIds: [ID!]!
    $partnerIds: [ID!]!
    $connectionReferenceFilterText: String
    $qrCodeFilterText: String
  ) {
    unchangedContainer(
      from: $from
      page: $page
      pageSize: $pageSize
      locationIds: $locationIds
      containerTypeIds: $containerTypeIds
      partnerIds: $partnerIds
      connectionReferenceFilterText: $connectionReferenceFilterText
      qrCodeFilterText: $qrCodeFilterText
    ) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        address
        latitude
        longitude
        since
        status
        qr_code
        productive
        overlap
        statusInfo
        containerTypeName
        containerLocationName
        partnerName
      }
    }
  }
`

export type UnchangedContainerResult = unchangedContainer
export type UnchangedContainer = unchangedContainer_unchangedContainer_entries
export type UnchangedContainerVariables = unchangedContainerVariables
export type UnchangedContainerPaginationResult = unchangedContainer_unchangedContainer
