import { Card, Grid, makeStyles, Theme, useTheme } from "@material-ui/core"
import moment from "moment"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CellInfo, Column } from "react-table"
import "react-table/react-table.css"
import { UnchangedContainer } from "../../../../api/graphql/query/unchanged_container"
import { formatDate } from "../../../../domain/helpers/formatText"
import { ContentContainer } from "../../../partials/layout/content/ContentContainer"
import { renderHeader, renderTooltippedSpan } from "../../../partials/layout/table/ReactTableHelper"
import { LoadingPlaceholder } from "../../../partials/placeholders/loading-placeholder"
import { CustomStripedTable } from "../../../partials/table/CustomStripedTable"
import { Text } from "../../../partials/text/Text"
import { PopoverContainerHistory } from "../../container-issue/partials/PopoverContainerHistory"
import { UnchangedContainersPageContext } from "../UnchangedContainersPageContext"

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    height: "80px",
    minHeight: "0",
  },
}))

interface IUnchangedContainersTableProps {}

export const UnchangedContainersTable: React.FunctionComponent<IUnchangedContainersTableProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const {
    isUnchangedContainersLoading,
    unchangedContainers,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalPages,
    totalUnchangedContainersCount,
    unchangedContainerDateFilter,
  } = useContext(UnchangedContainersPageContext)

  const columns: Column[] = [
    {
      Header: t("unchanged_container.table_header.qr_code"),
      accessor: "qr_code",
      width: 130,
    },
    {
      Header: t("unchanged_container.table_header.container_type"),
      accessor: "containerTypeName",
      width: 200,
    },
    {
      Header: t("unchanged_container.table_header.address"),
      accessor: "address",
    },
    {
      Header: t("unchanged_container.table_header.gps"),
      sortable: false,
      filterable: false,
      accessor: "gps",
      width: 180,
    },
    {
      Header: t("unchanged_container.table_header.last_location"),
      accessor: "containerLocationName",
      width: 220,
    },
    {
      Header: t("unchanged_container.table_header.last_partner"),
      accessor: "partnerName",
      width: 220,
    },
    {
      Header: t("unchanged_container.table_header.statusInfo"),
      accessor: "statusInfo",
      width: 150,
    },
    {
      Header: t("unchanged_container.table_header.since"),
      accessor: "since",
      Cell: ({ original }: { original: UnchangedContainer }) => {
        return renderTooltippedSpan(formatDate(original.since))
      },
      width: 160,
      sortMethod: (a: any, b: any) => {
        return moment(b).diff(moment(a))
      },
    },
    {
      Header: "",
      sortable: false,
      width: 50,
      Cell: ({ original }: { original: any }) => {
        return (
          <Grid container item justifyContent="center">
            <Grid item>
              <PopoverContainerHistory qrCode={original.qr_code} />
            </Grid>
          </Grid>
        )
      },
    },
  ].map((column) => ({
    Cell: ({ original }: { original: any }) => {
      if (!column.accessor) {
        return null
      }

      return renderTooltippedSpan(original[column.accessor])
    },
    ...column,
    Header: (cellInfo: CellInfo, col: any) => renderHeader(cellInfo, col, column.Header),
  }))

  const tableData = unchangedContainers.map((container) => {
    return {
      ...container,
      gps: `${container.latitude ?? -1} | ${container.longitude ?? -1}`,
      productive: container.productive === true ? t("general.yes") : t("general.no"),
    }
  })

  return (
    <ContentContainer>
      {isUnchangedContainersLoading ? (
        <LoadingPlaceholder className={classes.loadingContainer} />
      ) : (
        <Grid container item justifyContent={"space-between"} direction={"row"} alignItems="center">
          <Grid item>
            <Text variant={"body1"} themeColor={theme.palette.text.secondary}>
              {`${t(`unchanged_container.subtitle`, {
                containerCount: totalUnchangedContainersCount,
                daysSinceLastMovementOrSignal: moment().endOf("day").diff(unchangedContainerDateFilter, "days"),
              })} `}
            </Text>
          </Grid>
        </Grid>
      )}

      <Card>
        <CustomStripedTable
          data={tableData}
          columns={columns}
          page={currentPage}
          pages={totalPages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          loading={isUnchangedContainersLoading}
          defaultSorted={[{ id: "since", desc: true }]}
        />
      </Card>
    </ContentContainer>
  )
}
