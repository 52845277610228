import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Client } from "../../../api/graphql/query/clients"
import { CustomAutocomplete } from "../../partials/custom-select-components/custom-autocomplete"
import {
  CommonSelectOptionIdEnum,
  SelectOption,
  SortGroupEnum,
} from "../../partials/custom-select-components/custom-select-component-commons"

export interface IPartnerCustomAutocompleteProps {
  id: string
  title: string
  availablePartners: Client[]
  currentlySelectedPartners: SelectOption[]
  setCurrentlySelectedPartners: (currentlySelectedPartners: SelectOption[]) => void
}

export const PartnerCustomAutocomplete: React.FunctionComponent<IPartnerCustomAutocompleteProps> = (props) => {
  const { id, title, availablePartners, currentlySelectedPartners, setCurrentlySelectedPartners } = props
  const { t } = useTranslation()

  const partnerSelectOptions = useMemo(
    () => {
      const computedPartnerSelectOptions =
        availablePartners?.map((partner) => new SelectOption(String(partner.id), partner.name)) || []
      computedPartnerSelectOptions.unshift(
        new SelectOption(CommonSelectOptionIdEnum.Null, t("custom_auto_complete.without_client"), SortGroupEnum.Second)
      )
      return computedPartnerSelectOptions
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availablePartners]
  )

  return (
    <CustomAutocomplete
      id={id}
      title={title}
      currentlySelectedValues={currentlySelectedPartners}
      availableSelectOptions={partnerSelectOptions}
      setCurrentlySelectedValues={setCurrentlySelectedPartners}
      sortSelectedOptions={false}
    />
  )
}
