import { clients, clients_clients } from './types/clients';
import { gql } from "apollo-boost"

export const CLIENTS_QUERY = gql`    
    query clients {
        clients {
           id
           name
        }
    }
`
export type Client = clients_clients
export type ClientResult = clients