import { gql } from "apollo-boost"
import { inventoryConfirmations, inventoryConfirmationsVariables, inventoryConfirmations_inventoryConfirmations } from "./types/inventoryConfirmations"

export const INVENTORY_CONFIRMATIONS = gql`
  query inventoryConfirmations(
    $locationId: ID!
  ) {
    inventoryConfirmations(
      locationId: $locationId
    ) {
      id,
      clientId,
      location,
      inventorisedAt
    }
  }
`

export type InventoryConfirmationsResult = inventoryConfirmations
export type InventoryConfirmations = inventoryConfirmations_inventoryConfirmations
export type InventoryConfirmationsVariables = inventoryConfirmationsVariables
