import { gql } from "apollo-boost"
import { confirmInventory, confirmInventoryVariables } from "./types/confirmInventory"

export const CONFIRM_INVENTORY = gql`
  mutation confirmInventory($locationId: ID!, $date: DateTime!) {
    confirmInventory(locationId: $locationId, date: $date) {
      id
      clientId
      location
      inventorisedAt
    }
  }
`

export type ConfirmInventory = confirmInventory
export type ConfirmInventoryVariables = confirmInventoryVariables
