import { gql } from "apollo-boost"
import { containerHistoryForQrCode, containerHistoryForQrCode_containerHistoryForQrCode, containerHistoryForQrCodeVariables } from "./types/containerHistoryForQrCode"


export const CONTAINER_HISTORY_QUERY = gql`
  query containerHistoryForQrCode(
    $qrCode: ID!
  ) {
    containerHistoryForQrCode(
      qrCode: $qrCode
    ) {
      id
      container_type
      construction_year
      latitude
      longitude
      qr_code
      inserted_at
      source
      container_location
      client_id
      characteristic
      varnishing
      rating
      location_type
      comment
      address
      actual_location
      internal_container_number
    }
  }
`

export type ContainerHistoryVariables = containerHistoryForQrCodeVariables
export type ContainerHistory = containerHistoryForQrCode_containerHistoryForQrCode
export type ContainerHistoryResult = containerHistoryForQrCode
