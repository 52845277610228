import { Button, Card, CardContent, Grid, IconButton, makeStyles, Popover } from "@material-ui/core"
import { MoreVert } from "@material-ui/icons"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { ContainerCharacteristic, UserPermissionNameEnum } from "../../../api/graphql/graphql-global-types"
import { UserContext } from "../../../context/UserContext"

const useStyles = makeStyles(() => ({
  cardcontent: {
    "&:last-child": {
      paddingBottom: 16,
    },
  },
}))

export interface IPopoverContainerContextMenu {
  characteristic: ContainerCharacteristic
  qrCode: string
  setSelectedQrCode: (qrCode: string) => void
  setIsConvertToProductionDialogOpen: (isConvertDialogOpen: boolean) => void
  setIsSetStatusAndMaterialDialogOpen: (isSetStatusAndMaterialDialogOpen: boolean) => void
  setIsResignDialogOpen: (isResignDialogOpen: boolean) => void
}

export const PopoverContainerContextMenu: React.FunctionComponent<IPopoverContainerContextMenu> = (props) => {
  const {
    characteristic,
    qrCode,
    setSelectedQrCode,
    setIsConvertToProductionDialogOpen,
    setIsSetStatusAndMaterialDialogOpen,
    setIsResignDialogOpen,
  } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const { hasPermission } = useContext(UserContext)

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const open = Boolean(menuAnchor)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setMenuAnchor(null)
  }

  return (
    <>
      <Popover
        anchorEl={menuAnchor}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ marginTop: 48 }}
      >
        <Card elevation={12}>
          <CardContent className={classes.cardcontent}>
            <Grid
              container
              style={{ minWidth: 200 }}
              justifyContent="center"
              alignContent="center"
              direction="column"
              spacing={2}
            >
              {characteristic === ContainerCharacteristic.LOGISTIC &&
                hasPermission(UserPermissionNameEnum.CONTAINER_OVERVIEW_CONVERT_TO_PRODUCTION) && (
                  <Grid item xs={12}>
                    <Button
                      style={{ width: "100%" }}
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={() => {
                        setSelectedQrCode(qrCode)
                        setMenuAnchor(null)
                        setIsConvertToProductionDialogOpen(true)
                      }}
                    >
                      {t("container.context_menu.convert_to_production")}
                    </Button>
                  </Grid>
                )}
              {hasPermission(UserPermissionNameEnum.CONTAINER_OVERVIEW_SET_STATUS_AND_MATERIAL) && (
                <Grid item xs={12}>
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    type="button"
                    color="primary"
                    onClick={() => {
                      setSelectedQrCode(qrCode)
                      setMenuAnchor(null)
                      setIsSetStatusAndMaterialDialogOpen(true)
                    }}
                  >
                    {t("container.context_menu.set_status_and_material")}
                  </Button>
                </Grid>
              )}
              {hasPermission(UserPermissionNameEnum.CONTAINER_OVERVIEW_RESIGN_CONTAINER) && (
                <Grid item xs={12}>
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    type="button"
                    color="primary"
                    onClick={() => {
                      setSelectedQrCode(qrCode)
                      setMenuAnchor(null)
                      setIsResignDialogOpen(true)
                    }}
                  >
                    {t("container.context_menu.resign")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Popover>
      <IconButton color="primary" onClick={handleClick} size="small">
        <MoreVert />
      </IconButton>
    </>
  )
}
