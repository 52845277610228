import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from "apollo-boost"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import React, { createContext, FunctionComponent, PropsWithChildren, useState } from "react"
import { ApolloProvider } from "react-apollo"
import { LOCAL_STORAGE_CONSTANTS } from "../utils/constants"
import { Environment, EnvKey } from "../utils/environment"

interface GraphQLContextType {
  client: ApolloClient<NormalizedCacheObject>
  setJwtToken: (token: string) => void
  removeJwtToken: () => void
  accessToken: string | null
}

export const GraphQLContext = createContext<GraphQLContextType>({} as GraphQLContextType)

interface IGraphQLProviderProps {}

export const GraphQLProvider: FunctionComponent<PropsWithChildren<IGraphQLProviderProps>> = (props) => {
  const value = useGraphQLProvider()
  const { children } = props

  return (
    <GraphQLContext.Provider value={value}>
      <ApolloProvider client={value.client}>{children}</ApolloProvider>
    </GraphQLContext.Provider>
  )
}

const useGraphQLProvider = (): GraphQLContextType => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>(
    generateGraphQLClient(localStorage.getItem(LOCAL_STORAGE_CONSTANTS.JWT_TOKEN), actuallyDeleteJwtToken)
  )

  const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem(LOCAL_STORAGE_CONSTANTS.JWT_TOKEN))

  function actuallyDeleteJwtToken() {
    localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.JWT_TOKEN)
    setAccessToken(null)
  }

  function setJwtToken(token: string) {
    localStorage.setItem(LOCAL_STORAGE_CONSTANTS.JWT_TOKEN, token)
    setClient(generateGraphQLClient(token, actuallyDeleteJwtToken))
    setAccessToken(token)
  }

  function removeJwtToken() {
    actuallyDeleteJwtToken()
    setClient(generateGraphQLClient(null, () => {}))
  }

  return { client, setJwtToken, removeJwtToken, accessToken }
}

function generateGraphQLClient(token: string | null, actuallyDeleteJwtToken: () => void) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let isLogoutNecessary = false

    for (const graphQLError of graphQLErrors || []) {
      if (graphQLError.message.includes("Unauthorized")) {
        isLogoutNecessary = true
        break
      }
    }

    if (isLogoutNecessary || networkError?.message.includes("Unauthorized")) {
      actuallyDeleteJwtToken()
      window.location.href = `${window.location.origin}/login`
    }
  })

  const queryOrMutationLink = (uri: string, config = {}) => {
    return new HttpLink({
      uri,
      ...config,
      credentials: "same-origin",
    })
  }

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-authorization": token,
      },
    }
  })

  const links = [
    authLink,
    errorLink,
    queryOrMutationLink(Environment.stringFor(EnvKey.REACT_APP_GRAPHQL_ENDPOINT) ?? ""),
  ]

  return new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache(),
  })
}
