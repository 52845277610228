import { Avatar, Button, Card, Grid, SnackbarContent, TextField, Theme, makeStyles } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ApiContext } from "../../../context/ApiContext"
import { GraphQLContext } from "../../../context/GraphQLContext"
import { Text } from "../../partials/text/Text"

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    width: "100%",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.dark,
  },
  card: {
    height: 370,
    width: 320,
  },
  logo: {
    width: "60%",
  },
  logoContainer: {
    height: 50,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.dark,
  },
  loginContainer: {
    padding: 10,
    flex: 1,
  },
  fieldsContainer: {
    flex: 1,
  },
  button: {
    margin: 10,
  },
  formContainer: {
    height: "100%",
  },
  snackBar: {
    backgroundColor: theme.palette.error.dark,
    marginTop: 10,
  },
}))

export const LoginPage: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { api } = useContext(ApiContext)
  const { setJwtToken } = useContext(GraphQLContext)

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [shownError, setShownError] = useState<string | null>(null)
  const [isEmailError, setIsEmailError] = useState<boolean>(false)
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false)
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(false)

  const onSnackBarClose = () => {
    setShownError(null)
  }

  const onLoginButtonClicked = async () => {
    setIsLoginDisabled(true)
    const validEmail = isValidEmail(email)
    const validPassword = isValidPassword(password)
    setIsEmailError(!validEmail)
    setIsPasswordError(!validPassword)

    if (validEmail && validPassword) {
      try {
        const res = await api.login(email, password)
        if (res.status >= 300) {
          throw Error()
        }

        const token = res.data.token
        setJwtToken(token)
        navigate("/portal/dashboard", { replace: true })
      } catch (error) {
        console.log(error)
        setShownError(t("general.error.wrong_login"))
        setIsEmailError(true)
        setIsPasswordError(true)
        setIsLoginDisabled(false)
      }
    } else {
      setShownError(t("general.error.wrong_login"))
      setIsLoginDisabled(false)
    }
  }

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const isValidPassword = (password: string) => {
    return password.length > 0
  }

  const onEnter = (ev: any) => {
    if (ev.key === "Enter") {
      onLoginButtonClicked()
    }
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.pageContainer}>
      <Grid item>
        <Card raised className={classes.card}>
          <Grid container direction="column" className={classes.formContainer}>
            <Grid container justifyContent="center" alignItems="center" className={classes.logoContainer}>
              <img src="/images/logo.svg" className={classes.logo} alt="" />
            </Grid>

            <Grid container direction="column" alignItems="center" className={classes.loginContainer}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Text variant="h5">{t("general.sign_in")}</Text>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("general.email_address")}
                name="email"
                autoComplete="email"
                autoFocus
                error={isEmailError}
                value={email}
                onChange={(ev) => {
                  setEmail(ev.target.value)
                }}
                onKeyPress={onEnter}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("general.password")}
                type="password"
                id="password"
                autoComplete="current-password"
                error={isPasswordError}
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                onKeyPress={onEnter}
              />
            </Grid>
            <Button
              className={classes.button}
              variant="contained"
              type="button"
              color="primary"
              onClick={onLoginButtonClicked}
              disabled={isLoginDisabled}
            >
              {t("general.login")}
            </Button>
          </Grid>
        </Card>
      </Grid>
      <Grid>
        {shownError && (
          <SnackbarContent
            className={classes.snackBar}
            message={shownError}
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={onSnackBarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
      </Grid>
    </Grid>
  )
}
