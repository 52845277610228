import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { ContainerCharacteristic } from "../api/graphql/graphql-global-types"
import {
  MergedContainerDataForTableResult,
  MergedContainerDataForTableVariables,
  MergedContainerDataForTable_Container,
  MERGED_CONTAINER_DATA_FOR_TABLE,
} from "../api/graphql/query/merged_container_data_for_table"
import {
  MergedContainerDataForMapResult,
  MergedContainerDataForMapVariables,
  MergedContainerDataForMap,
  MERGED_CONTAINER_DATA_FOR_MAP,
} from "../api/graphql/query/merged_container_data_for_map"
import { GlobalFilterContext } from "./GlobalFilterContext"

export interface IContainerPageContext {
  container: MergedContainerDataForTable_Container[]
  totalContainerCount: number
  currentPage: number
  setCurrentPage: (currentPage: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  pages: number
  loading: boolean
  sorted: any[] | undefined
  setSorted: (sorted: any[]) => void
  hasError: boolean
  refetchContainer: () => void
  containerCharacteristic: ContainerCharacteristic
  containersForMap: MergedContainerDataForMap[]
  mergedContainerDataForMapLoading: boolean
  mergedContainerDataForMapError: boolean
}
export const ContainerPageContext = createContext<IContainerPageContext>({
  container: [],
  totalContainerCount: 0,
  currentPage: 0,
  setCurrentPage: (_) => {},
  pageSize: 20,
  setPageSize: (_) => {},
  pages: 1,
  loading: false,
  sorted: undefined,
  setSorted: (_) => {},
  hasError: false,
  refetchContainer: () => {},
  containerCharacteristic: ContainerCharacteristic.LOGISTIC,
  containersForMap: [],
  mergedContainerDataForMapLoading: false,
  mergedContainerDataForMapError: false,
})

interface IContainerPageContextProviderProps {
  containerCharacteristic: ContainerCharacteristic
}

export const ContainerPageContextProvider: FunctionComponent<PropsWithChildren<IContainerPageContextProviderProps>> = (props) => {
  const { children, containerCharacteristic } = props
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [sorted, setSorted] = useState<any[]>()
  const [containersForMap, setContainersForMap] = useState<MergedContainerDataForMap[]>([])

  const {
    selectedContainerLocations,
    selectedContainerTypes,
    debouncedQrCodeFilterText,
    debouncedConnectionReferenceFilterText,
    selectedContainerFillStates,
  } = useContext(GlobalFilterContext)

  useEffect(() => {
    setCurrentPage(0)
  }, [
    selectedContainerLocations,
    selectedContainerTypes,
    debouncedQrCodeFilterText,
    debouncedConnectionReferenceFilterText,
  ])

  const {
    data: mergedContainerDataForTable,
    loading: mergedContainerDataForTableLoading,
    error: mergedContainerDataForTableError,
    refetch,
  } = useQuery<MergedContainerDataForTableResult, MergedContainerDataForTableVariables>(
    MERGED_CONTAINER_DATA_FOR_TABLE,
    {
      variables: {
        currentPage: currentPage,
        pageSize: pageSize,
        sorting: (sorted || []).map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`).join(","),
        locationIds: selectedContainerLocations.map((containerLocation) => containerLocation.id),
        containerTypeIds: selectedContainerTypes.map((containerType) => containerType.id),
        containerCharacteristic: containerCharacteristic,
        qrCodeFilterText: debouncedQrCodeFilterText || null,
        connectionReferenceFilterText: debouncedConnectionReferenceFilterText || null,
        containerFillStates: selectedContainerFillStates.map((containerFillState) => containerFillState.id),
      },
      errorPolicy: "all",
    }
  )

  const refetchContainer = () => {
    const variables = {
      currentPage: currentPage,
      pageSize: pageSize,
      sorting: (sorted || []).map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`).join(","),
      locationIds: selectedContainerLocations.map((containerLocation) => containerLocation.id),
      containerTypeIds: selectedContainerTypes.map((containerType) => containerType.id),
      containerCharacteristic: containerCharacteristic,
      debouncedQrCodeFilterText: debouncedQrCodeFilterText || null,
      debouncedConnectionReferenceFilterText: debouncedConnectionReferenceFilterText || null,
      containerFillStates: selectedContainerFillStates.map((containerFillState) => containerFillState.id),
    }

    refetch(variables)
  }

  const container = mergedContainerDataForTable?.mergedContainerDataForTable.container || []
  const isContextLoading = mergedContainerDataForTableLoading
  const paginationData = mergedContainerDataForTable?.mergedContainerDataForTable.pagination
  const totalContainerCount = paginationData?.total || 0
  const totalPages = paginationData?.pages || 1

  const {
    data: mergedContainerDataForMap,
    loading: mergedContainerDataForMapLoading,
    error: mergedContainerDataForMapError,
  } = useQuery<MergedContainerDataForMapResult, MergedContainerDataForMapVariables>(MERGED_CONTAINER_DATA_FOR_MAP, {
    variables: {
      locationIds: selectedContainerLocations.map((containerLocation) => containerLocation.id),
      containerTypeIds: selectedContainerTypes.map((containerType) => containerType.id),
      containerCharacteristic: containerCharacteristic,
      qrCodeFilterText: debouncedQrCodeFilterText || null,
      connectionReferenceFilterText: debouncedConnectionReferenceFilterText || null,
      containerFillStates: selectedContainerFillStates.map((containerFillState) => containerFillState.id),
    },
    onCompleted: () => setContainersForMap(mergedContainerDataForMap?.mergedContainerDataForMap || []),
    errorPolicy: "all",
  })

  return (
    <ContainerPageContext.Provider
      value={{
        container,
        totalContainerCount,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        pages: totalPages,
        loading: isContextLoading,
        sorted,
        setSorted,
        hasError: !!mergedContainerDataForTableError,
        refetchContainer,
        containerCharacteristic,
        containersForMap,
        mergedContainerDataForMapLoading,
        mergedContainerDataForMapError: !!mergedContainerDataForMapError,
      }}
    >
      {children}
    </ContainerPageContext.Provider>
  )
}
