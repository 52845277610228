import { Grid, makeStyles, MenuItem, Select, Theme } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "react-table/react-table.css"
import { ResignContainerReason } from "../../../api/graphql/graphql-global-types"
import { YesOrNoConfirmationDialog } from "../dialog/yes-or-no-confirmation-dialog"

const useStyles = makeStyles((theme: Theme) => ({
  spacingTop: {
    marginTop: 20,
  },
  errorText: {
    color: "red",
  },
}))

export interface IResignContainerDialogProps {
  open: boolean
  onClose: () => void
  onResignContainer: (resignContainerReason: ResignContainerReason) => void
}

export const ResignContainerDialog: React.FunctionComponent<IResignContainerDialogProps> = (props) => {
  const { open, onClose, onResignContainer } = props

  const classes = useStyles()
  const { t } = useTranslation()

  const [resignContainerReason, setResignContainerReason] = useState<ResignContainerReason>(
    ResignContainerReason.RESIGN_CONTAINER
  )

  const resignContainerReasonOptions = Array.of(
    ResignContainerReason.RESIGN_CONTAINER,
    ResignContainerReason.PASTED_OVER
  )

  function onConfirm() {
    onResignContainer(resignContainerReason)
    resetValues()
  }

  function resetValues() {
    setResignContainerReason(ResignContainerReason.RESIGN_CONTAINER)
  }

  function resetAndCloseDialog() {
    resetValues()
    onClose()
  }

  return (
    <YesOrNoConfirmationDialog
      open={open}
      onClose={resetAndCloseDialog}
      onConfirm={onConfirm}
      content={
        <Grid container direction="column">
          <Grid item xs={12}>
            {t("resign_container_dialog.title")}
          </Grid>
          <Grid item xs={12} className={classes.spacingTop}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item xs={3}>
                    {t("resign_container_dialog.reason")}:
                  </Grid>
                  <Grid item xs={9}>
                    <Select
                      style={{ width: "100%" }}
                      required
                      value={resignContainerReason}
                      onChange={(event: any) => {
                        setResignContainerReason(event.target.value as ResignContainerReason)
                      }}
                    >
                      {resignContainerReasonOptions.map((containerFillState) => (
                        <MenuItem key={containerFillState} value={containerFillState}>
                          {t(`container_model.resign_container_reason.${containerFillState}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
