import {
  Drawer,
  Grid,
  Icon,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Theme,
} from "@material-ui/core"
import {
  AllInbox,
  AvTimer,
  Cancel,
  ChevronLeft,
  Dashboard,
  ExitToApp,
  Menu,
  ReportProblem,
  VerifiedUser,
} from "@material-ui/icons"
import classNames from "classnames"
import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { UserPermissionNameEnum } from "../../../api/graphql/graphql-global-types"
import { GraphQLContext } from "../../../context/GraphQLContext"
import { UserContext } from "../../../context/UserContext"
import { DRAWER_WIDTH } from "../../../styles/theme"
import { useLocation, useNavigate } from "react-router"

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
  },
  logoContainer: {
    height: 80,
  },
  menuItem: {
    paddingLeft: "14px",
    color: "white",
    marginBottom: "10px",
  },
  menuItemText: {
    color: "white !important",
    fontSize: "14px",
  },

  menuItemTextSelected: {
    color: "white !important",
    fontSize: "14px",
    fontWeight: "bold",
  },
  menuItemTextRoot: {
    padding: 0,
  },
  listItemIcon: {
    color: "white",
  },
  listItemIconRoot: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: 30,
    marginRight: 10,
  },
  listItemIconRootCollapsed: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
    marginRight: 10,
  },
  listItemRoot: {
    wdith: "100%",
    height: 28,
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 5,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
    "&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
  },
  selected: {
    backgroundColor: "rgba(255, 255, 255, 0.25) !important",
  },
  iconContainerCollapsed: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  logo: {
    width: "80%",
  },
  sideBarContent: {
    height: "100%",
  },
}))

interface ISidebarProps { }

interface IMenuItem {
  title: string
  icon: React.ReactElement
  id: string
  route?: string
  action?: () => void
  requiredPermission?: UserPermissionNameEnum
}

const basePath = "/portal"

export const Sidebar: React.FunctionComponent<ISidebarProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles()
  const { t } = useTranslation()

  const { removeJwtToken } = useContext(GraphQLContext)
  const { hasPermission } = useContext(UserContext)

  const [open, setOpen] = useState(true)

  const logout = () => {
    removeJwtToken()
    navigate("/login", { replace: true })
  }

  const menuItems: IMenuItem[] = [
    {
      id: "dashboard",
      title: t("navigation.dashboard"),
      icon: <Dashboard />,
      route: basePath + "/dashboard",
    },
    {
      id: "unchanged-containers",
      title: t("navigation.unchanged_containers"),
      icon: <AvTimer />,
      route: basePath + "/unchanged-containers",
    },
    {
      id: "fill-level",
      title: t("navigation.container"),
      icon: <AllInbox />,
      route: basePath + "/container",
    },
    {
      id: "container-production",
      title: t("navigation.container_production"),
      icon: <AllInbox />,
      route: basePath + "/container-production",
      requiredPermission: UserPermissionNameEnum.CONTAINER_PRODUCTION_OVERVIEW_PAGE,
    },
    {
      id: "container-inventory",
      title: t("navigation.container_inventory"),
      icon: <AllInbox />,
      route: basePath + "/container-inventory",
    },
    {
      id: "container-issues",
      title: t("navigation.container_issues"),
      icon: <Cancel />,
      route: basePath + "/container-issues",
    },
    {
      id: "damage-reports",
      title: t("navigation.damage_reports"),
      icon: <ReportProblem />,
      route: basePath + "/damage-reports",
      requiredPermission: UserPermissionNameEnum.DAMAGE_REPORT_OVERVIEW,
    },
    {
      id: "vehicle-damage-reports",
      title: t("navigation.vehicle_damage_reports"),
      icon: <ReportProblem />,
      route: basePath + "/vehicle-damage-reports",
      requiredPermission: UserPermissionNameEnum.VEHICLE_DAMAGE_REPORT_OVERVIEW,
    },
    {
      id: "non-container-tracking",
      title: t("navigation.non_container_tracking"),
      icon: <ReportProblem />,
      route: basePath + "/non-container-tracking",
    },
    {
      id: "admin-menu",
      title: t("navigation.admin_menu"),
      icon: <VerifiedUser />,
      route: basePath + "/admin-menu",
      requiredPermission: UserPermissionNameEnum.ADMIN_MENU,
    },
  ]

  const bottomActions: IMenuItem[] = [
    {
      id: "logout",
      title: t("navigation.logout"),
      icon: <ExitToApp />,
      action: logout,
    },
  ]

  const [selectedMenuItem, setSelectedMenuItem] = useState<IMenuItem | undefined>(
    menuItems.find((m) => {
      let pathname = location.pathname
      if (pathname === basePath) {
        pathname = basePath + "/dashboard"
      }
      return m.route === pathname
    })
  )

  const onMenuItemClick = (item: IMenuItem) => {
    if (item.route) {
      navigate(item.route, { replace: true })
      setSelectedMenuItem(item)
    } else if (item.action) {
      item.action()
    }
  }

  const isItemSelected = (item: IMenuItem): boolean => {
    return selectedMenuItem ? selectedMenuItem.id === item.id : false
  }

  const renderMenu = (items: IMenuItem[]) => {
    return (
      <List>
        {items.map((item, index) => {
          if (item.requiredPermission && !hasPermission(item.requiredPermission)) {
            return undefined
          }

          return (
            <MenuItem
              key={index}
              button={true}
              disableGutters={true}
              classes={{
                root: classes.listItemRoot,
                selected: classes.selected,
              }}
              onClick={() => onMenuItemClick(item)}
              selected={isItemSelected(item)}
            >
              <ListItemIcon
                classes={{
                  root: classNames(classes.listItemIconRoot, {
                    [classes.listItemIconRootCollapsed]: !open,
                  }),
                }}
              >
                <Icon
                  className={classNames(classes.listItemIcon, {
                    [classes.iconContainerCollapsed]: !open,
                  })}
                >
                  {item.icon}
                </Icon>
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={item.title}
                  classes={{
                    primary: isItemSelected(item) ? classes.menuItemTextSelected : classes.menuItemText,
                    root: classes.menuItemTextRoot,
                  }}
                />
              ) : undefined}
            </MenuItem>
          )
        })}
      </List>
    )
  }

  useEffect(() => {
    const resize = () => {
      let newOpenState = window.innerWidth > 992
      setOpen(newOpenState)
    }
    //will mount
    window.addEventListener("resize", resize)
    //will unmount
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  return (
    <Drawer
      variant={"permanent"}
      anchor={"left"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames([
          classes.drawerPaper,
          {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          },
        ]),
      }}
      open={open}
    >
      <Grid container direction="column" justifyContent="space-between" className={classes.sideBarContent}>
        <Grid item>
          <div>
            <Grid container={true} justifyContent={open ? "flex-end" : "center"}>
              <IconButton onClick={() => setOpen(!open)} style={{ color: "white" }}>
                {open ? <ChevronLeft /> : <Menu />}
              </IconButton>
            </Grid>
          </div>
          <Grid container={true} alignContent={"stretch"} direction={"column"} style={{ flexWrap: "unset" }}>
            <Grid item={true} className={classes.logoContainer} xs={12}>
              <Grid container={true} alignContent={"center"} justifyContent={"center"} style={{ height: "100%" }}>
                <img src={"/images/logo.svg"} className={classes.logo} alt="" />
              </Grid>
            </Grid>
            <Grid item={true} xs={12}>
              {renderMenu(menuItems)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{renderMenu(bottomActions)}</Grid>
      </Grid>
    </Drawer>
  )
}
