import { Button, Snackbar, Theme, makeStyles } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { FC, useMemo, useState } from "react"
import { useMutation, useQuery } from "react-apollo"
import { useTranslation } from "react-i18next"
import {
  CONFIRM_INVENTORY,
  ConfirmInventory,
  ConfirmInventoryVariables,
} from "../../../api/graphql/mutations/confirm-inventory"
import {
  INVENTORY_CONFIRMATION,
  InventoryConfirmationResult,
  InventoryConfirmationVariables,
} from "../../../api/graphql/query/inventory_confirmation"
import { useContainerInventoryContext } from "./ContainerInventoryProvider"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: 5,
  },
}))

export interface ISnackbarData {
  message: string
  type: "success" | "error"
}

interface IConfirmInventoryButtonProps {
  selectedDate: Date | null
  selectedLocationId: number | null
  onInventorised: VoidFunction
}

export const ConfirmInventoryButton: FC<IConfirmInventoryButtonProps> = (props) => {
  const { selectedDate, selectedLocationId, onInventorised } = props
  const { t } = useTranslation()
  const { setIsLoading } = useContainerInventoryContext()
  const [snackbarData, setSnackbarData] = useState<ISnackbarData | undefined>()
  const classes = useStyles()

  const variables = {
    date: selectedDate ?? new Date(),
    locationId: selectedLocationId?.toString() ?? "-1",
  }
  const { data: inventoryConfirmationData, refetch: refetchInventoryConfirmationData } = useQuery<
    InventoryConfirmationResult,
    InventoryConfirmationVariables
  >(INVENTORY_CONFIRMATION, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    skip: !selectedDate || !selectedLocationId,
    variables,
  })

  const [confirmInventory] = useMutation<ConfirmInventory, ConfirmInventoryVariables>(CONFIRM_INVENTORY, {
    onCompleted: () => {
      refetchInventoryConfirmationData(variables)
      setSnackbarData({ message: t("container_inventory.confirm_success"), type: "success" })
      setIsLoading(false)
      onInventorised()
    },
    onError: () => {
      refetchInventoryConfirmationData(variables)
      setSnackbarData({ message: t("container_inventory.confirm_success"), type: "error" })
      setIsLoading(false)
    },
  })

  const handleConfirm = async () => {
    if (!selectedDate || !selectedLocationId) return
    setIsLoading(true)
    await confirmInventory({ variables: { locationId: selectedLocationId.toString(), date: selectedDate } })
  }

  const isDisabled = useMemo(() => {
    return inventoryConfirmationData?.inventoryConfirmation != null || !selectedDate || !selectedLocationId
  }, [inventoryConfirmationData, selectedDate, selectedLocationId])

  return (
    <>
      <Button
        variant="contained"
        className={classes.button}
        type="button"
        color="primary"
        onClick={handleConfirm}
        disabled={isDisabled}
      >
        {t("container_inventory.confirm")}
      </Button>
      <Snackbar
        open={snackbarData !== undefined}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={1300}
        onClose={() => setSnackbarData(undefined)}
      >
        <Alert severity={snackbarData?.type} style={{ width: "100%" }}>
          {snackbarData?.message}
        </Alert>
      </Snackbar>
    </>
  )
}
