import { gql } from "apollo-boost"
import { damageReports, damageReportsVariables, damageReports_damageReports_entries } from "./types/damageReports"

export const DAMAGE_REPORTS_QUERY = gql`
  query damageReports($page: Int!, $pageSize: Int!, $locationIds: [ID!]!, $qrCodeFilter: String, $damageReportStatus: String) {
    damageReports(page: $page, pageSize: $pageSize, locationIds: $locationIds, qrCodeFilter: $qrCodeFilter, damageReportStatus: $damageReportStatus) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        id
        container_qr_code
        locationName
        driver_personal_number
        driver_name
        info_text
        reason
        report_mail_sent_at
        inserted_at
        completed_driver_name
        completed_driver_personal_number
        completed_at
        damage_report_photos {
          image_url
        }
      }
    }
  }
`

export type DamageReportsResult = damageReports
export type DamageReportsVariables = damageReportsVariables
export type DamageReport = damageReports_damageReports_entries
