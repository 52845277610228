import React, { useEffect } from "react"
import { useQuery } from "react-apollo"
import { useTranslation } from "react-i18next"
import {
  CONTAINER_LOCATIONS_QUERY,
  ContainerLocation,
  ContainerLocationsResult,
} from "../../../api/graphql/query/container_locations"
import {
  VEHICLE_DAMAGE_REPORTS_QUERY,
  VehicleDamageReport,
  VehicleDamageReportsResult,
  VehicleDamageReportsVariables,
} from "../../../api/graphql/query/vehicle_damage_reports"
import { SelectOption } from "../../partials/custom-select-components/custom-select-component-commons"
import { VehicleDamageReportStatus } from "../../partials/custom-select-components/vehicle-damage-report-status-autocomplete"

export interface IVehicleDamageReportsPageContext {
  damageReports: VehicleDamageReport[]
  refetchDamageReports: () => void

  totalDamageReportsCount: number
  currentPage: number
  setCurrentPage: (currentPage: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  totalPages: number

  containerLocations: ContainerLocation[]
  selectedContainerLocations: SelectOption[]
  setSelectedContainerLocations: (locations: SelectOption[]) => void

  selectedStatus: SelectOption | null
  setSelectedStatus: (locations: SelectOption | null) => void

  vehicleNumber: string | null
  setVehicleNumber: (vehicleNumber: string | null) => void

  isDamageReportsLoading: boolean
  isDamageReportsError: Error | undefined
}

export const VehicleDamageReportsPageContext = React.createContext<IVehicleDamageReportsPageContext>(
  {} as IVehicleDamageReportsPageContext
)

interface IVehicleDamageReportsPageContextProviderProps {
  children: React.ReactNode
}

export const VehicleDamageReportsPageContextProvider: React.FC<IVehicleDamageReportsPageContextProviderProps> = (
  props
) => {
  const { children } = props
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(20)

  const [selectedContainerLocations, setSelectedContainerLocations] = React.useState<SelectOption[]>([])
  const option = new SelectOption(
    VehicleDamageReportStatus.Open,
    t(`vehicle_damage_reports.filter.status.${VehicleDamageReportStatus.Open}`)
  )
  const [selectedStatus, setSelectedStatus] = React.useState<SelectOption | null>(option)
  const [vehicleNumber, setVehicleNumber] = React.useState<string | null>(null)

  const { data: containerLocationsData } = useQuery<ContainerLocationsResult>(CONTAINER_LOCATIONS_QUERY, {
    errorPolicy: "all",
  })

  const {
    data: damageReportsData,
    loading: isDamageReportsLoading,
    error: isDamageReportsError,
    refetch: refetchDamageReports,
  } = useQuery<VehicleDamageReportsResult, VehicleDamageReportsVariables>(VEHICLE_DAMAGE_REPORTS_QUERY, {
    variables: {
      page: currentPage,
      pageSize: pageSize,
      locationIds: selectedContainerLocations.map((location) => location.id),
      damageReportStatus: selectedStatus?.id,
      vehicleNumber: vehicleNumber,
    },
    errorPolicy: "all",
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [selectedContainerLocations, selectedStatus])

  const damageReports = damageReportsData?.vehicleDamageReports.entries || []

  const totalDamageReportsCount = damageReportsData?.vehicleDamageReports.totalEntries || 0
  const totalPages = damageReportsData?.vehicleDamageReports.totalPages || 1

  const containerLocations = containerLocationsData?.containerLocations || []

  const contextValue: IVehicleDamageReportsPageContext = {
    damageReports,
    refetchDamageReports,
    totalDamageReportsCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalPages,
    containerLocations,
    selectedContainerLocations,
    setSelectedContainerLocations,
    selectedStatus,
    setSelectedStatus,
    vehicleNumber,
    setVehicleNumber,
    isDamageReportsLoading,
    isDamageReportsError,
  }

  return (
    <VehicleDamageReportsPageContext.Provider value={contextValue}>{children}</VehicleDamageReportsPageContext.Provider>
  )
}
