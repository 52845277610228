import { VFC } from "react"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { ContainerInventoryContent } from "./ContainerInventoryContent"
import { ContainerInventoryProvider } from "./ContainerInventoryProvider"

export const ContainerInventoryPage: VFC = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeading title={t("container.heading_inventory")} />
      <ContainerInventoryProvider>
        <ContainerInventoryContent />
      </ContainerInventoryProvider>
    </>
  )
}
