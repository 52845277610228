import React, { createContext, FunctionComponent, PropsWithChildren, useContext } from "react"
import SensorPortalApi from "../api/SensorPortalApi"
import { Environment, EnvKey } from "../utils/environment"
import { GraphQLContext } from "./GraphQLContext"

interface IApiContext {
  api: SensorPortalApi
}

export const ApiContext = createContext<IApiContext>({
  api: new SensorPortalApi("", () => {}),
})

interface IApiContextProviderProps {}

export const ApiContextProvider: FunctionComponent<PropsWithChildren<IApiContextProviderProps>> = (props) => {
  const { removeJwtToken } = useContext(GraphQLContext)

  const api = new SensorPortalApi(Environment.stringFor(EnvKey.REACT_APP_REST_ENDPOINT) ?? "", removeJwtToken)
  return <ApiContext.Provider value={{ api }}>{props.children}</ApiContext.Provider>
}
