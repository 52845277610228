import React, { PropsWithChildren } from 'react';
import { Theme, makeStyles, Card } from "@material-ui/core";

interface IContentCardProps {
    children: any
}

const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
        marginTop: 12,
        padding: 12
    }
}))

export const ContentCard: React.FunctionComponent<PropsWithChildren<IContentCardProps>> = (props) => {
    const classes = useStyles()
    const { children } = props
    return (
        <Card className={classes.contentContainer}>
            {children}
        </Card>
    )
}
