import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Typography, TypographyProps } from "@material-ui/core"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  h1: {
    border: "none",
    fontSize: 22,
    fontWeight: "bold",
  },
  h2: {
    border: "none",
    fontSize: 20,
    fontWeight: "bold",
  },
  h3: {
    border: "none",
    fontSize: 18,
    fontWeight: "bold",
  },
  h4: {
    border: "none",
    fontSize: 16,
    fontWeight: "bold",
  },
  h5: {
    border: "none",
    fontSize: 14,
    fontWeight: "normal",
    letterSpacing: "0.5px",
  },
  h6: {
    border: "none",
    fontSize: 10,
    fontWeight: "normal",
    letterSpacing: "0.5px",
  },
}))

interface ITextProps extends TypographyProps {
  themeColor?: string
}

export const Text: FunctionComponent<ITextProps> = (props) => {
  const { variant, children, className, style, themeColor, ...rest } = props
  const classes = useStyles()
  const classVariant = variant || "text"
  const cn = className ? `${className} ${lodash.get(classes, classVariant)}` : lodash.get(classes, classVariant)
  const newStyle = { ...style, color: themeColor }

  return (
    <Typography variant={variant} {...rest} className={cn} style={newStyle}>
      {children}
    </Typography>
  )
}
