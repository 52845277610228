import { useMutation } from "@apollo/react-hooks"
import { Box, Card, Grid, Tooltip, Typography } from "@material-ui/core"
import React, { FunctionComponent, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { CellInfo, Column } from "react-table"
import "react-table/react-table.css"
import {
  ContainerFillState,
  ResignContainerReason,
  UserPermissionNameEnum,
} from "../../../api/graphql/graphql-global-types"
import {
  ConvertToProductionResult,
  CONVERT_TO_PRODUCTION_MUTATION,
} from "../../../api/graphql/mutations/convert-to-production"
import {
  ResignContainerResult,
  ResignContainerVariables,
  RESIGN_CONTAINER_MUTATION,
} from "../../../api/graphql/mutations/resign-container"
import {
  SetFillStateAndMaterialResult,
  SetFillStateAndMaterialVariables,
  SET_FILL_STATE_AND_MATERIAL_MUTATION,
} from "../../../api/graphql/mutations/set-fill-state-and-material"
import { ConvertToProductionVariables } from "../../../api/graphql/mutations/types/ConvertToProduction"
import { ContainerPageContext } from "../../../context/ContainerPageContext"
import { UserContext } from "../../../context/UserContext"
import { formatDate } from "../../../domain/helpers/formatText"
import { ResignContainerDialog } from "../../partials/container/ResignContainerDialog"
import { SetStatusAndMaterialDialog } from "../../partials/container/SetStatusAndMaterialDialog"
import { YesOrNoConfirmationDialog } from "../../partials/dialog/yes-or-no-confirmation-dialog"
import { renderHeader, renderTooltippedSpan } from "../../partials/layout/table/ReactTableHelper"
import { LoadingIndicator } from "../../partials/loading-indicator/loading-indicator"
import { CustomStripedTable } from "../../partials/table/CustomStripedTable"
import { TableEntryCount } from "../../partials/table/TableEntryCount"
import { PopoverContainerHistory } from "../container-issue/partials/PopoverContainerHistory"
import { PopoverContainerContextMenu } from "./PopoverContainerContextMenu"
import { convertRating, SAUBERMACHER_CLIENT_ID } from "../../../utils/rating"
import { ContainerRatingPopover } from "../../partials/container/ContainerRatingPopover"
import { Stack } from "@mui/material"
import { Environment, EnvKey } from "../../../utils/environment";

export interface IContainerTableProps {}



const StatusInfo: FunctionComponent<{
  status: string
  statusInfo: string
  date: string | undefined
}> = (props) => {
  const { status: lastStatus, statusInfo, date } = props
  const informationToDisplay = (
    <div>
      {lastStatus || "-"} <br />
      {statusInfo || ""} <br />
      {(date && formatDate(date)) || "-"}
    </div>
  )

  return (
    <Tooltip
      title={<Typography style={{ fontSize: "14px" }}>{informationToDisplay}</Typography>}
      placement={"top-start"}
    >
      <div style={{ wordBreak: "break-all", width: 50 }}>{informationToDisplay}</div>
    </Tooltip>
  )
}

export const ContainerTable: React.FunctionComponent<IContainerTableProps> = (props) => {
  const {
    container,
    totalContainerCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    pages,
    loading,
    sorted,
    setSorted,
    refetchContainer,
    containerCharacteristic: characteristic,
  } = useContext(ContainerPageContext)

  const { hasPermission, clientId } = useContext(UserContext)

  const { t } = useTranslation()

  const [selectedQrCode, setSelectedQrCode] = useState<string | null>(null)
  const [isConvertDialogOpen, setIsConvertDialogOpen] = useState<boolean>(false)
  const [isSetStatusAndMaterialDialogOpen, setIsSetStatusAndMaterialDialogOpen] = useState<boolean>(false)
  const [isResignDialogOpen, setIsResignDialogOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [resignContainer] = useMutation<ResignContainerResult, ResignContainerVariables>(RESIGN_CONTAINER_MUTATION, {
    onCompleted({ resignContainer }) {
      refetchContainer()
      setIsLoading(false)
    },
  })

  const [convertToProductionContainer] = useMutation<ConvertToProductionResult, ConvertToProductionVariables>(
    CONVERT_TO_PRODUCTION_MUTATION,
    {
      onCompleted({ convertToProduction }) {
        refetchContainer()
        setIsLoading(false)
      },
    },
  )

  const [setStatusAndMaterialMutation] = useMutation<SetFillStateAndMaterialResult, SetFillStateAndMaterialVariables>(
    SET_FILL_STATE_AND_MATERIAL_MUTATION,
    {
      onCompleted({ setFillStateAndMaterial }) {
        refetchContainer()
        setIsLoading(false)
      },
    },
  )

  const SLO_CLIENT_ID =  Environment.numberFor(EnvKey.REACT_APP_SLO_CLIENT_ID) ?? 8

  const columnProps: any[] = [
    {
      Header: t("generic.qr_code"),
      accessor: "qr_code",
      width: 80,
    },
    {
      Header: t("generic.internal_container_number"),
      accessor: "internal_container_number",
      width: 140,
      show: clientId === SLO_CLIENT_ID
    },
    {
      Header: t("generic.container_type"),
      accessor: "container_type",
      width: 100,
    },
    {
      Header: t("generic.branding"),
      accessor: "varnishing",
      width: 100,
    },
    {
      Header: t("generic.rating"),
      accessor: "rating",
      width: 100,
      Cell: ({ original }: { original: any }) => {
        if (original.rating === null) {
          return ""
        }

        if (clientId !== SAUBERMACHER_CLIENT_ID) {
          return original.rating
        }

        return (
          <Stack direction="row" spacing={1}>
            <Box display="flex" alignSelf="center">
              <Typography>{convertRating(original.rating)}</Typography>
            </Box>
            {original.qr_code && <ContainerRatingPopover qrCode={original.qr_code} />}
          </Stack>
        )
      },
    },
    {
      Header: t("generic.location"),
      accessor: "location",
      width: 150,
    },
    {
      Header: t("generic.construction_year"),
      accessor: "construction_year",
      width: 80,
    },
    {
      Header: t("generic.address"),
      accessor: "containerAddress",
      width: 300,
      sortable: false,
    },
    {
      Header: t("generic.gps"),
      sortable: false,
      filterable: false,
      accessor: "gps",
      width: 180,
    },
    {
      Header: t("generic.last_interaction"),
      accessor: "last_interaction",
      Cell: ({ original }: { original: any }) => {
        return (
          <StatusInfo statusInfo={original.statusInfo} status={original.lastStatus} date={original.last_interaction} />
        )
      },
    },
    {
      sortable: false,
      width: 50,
      Cell: ({ original }: { original: any }) => {
        return (
          <Grid container item justifyContent="center">
            <Grid item>
              <PopoverContainerHistory qrCode={original.qr_code} />
            </Grid>
          </Grid>
        )
      },
    },
  ]

  if (hasPermission(UserPermissionNameEnum.CONTAINER_OVERVIEW_LIST_MENU)) {
    columnProps.push({
      sortable: false,
      Cell: ({ original }: { original: any }) => {
        return (
          <Grid container item justifyContent="center">
            <Grid item>
              <PopoverContainerContextMenu
                characteristic={characteristic}
                qrCode={original.qr_code}
                setSelectedQrCode={setSelectedQrCode}
                setIsConvertToProductionDialogOpen={setIsConvertDialogOpen}
                setIsSetStatusAndMaterialDialogOpen={setIsSetStatusAndMaterialDialogOpen}
                setIsResignDialogOpen={setIsResignDialogOpen}
              />
            </Grid>
          </Grid>
        )
      },
      width: 50,
    })
  }

  const columns: Column[] = columnProps.map((column) => ({
    Cell: ({ original }: { original: any }) => {
      if (!column.accessor) {
        return null
      }

      return renderTooltippedSpan(original[column.accessor])
    },
    ...column,
    Header: (cellInfo: CellInfo, col: any) =>
      renderHeader(cellInfo, col, typeof column.Header === "string" ? column.Header : ""),
  }))

  const tableData = container.map((container) => {
    return {
      qr_code: container.qr_code,
      internal_container_number: container.internal_container_number,
      container_type: container.container_type,
      varnishing: container.varnishing,
      rating: container.rating,
      location: container.location,
      construction_year: container.construction_year,
      lastStatus: container.lastStatus,
      statusInfo: container.statusInfo,
      lastSensorTimeSend: container.lastSensorTimeSend,
      containerAddress: container.containerAddress,
      gps: container.lat && container.long ? `${container.lat} | ${container.long}` : "-",
      actual_location: container.actual_location,
      last_interaction: container.last_interaction,
      last_client_name: container.last_client_name,
    }
  })

  const doResignContainer = async (resignContainerReason: ResignContainerReason) => {
    if (!selectedQrCode) {
      return
    }

    setIsResignDialogOpen(false)
    setIsLoading(true)

    await resignContainer({
      variables: {
        qrCode: selectedQrCode!,
        resignContainerReason,
      },
    })
  }

  const doConvertContainer = async () => {
    if (!selectedQrCode) {
      return
    }

    setIsConvertDialogOpen(false)
    setIsLoading(true)

    await convertToProductionContainer({
      variables: {
        qrCode: selectedQrCode!,
      },
    })
  }

  const doSetStatusAndMaterial = async (fillState: ContainerFillState, material?: string) => {
    if (!selectedQrCode) {
      return
    }

    setIsSetStatusAndMaterialDialogOpen(false)
    setIsLoading(true)

    await setStatusAndMaterialMutation({
      variables: {
        qrCode: selectedQrCode!,
        fillState,
        material: material,
      },
    })
  }

  return (
    <>
      <LoadingIndicator isLoading={isLoading} />
      <TableEntryCount totalNumberOfEntries={totalContainerCount} isTableLoading={loading} />
      <Card>
        <YesOrNoConfirmationDialog
          open={isConvertDialogOpen}
          onClose={() => setIsConvertDialogOpen(false)}
          onConfirm={doConvertContainer}
          content={
            <Grid container direction="column">
              <Grid item xs={12}>
                {t("dialog.convertContainer")}
              </Grid>
            </Grid>
          }
        />
        <SetStatusAndMaterialDialog
          open={isSetStatusAndMaterialDialogOpen}
          onClose={() => {
            setIsSetStatusAndMaterialDialogOpen(false)
          }}
          onSetStatusAndMaterial={doSetStatusAndMaterial}
        />
        <ResignContainerDialog
          open={isResignDialogOpen}
          onClose={() => setIsResignDialogOpen(false)}
          onResignContainer={doResignContainer}
        />
        <CustomStripedTable
          data={tableData}
          columns={columns}
          page={currentPage}
          pages={pages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          sortable={true}
          sorted={sorted}
          onSortedChange={(newSort, column) => {
            setSorted(newSort)
          }}
          loading={loading}
        />
      </Card>
    </>
  )
}
