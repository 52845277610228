import React, { createContext, FunctionComponent, PropsWithChildren, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { NonContainerTrackingReason } from "../../../../api/graphql/graphql-global-types"
import {
  GetNonContainerTrackingsForTableResult,
  NonContainerTrackingForTable,
  NON_CONTAINER_TRACKINGS_FOR_TABLE_QUERY,
} from "../../../../api/graphql/query/get-non-container-trackings-for-table"
import { getNonContainerTrackingsForTableVariables } from "../../../../api/graphql/query/types/getNonContainerTrackingsForTable"

import { useDebounce } from "usehooks-ts"
import {
  GetNonContainerTrackingsForMapResult,
  GetNonContainerTrackingsForMapVariables,
  NonContainerTrackingForMap,
  NON_CONTAINER_TRACKINGS_FOR_MAP_QUERY,
} from "../../../../api/graphql/query/get-non-container-trackings-for-map"

export interface INonContainerTrackingPageContext {
  nonContainerTrackings: NonContainerTrackingForTable[]
  totalNonContainerTrackingCount: number
  currentPage: number
  setCurrentPage: (currentPage: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  totalPages: number
  nonContainerTrackingsLoading: boolean
  nonContainerTrackingsError: boolean
  refetchNonContainerTrackings: () => void

  nonContainerTrackingsForMap: NonContainerTrackingForMap[]
  nonContainerTrackingsForMapLoading: boolean
  nonContainerTrackingsForMapError: boolean
  refetchNonContainerTrackingsForMap: () => void

  nonContainerTrackingReason: NonContainerTrackingReason | undefined
  setNonContainerTrackingReason: (nonContainerTrackingReason: NonContainerTrackingReason | undefined) => void

  qrCodeFilter: string
  setQrCodeFilter: (qrCodeFilter: string) => void

  referenceFilterText: string
  setReferenceFilterText: (text: string) => void
}
export const NonContainerTrackingPageContext = createContext<INonContainerTrackingPageContext>({
  nonContainerTrackings: [],
  totalNonContainerTrackingCount: 0,
  currentPage: 0,
  setCurrentPage: (_) => {},
  pageSize: 20,
  setPageSize: (_) => {},
  totalPages: 1,
  nonContainerTrackingsLoading: false,
  nonContainerTrackingsError: false,
  refetchNonContainerTrackings: () => {},

  nonContainerTrackingsForMap: [],
  nonContainerTrackingsForMapLoading: false,
  nonContainerTrackingsForMapError: false,
  refetchNonContainerTrackingsForMap: () => {},

  nonContainerTrackingReason: undefined,
  setNonContainerTrackingReason: () => {},

  qrCodeFilter: "",
  setQrCodeFilter: () => {},

  referenceFilterText: "",
  setReferenceFilterText: (_) => {},
})

interface INonContainerTrackingPageContextProviderProps {}

export const NonContainerTrackingPageContextProvider: FunctionComponent<PropsWithChildren<INonContainerTrackingPageContextProviderProps>> = (
  props
) => {
  const { children } = props

  const [nonContainerTrackings, setNonContainerTrackings] = useState<NonContainerTrackingForTable[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)

  const [nonContainerTrackingsForMap, setNonContainerTrackingsForMap] = useState<NonContainerTrackingForTable[]>([])

  const [nonContainerTrackingReason, setNonContainerTrackingReason] = useState<NonContainerTrackingReason | undefined>(
    undefined
  )
  const [qrCodeFilter, setQrCodeFilter] = useState<string>("")
  const debouncedQrCodeFilter = useDebounce(qrCodeFilter, 500)

  const [referenceFilterText, setReferenceFilterText] = useState<string>("")
  const debouncedFilterText = useDebounce(referenceFilterText, 500)

  const {
    data: getNonContainerTrackingData,
    loading: nonContainerTrackingsLoading,
    error: nonContainerTrackingsError,
    refetch: refetchNonContainerTrackings,
  } = useQuery<GetNonContainerTrackingsForTableResult, getNonContainerTrackingsForTableVariables>(
    NON_CONTAINER_TRACKINGS_FOR_TABLE_QUERY,
    {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      variables: {
        page: currentPage,
        pageSize: pageSize,
        reasonFilter: nonContainerTrackingReason || null,
        qrCodeFilterText: debouncedQrCodeFilter || null,
        referenceFilterText: debouncedFilterText || null,
      },
      onCompleted: () =>
        setNonContainerTrackings(getNonContainerTrackingData?.getNonContainerTrackingsForTable.entries || []),
    }
  )

  useEffect(() => {
    setCurrentPage(0)
  }, [nonContainerTrackingReason, debouncedQrCodeFilter, debouncedFilterText])

  const {
    data: getNonContainerTrackingsForMapData,
    loading: nonContainerTrackingsForMapLoading,
    error: nonContainerTrackingsForMapError,
    refetch: refetchNonContainerTrackingsForMap,
  } = useQuery<GetNonContainerTrackingsForMapResult, GetNonContainerTrackingsForMapVariables>(
    NON_CONTAINER_TRACKINGS_FOR_MAP_QUERY,
    {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      variables: {
        reasonFilter: nonContainerTrackingReason || null,
        qrCodeFilterText: debouncedQrCodeFilter || null,
        referenceFilterText: debouncedFilterText || null,
      },
      onCompleted: () =>
        setNonContainerTrackingsForMap(getNonContainerTrackingsForMapData?.getNonContainerTrackingsForMap || []),
    }
  )

  return (
    <NonContainerTrackingPageContext.Provider
      value={{
        nonContainerTrackings,
        totalNonContainerTrackingCount: getNonContainerTrackingData?.getNonContainerTrackingsForTable.totalEntries || 0,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        totalPages: getNonContainerTrackingData?.getNonContainerTrackingsForTable.totalPages || 1,
        nonContainerTrackingsLoading,
        nonContainerTrackingsError: !!nonContainerTrackingsError,
        refetchNonContainerTrackings,
        nonContainerTrackingsForMap,
        nonContainerTrackingsForMapLoading,
        nonContainerTrackingsForMapError: !!nonContainerTrackingsForMapError,
        refetchNonContainerTrackingsForMap,
        nonContainerTrackingReason,
        setNonContainerTrackingReason,
        qrCodeFilter,
        setQrCodeFilter,
        referenceFilterText,
        setReferenceFilterText,
      }}
    >
      {children}
    </NonContainerTrackingPageContext.Provider>
  )
}
