import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"
import lodash from "lodash"
import moment, { Moment } from "moment"
import "moment/locale/de"
import React, { useEffect, useState } from "react"
import { useLazyQuery } from "react-apollo"
import { useTranslation } from "react-i18next"
import { ContainerCharacteristic } from "../../../api/graphql/graphql-global-types"
import {
  ContainerStatisticCSVResult,
  ContainerStatisticCSVVariables,
  CONTAINER_STATISTIC_CSV,
} from "../../../api/graphql/query/container_statistic_csv"
import { exportContainerStatisticCSV } from "../../../utils/exportCSV"
import { ITimeRange } from "../../../utils/ITimeRange"
import { CustomDatePicker } from "../../partials/datepicker/custom-datepicker"

interface IContainerStatisticDownloadDialogProps {
  open: boolean
  handleClose: () => void
  characteristic: ContainerCharacteristic
}

const initialTimeRange: ITimeRange = {
  fromDate: moment().startOf("month").startOf("day"),
  toDate: moment().endOf("day"),
}

export const ContainerStatisticDownloadDialog: React.FunctionComponent<IContainerStatisticDownloadDialogProps> = (
  props
) => {
  const { t } = useTranslation()
  const { open, handleClose, characteristic } = props
  const [timeRange, setTimeRange] = useState<ITimeRange>(initialTimeRange)
  const [isValidTimeRange, setIsValidTimeRange] = useState<boolean>(false)

  const [getContainerStatisticCSV, { loading: isExporting, data }] = useLazyQuery<
    ContainerStatisticCSVResult,
    ContainerStatisticCSVVariables
  >(CONTAINER_STATISTIC_CSV, {
    onCompleted: () => exportContainerStatisticCSV(characteristic, data?.containerStatisticCSV.data as string),
    fetchPolicy: "no-cache",
  })

  // reset state upon dialog opening
  useEffect(() => {
    if (open) {
      setTimeRange(initialTimeRange)
    }
  }, [open])

  useEffect(() => {
    if (!timeRange.fromDate.isValid() || !timeRange.toDate.isValid()) {
      setIsValidTimeRange(false)
      return
    }

    if (timeRange.fromDate.isAfter(timeRange.toDate)) {
      setIsValidTimeRange(false)
      return
    }

    setIsValidTimeRange(true)
  }, [timeRange])

  function onTimeRangeChange(from?: Moment, to?: Moment) {
    const newTimeRange: ITimeRange = lodash.cloneDeep(timeRange)

    if (from) {
      newTimeRange.fromDate = from.clone().startOf("day")
      if (newTimeRange.fromDate.isAfter(newTimeRange.toDate, "day")) {
        newTimeRange.toDate = newTimeRange.toDate.add(newTimeRange.fromDate.diff(timeRange.fromDate, "days"), "days")
      }
    }

    if (to) {
      newTimeRange.toDate = to.clone().endOf("day")
      if (newTimeRange.toDate.isBefore(newTimeRange.fromDate, "day")) {
        newTimeRange.fromDate = newTimeRange.fromDate.add(newTimeRange.toDate.diff(timeRange.toDate, "days"), "days")
      }
    }

    setTimeRange(newTimeRange)
  }

  function downloadContainerStatisticCsv() {
    getContainerStatisticCSV({
      variables: {
        characteristic,
        fromDate: timeRange.fromDate,
        toDate: timeRange.toDate,
      },
    })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("statistic_download_dialog.title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomDatePicker
              label={t("statistic_download_dialog.from")}
              format="DD.MMM YYYY"
              value={timeRange.fromDate.toDate()}
              onChange={(date) => {
                onTimeRangeChange(date ?? undefined, undefined)
              }}
              disableFuture
            />
          </Grid>
          <Grid item xs={6}>
            <CustomDatePicker
              label={t("statistic_download_dialog.to")}
              format="DD.MMM YYYY"
              value={timeRange.toDate.toDate()}
              onChange={(date) => {
                onTimeRangeChange(undefined, date ?? undefined)
              }}
              disableFuture
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("statistic_download_dialog.cancel")}
        </Button>
        <Button onClick={downloadContainerStatisticCsv} color="primary" disabled={isValidTimeRange === false}>
          {isExporting ? <CircularProgress size={25} /> : t("statistic_download_dialog.download")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
