import { Grid } from "@material-ui/core"
import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { DashboardFilterContextProvider } from "./DashboardFilterContext"
import { DashboardFilter } from "./partials/DashboardFilter"
import { DashboardTriptychon } from "./partials/widgets/DashboardTriptychon"

interface IDashboardProps {}

export const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <PageHeading title={t("dashboard.heading")} />
      <Fragment>
        <Grid container spacing={3}>
          <DashboardFilterContextProvider>
            <Grid item xs={12}>
              <DashboardFilter />
            </Grid>
            <Grid item xs={12}>
              <DashboardTriptychon />
            </Grid>
          </DashboardFilterContextProvider>
        </Grid>
      </Fragment>
    </Fragment>
  )
}
