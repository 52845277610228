import jwt from 'jwt-decode'
import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from "react"
import { JwtSubjectEnum, UserPermissionNameEnum } from "../api/graphql/graphql-global-types"
import { GraphQLContext } from "./GraphQLContext"

// same interface exists in the API
interface ITokenPayload {
  subject: JwtSubjectEnum
  userId: number
  userEmail: string
  clientId: number
  userPermissionNames: UserPermissionNameEnum[]
  generatedVersionHash: string
}

export interface IUserContext {
  userId?: number
  userEmail?: string
  clientId?: number
  userPermissionNames: UserPermissionNameEnum[]
  hasPermission: (requiredPermission: UserPermissionNameEnum) => boolean
}

export const UserContext = createContext<IUserContext>({
  userId: undefined,
  userEmail: undefined,
  clientId: undefined,
  userPermissionNames: [],
  hasPermission: (_) => false,
})

interface IUserContextProviderProps {}

export const UserContextProvider: FunctionComponent<PropsWithChildren<IUserContextProviderProps>> = (props) => {
  const { children } = props

  const { accessToken } = useContext(GraphQLContext)
  const payload = accessToken ? (jwt(accessToken) as ITokenPayload) : undefined

  const [userId, setUserId] = useState<number | undefined>(payload?.userId)
  const [userEmail, setUserEmail] = useState<string | undefined>(payload?.userEmail)
  const [clientId, setClientId] = useState<number | undefined>(payload?.clientId)
  const [userPermissionNames, setUserPermissionNames] = useState<UserPermissionNameEnum[]>(
    payload?.userPermissionNames || []
  )

  useEffect(() => {
    if (!accessToken) {
      setUserId(undefined)
      setUserEmail(undefined)
      setClientId(undefined)
      setUserPermissionNames([])
      return
    }

    const payload = jwt(accessToken) as ITokenPayload

    setUserId(payload.userId)
    setUserEmail(payload.userEmail)
    setClientId(payload.clientId)
    setUserPermissionNames(payload.userPermissionNames)
  }, [accessToken])

  function hasPermission(requiredPermission: UserPermissionNameEnum) {
    return userPermissionNames.includes(requiredPermission)
  }

  return (
    <UserContext.Provider
      value={{
        userId,
        userEmail,
        clientId,
        userPermissionNames,
        hasPermission,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
