import { gql } from "apollo-boost"
import { containerLocations, containerLocations_containerLocations } from "./types/containerLocations"

export const CONTAINER_LOCATIONS_QUERY = gql`
  query containerLocations {
    containerLocations {
      id
      name
    }
  }
`
export type ContainerLocation = containerLocations_containerLocations
export type ContainerLocationsResult = containerLocations
