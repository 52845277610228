import { Grid, TextField } from "@material-ui/core"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ContainerLocationCustomAutocomplete } from "../../../partials/custom-select-components/container-location-custom-autocomplete"
import { DamageReportStatusAutocomplete } from "../../../partials/custom-select-components/damage-report-status-autocomplete"
import { ContentCard } from "../../../partials/layout/content/ContentCard"
import { DamageReportsPageContext } from "../DamageReportsPageContext"

interface IDamageReportsFilterProps {}

export const DamageReportsFilter: FunctionComponent<IDamageReportsFilterProps> = (props) => {
  const {
    qrCodeFilterText,
    setQrCodeFilterText,
    containerLocations,
    selectedContainerLocations,
    setSelectedContainerLocations,
    selectedStatus,
    setSelectedStatus
  } = useContext(DamageReportsPageContext)

  const { t } = useTranslation()

  return (
    <ContentCard>
      <Grid container direction={"row"} spacing={6}>
        <Grid item xs={4}>
          <TextField
            id="damage-reports-qr-code-filter"
            label={t("generic.qr_code")}
            fullWidth
            value={qrCodeFilterText}
            onChange={(event) => setQrCodeFilterText(event.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ContainerLocationCustomAutocomplete
            id="damage-reports-location-filter"
            title={t("damage_reports.filter.location")}
            currentlySelectedContainerLocations={selectedContainerLocations}
            availableContainerLocations={containerLocations}
            setCurrentlySelectedContainerLocations={setSelectedContainerLocations}
          />
        </Grid>
        <Grid item xs={4}>
          <DamageReportStatusAutocomplete
            id={"damage-report-status-filter"}
            title={t("dialog.status")}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />
        </Grid>
      </Grid>
    </ContentCard>
  )
}
