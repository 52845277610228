import { gql } from "apollo-boost"
import {
  inventoryListCsv,
  inventoryListCsvVariables,
  inventoryListCsv_inventoryListCsv,
} from "./types/inventoryListCsv"

export const INVENTORY_LIST_CSV = gql`
  query inventoryListCsv($locationIds: [ID!]!, $containerTypeIds: [ID!]!, $partnerIds: [ID!]!) {
    inventoryListCsv(locationIds: $locationIds, containerTypeIds: $containerTypeIds, partnerIds: $partnerIds) {
      data
    }
  }
`
export type InventoryListCSV = inventoryListCsv_inventoryListCsv
export type InventoryListCSVResult = inventoryListCsv
export type InventoryListCSVVariables = inventoryListCsvVariables
