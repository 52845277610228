import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { useMutation } from "react-apollo"
import { OPERATION_RESULT_SUCCESS_MSG } from "../../../utils/constants"
import {
  Button, Checkbox,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import { ISnackbarData } from "../container-inventory/ConfirmInventoryButton"
import { COMPLETE_VEHICLE_DAMAGE_REPORT, CompleteVehicleDamageReport, CompleteVehicleDamageReportVariables } from "../../../api/graphql/mutations/complete-vehicle-damage-report"

interface ICompleteVehicleDamageReportModalProps {
  damageReportId: number
  open: boolean
  handleClose: VoidFunction
}

export const CompleteVehicleDamageReportModal: React.FC<ICompleteVehicleDamageReportModalProps> = (props) => {
  const { damageReportId, open, handleClose } = props
  const { t } = useTranslation()
  const [formData, setFormData] = useState<{ name: string; comment: string, fixedIntern: boolean }>({ name: "", comment: "", fixedIntern: false })
  const [snackbarData, setSnackbarData] = useState<ISnackbarData | undefined>()

  const dataValid = useMemo(() => formData.name.length > 0 && formData.comment.length > 0, [formData])

  const handleError = useCallback(() => {
    setSnackbarData({ message: t("vehicle_damage_reports.complete_dialog.error"), type: "error" })
  }, [t])

  const [completeVehicleDamageReportMutation, { loading }] = useMutation<
    CompleteVehicleDamageReport,
    CompleteVehicleDamageReportVariables
  >(COMPLETE_VEHICLE_DAMAGE_REPORT, {
    onError: () => {
      handleError()
      handleClose()
    },
    onCompleted: (result) => {
      const hasError = result.completeVehicleDamageReport.message !== OPERATION_RESULT_SUCCESS_MSG
      if (hasError) {
        handleError()
      }
      handleClose()
    },
  })

  const handleCompleteVehicleDamageReport = useCallback(() => {
    completeVehicleDamageReportMutation({
      variables: {
        data: {
          damageReportId: damageReportId.toString(),
          name: formData.name,
          comment: formData.comment,
          fixedIntern: formData.fixedIntern
        },
      },
    })
  }, [completeVehicleDamageReportMutation, formData, damageReportId])

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogTitle>{t("vehicle_damage_reports.complete_dialog.title")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                id="name"
                label={t("vehicle_damage_reports.complete_dialog.name")}
                fullWidth
                required
                value={formData.name}
                onChange={(event) => setFormData({ ...formData, name: event.target.value })}
              />
            </Grid>
            <Grid item>
              <TextField
                id="comment"
                label={t("vehicle_damage_reports.complete_dialog.comment")}
                fullWidth
                required
                multiline
                minRows={3}
                value={formData.comment}
                onChange={(event) => setFormData({ ...formData, comment: event.target.value })}
              />
            </Grid>
            <Grid item>
              <FormControlLabel control={
              <Checkbox
                checked={formData.fixedIntern}
                onChange={() => setFormData({ ...formData, fixedIntern: !formData.fixedIntern })}
                disableRipple={true}
              />}  label={t("vehicle_damage_reports.complete_dialog.fixed_intern")} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            {t("vehicle_damage_reports.complete_dialog.no")}
          </Button>
          <Button onClick={handleCompleteVehicleDamageReport} color="primary" disabled={!dataValid}>
            {loading ? <CircularProgress size={25} /> : t("vehicle_damage_reports.complete_dialog.yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarData !== undefined}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={1300}
        onClose={() => setSnackbarData(undefined)}
      >
        <Alert severity={snackbarData?.type} style={{ width: "100%" }}>
          {snackbarData?.message}
        </Alert>
      </Snackbar>
    </>
  )
}
