import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ContainerFillState } from "../../../api/graphql/graphql-global-types"
import { CustomAutocomplete } from "../../partials/custom-select-components/custom-autocomplete"
import { SelectOption } from "../../partials/custom-select-components/custom-select-component-commons"

export interface IContainerFillStateCustomAutocompleteProps {
  id: string
  title: string
  currentlySelectedContainerFillStates: SelectOption[]
  setCurrentlySelectedContainerFillStates: (currentlySelectedContainerFillStates: SelectOption[]) => void
}

export const ContainerFillStateCustomAutocomplete: React.FunctionComponent<IContainerFillStateCustomAutocompleteProps> = (
  props
) => {
  const { id, title, currentlySelectedContainerFillStates, setCurrentlySelectedContainerFillStates } = props
  const { t } = useTranslation()

  const containerFillStateSelectOptions = useMemo(() => {
    const availableContainerFillStates = Object.keys(ContainerFillState)
    const computedContainerFillStateSelectOptions =
      availableContainerFillStates.map(
        (containerFillState) =>
          new SelectOption(String(containerFillState), t(`container_model.container_fill_state.${containerFillState}`))
      ) || []

    return computedContainerFillStateSelectOptions
  }, [t])

  return (
    <CustomAutocomplete
      id={id}
      title={title}
      currentlySelectedValues={currentlySelectedContainerFillStates}
      availableSelectOptions={containerFillStateSelectOptions}
      setCurrentlySelectedValues={setCurrentlySelectedContainerFillStates}
      sortSelectedOptions={false}
    />
  )
}
