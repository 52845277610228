import { gql } from "apollo-boost"
import { ConvertToProduction } from "./types/ConvertToProduction"

export const CONVERT_TO_PRODUCTION_MUTATION = gql`
  mutation ConvertToProduction($qrCode: ID!) {
    convertToProduction(qrCode: $qrCode) {
      message
    }
  }
`
export type ConvertToProductionResult = ConvertToProduction
