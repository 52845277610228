import { gql } from "apollo-boost"
import { DeleteNonContainerTrackings } from "./types/DeleteNonContainerTrackings"

export const DELETE_NON_CONTAINER_TRACKINGS_MUTATION = gql`
  mutation DeleteNonContainerTrackings($idsToDelete: [Int!]!) {
    deleteNonContainerTrackings(idsToDelete: $idsToDelete) {
      error
    }
  }
`
export type DeleteNonContainerTrackingsResult = DeleteNonContainerTrackings
