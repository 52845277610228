import * as React from "react"
import { CellInfo } from "react-table"
import Tooltip from "@material-ui/core/Tooltip"
import lodash from "lodash"
import { Typography } from "@material-ui/core"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"

export const renderHeader = (cellInfo: CellInfo, column: any, title: string) => {
  return (
    <div className={"icon-header"}>
      <span className={"icon-up"}>
        <KeyboardArrowUp />
      </span>
      <span className={"icon-down"}>
        <KeyboardArrowDown />
      </span>
      <span> {title} </span>
    </div>
  )
}

export function renderTooltippedSpan(stringValueToDisplay: string) {
  if (!stringValueToDisplay || lodash.isEmpty(stringValueToDisplay)) {
    return <span />
  }

  return (
    <div>
      <Tooltip title={<Typography style={{ fontSize: "14px" }}>{stringValueToDisplay}</Typography>} placement={"top"}>
        <span>{stringValueToDisplay}</span>
      </Tooltip>
    </div>
  )
}
