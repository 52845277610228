import { Typography } from "@material-ui/core"
import React, { Fragment, FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { IGenericContainer } from "../../../domain/models/interfaces/IGenericSensorOrContainer"
import { ContentCard } from "../../partials/layout/content/ContentCard"
import { ContentContainer } from "../../partials/layout/content/ContentContainer"
import { PageHeading } from "../../partials/layout/heading/PageHeading"
import { IPin, Map } from "../../partials/map/Map"
import { MarkerContainerInfoWindowContent } from "../../partials/map/MarkerInfoWindow"
import { NonContainerTrackingPageContext } from "./context/NonContainerTrackingPageContext"
import { NonContainerTrackingFilter } from "./NonContainerTrackingFilter"
import { NonContainerTrackingTable } from "./NonContainerTrackingTable"

interface INonContainerTrackingPageContentProps {}

export const NonContainerTrackingPageContent: FunctionComponent<INonContainerTrackingPageContentProps> = (props) => {
  const { t } = useTranslation()

  const { nonContainerTrackingsError, nonContainerTrackingsForMap, nonContainerTrackingsForMapError } = useContext(
    NonContainerTrackingPageContext
  )

  const pinsForMap: IPin[] = nonContainerTrackingsForMap.map((nonContainerTracking) => {
    const genericContainer: IGenericContainer = {
      qr_code: nonContainerTracking.qr_code,
      lat: nonContainerTracking.latitude!,
      long: nonContainerTracking.longitude!,
      address: nonContainerTracking.address,
    }

    return {
      ...genericContainer,
      markerInfoWindowContent: <MarkerContainerInfoWindowContent genericContainer={genericContainer} />,
    }
  })

  return (
    <Fragment>
      <PageHeading title={t("non_container_tracking_page.heading")} />
      <NonContainerTrackingFilter />
      {!nonContainerTrackingsError && !nonContainerTrackingsForMapError && (
        <Fragment>
          <ContentContainer>
            <Map pins={pinsForMap} />
          </ContentContainer>
          <ContentContainer>
            <NonContainerTrackingTable />
          </ContentContainer>
        </Fragment>
      )}
      {(nonContainerTrackingsError || nonContainerTrackingsForMapError) && (
        <ContentCard>
          <Typography>{t("error.no_data.no_data_fetched")}</Typography>
        </ContentCard>
      )}
    </Fragment>
  )
}
