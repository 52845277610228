export const SAUBERMACHER_CLIENT_ID = 1;

export function convertRating(rating: number) {
  switch (rating) {
    case 1:
      return "D";
    case 2:
      return "C";
    case 3:
      return "B";
    default:
      return "A";
  }
}