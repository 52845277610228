import lodash from "lodash"
import moment, { Moment } from "moment";

export function formatFillingLevel(fillingLevel: number | string | undefined) {
  return !lodash.isNil(fillingLevel) ? fillingLevel + "%" : "-"
}

export function formatTemperature(temperature: number | string | undefined) {
  return !lodash.isNil(temperature) ? temperature + "°" : "-"
}

export function formatPercentage(percentage: number | string | undefined) {
  return !lodash.isNil(percentage) && !Number.isNaN(Number(percentage))
    ? Number(percentage).toFixed(2) + "%"
    : "-"
}

export function formatNumber(value: number | string | undefined | null) {
  return !lodash.isNil(value) && !Number.isNaN(Number(value))
    ? Number(value).toFixed(2)
    : "-"
}

export function formatDate(date: Moment | string | undefined | null): string {
  if (lodash.isNil(date)) return "--"
  const momentDate = moment(date)
  return momentDate.isValid()
    ? momentDate.format("lll")
    : "-"
}

export function formatGPS(
  lat: number | null,
  long: number | null
) {
  return !lodash.isNil(lat) && !lodash.isNil(long) ? lat + " | " + long : "-"
}
