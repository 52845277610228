import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core"
import lodash from "lodash"
import moment, { Moment } from "moment"
import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { GlobalFilterContext } from "../../../../context/GlobalFilterContext"
import { ITimeRange } from "../../../../utils/ITimeRange"
import { ContainerLocationCustomAutocomplete } from "../../../partials/custom-select-components/container-location-custom-autocomplete"
import { ContainerTypeCustomAutocomplete } from "../../../partials/custom-select-components/container-type-custom-autocomplete"
import { PartnerCustomAutocomplete } from "../../../partials/custom-select-components/partner-custom-autocomplete"
import { ContentCard } from "../../../partials/layout/content/ContentCard"
import { DashboardFilterContext } from "../DashboardFilterContext"
import { CustomDatePicker } from "../../../partials/datepicker/custom-datepicker"

export enum PredefinedDateRange {
  currentMonth,
  lastMonth,
  last30Days,
  currentDay,
}

interface IDashboardChangelogProps {}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    minWidth: 100,
    maxWidth: 500,
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
}))

export const DashboardFilter: FunctionComponent<IDashboardChangelogProps> = (props) => {
  const { timeRange, setTimeRange } = useContext(DashboardFilterContext)
  const {
    containerLocations,
    selectedContainerLocations,
    setSelectedContainerLocations,
    containerTypes,
    selectedContainerTypes,
    setSelectedContainerTypes,
    partners,
    selectedPartners,
    setSelectedPartners,
  } = useContext(GlobalFilterContext)
  const classes = useStyles()
  const { t } = useTranslation()
  const [predefinedRange, setPredefinedRange] = useState<PredefinedDateRange | undefined>(PredefinedDateRange.lastMonth)

  // componentDidMount-Hook
  useEffect(
    () => {
      // TODO: refactor in future - we want to have a seperate context for each page.
      setSelectedContainerLocations([])
      setSelectedContainerTypes([])
      setSelectedPartners([])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    const newTimeRange: ITimeRange = lodash.cloneDeep(timeRange)
    switch (predefinedRange) {
      case PredefinedDateRange.currentMonth:
        newTimeRange.fromDate = moment().startOf("month")
        newTimeRange.toDate = moment()
        break
      case PredefinedDateRange.lastMonth:
        newTimeRange.fromDate = moment().subtract(1, "month").startOf("month")
        newTimeRange.toDate = moment().subtract(1, "month").endOf("month")
        break
      case PredefinedDateRange.last30Days:
        newTimeRange.fromDate = moment().subtract(30, "days")
        newTimeRange.toDate = moment()
        break
      case PredefinedDateRange.currentDay:
        newTimeRange.fromDate = moment()
        newTimeRange.toDate = moment()
        break
    }
    setTimeRange(newTimeRange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predefinedRange])

  function onCustomTimeRangeChange(from?: Moment, to?: Moment) {
    const newTimeRange: ITimeRange = lodash.cloneDeep(timeRange)
    if (from) {
      newTimeRange.fromDate = from.clone()
      if (newTimeRange.fromDate.isAfter(newTimeRange.toDate, "day")) {
        newTimeRange.toDate = newTimeRange.toDate.add(newTimeRange.fromDate.diff(timeRange.fromDate, "days"), "days")
      }
    }
    if (to) {
      newTimeRange.toDate = to.clone()
      if (newTimeRange.toDate.isBefore(newTimeRange.fromDate, "day")) {
        newTimeRange.fromDate = newTimeRange.fromDate.add(newTimeRange.toDate.diff(timeRange.toDate, "days"), "days")
      }
    }
    setPredefinedRange(undefined)
    setTimeRange(newTimeRange)
  }

  return (
    <ContentCard>
      <Grid container direction={"row"} spacing={6}>
        <Grid item xs={4}>
          <ContainerLocationCustomAutocomplete
            id="dashboard-container-location-filter"
            title={t("generic.actual_location")}
            currentlySelectedContainerLocations={selectedContainerLocations}
            availableContainerLocations={containerLocations || []}
            setCurrentlySelectedContainerLocations={setSelectedContainerLocations}
          />
        </Grid>
        <Grid item xs={4}>
          <ContainerTypeCustomAutocomplete
            id="dashboard-container-type-filter"
            title={t("dashboard.filter.types")}
            currentlySelectedContainerTypes={selectedContainerTypes}
            availableContainerTypes={containerTypes || []}
            setCurrentlySelectedContainerTypes={setSelectedContainerTypes}
          />
        </Grid>
        <Grid item xs={4}>
          <PartnerCustomAutocomplete
            id="dashboard-container-partner-filter"
            title={t("dashboard.filter.partner")}
            currentlySelectedPartners={selectedPartners}
            availablePartners={partners}
            setCurrentlySelectedPartners={setSelectedPartners}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.fullWidth}>
            <InputLabel htmlFor="type">{t("dashboard.filter.predefined.title")}</InputLabel>
            <Select
              value={predefinedRange || ""}
              onChange={(e) => setPredefinedRange(e.target.value as PredefinedDateRange)}
              className={classes.select}
            >
              <MenuItem value={PredefinedDateRange.lastMonth}>{t("dashboard.filter.predefined.lastMonth")}</MenuItem>
              <MenuItem value={PredefinedDateRange.currentMonth}>
                {t("dashboard.filter.predefined.currentMonth")}
              </MenuItem>
              <MenuItem value={PredefinedDateRange.last30Days}>{t("dashboard.filter.predefined.last30Days")}</MenuItem>
              <MenuItem value={PredefinedDateRange.currentDay}>{t("dashboard.filter.predefined.currentDay")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <CustomDatePicker
            label={t("dashboard.filter.from")}
            fullWidth
            format="DD.MMM YYYY"
            value={timeRange.fromDate.toDate()}
            onChange={(date) => {
              onCustomTimeRangeChange(date ?? undefined, undefined)
            }}
            disableFuture
          />
        </Grid>
        <Grid item xs={4}>
          <CustomDatePicker
            label={t("dashboard.filter.to")}
            fullWidth
            format="DD.MMM YYYY"
            value={timeRange.toDate.toDate()}
            onChange={(date) => {
              onCustomTimeRangeChange(undefined, date ?? undefined)
            }}
            disableFuture
          />
        </Grid>
      </Grid>
    </ContentCard>
  )
}
