import { Button } from "@material-ui/core"
import "moment/locale/de"
import React, { useContext } from "react"
import { useLazyQuery } from "react-apollo"
import { useTranslation } from "react-i18next"
import { ContainerCharacteristic } from "../../../api/graphql/graphql-global-types"
import { CONTAINER_CSV } from "../../../api/graphql/query/container_csv"
import { containerCSV, containerCSVVariables } from "../../../api/graphql/query/types/containerCSV"
import { GlobalFilterContext } from "../../../context/GlobalFilterContext"
import { exportContainersCSV } from "../../../utils/exportCSV"

interface IContainerDownloadButtonProps {
  characteristic: ContainerCharacteristic
}

export const ContainerDownloadButton: React.FunctionComponent<IContainerDownloadButtonProps> = (props) => {
  const { characteristic } = props
  const { t } = useTranslation()

  const {
    selectedContainerLocations,
    selectedContainerTypes,
    selectedContainerFillStates,
    qrCodeFilterText,
    connectionReferenceFilterText,
  } = useContext(GlobalFilterContext)

  const [getContainerCSV, { data: csvData }] = useLazyQuery<containerCSV, containerCSVVariables>(CONTAINER_CSV, {
    onCompleted: () => exportContainersCSV(characteristic, csvData?.containerCSV.data as string),
    fetchPolicy: "no-cache",
  })

  return (
    <Button
      variant="contained"
      type="button"
      color="primary"
      onClick={() =>
        getContainerCSV({
          variables: {
            locationIds: selectedContainerLocations.map((selectedContainerLocation) => selectedContainerLocation.id),
            containerTypeIds: selectedContainerTypes.map((selectedContainerType) => selectedContainerType.id),
            containerCharacteristic: characteristic,
            qrCodeFilterText: qrCodeFilterText || null,
            connectionReferenceFilterText: connectionReferenceFilterText || null,
            containerFillStates: selectedContainerFillStates.map((containerFillState) => containerFillState.id),
          },
        })
      }
    >
      {t("container.download")}
    </Button>
  )
}
